import {
  BsBugFill,
  BsFillBarChartFill,
  BsTwitter
} from "react-icons/bs";
import Link from "next/link";
import {
  DISCORD_LINK, DUNE_LINK,
  MIRROR_LINK,
  PRIVACY_POLICY_LINK, RESPONSIBLE_DISCLOSURE_LINK,
  TERMS_OF_USE_LINK,
  TWITTER_LINK,
  GETTING_STARTED_LINK, PERPS_VIEW, PERP_ANALYTICS_LINK, isBlast
} from "@/util/constants";
import React, {useEffect} from "react";
import {FaDiscord, FaEthereum, FaGasPump} from "react-icons/fa";
import {
  isBadgeInViewpoint,
  logBadgeClick,
  logBadgeView
} from "../../scripts/alchemy-badge-analytics";
import Image from "next/image";
import {FooterLogo} from "@/components/icons/FooterLogo";
import {useLongView} from "@/components/BNPL/LongContext";
import {useFeeData} from "wagmi";
import {useHasMounted} from "@/hooks/useHasMounted";
import {LongFooter} from "@/components/BNPL/LongFooter";
import {useOptionAction} from "@/components/Option/actions/OptionActionContext";
import {OptionActionFooter} from "@/components/Option/actions/OptionActionFooter";
import {useEthPrice} from "@/contexts/EthPriceContext";
import Switch from "react-switch";
import {theme} from "../../tailwind.config";
import classNames from "classnames";
import {NewsButton} from "@/components/news/NewsButton";
import {useFooterLinks} from "@/hooks/useFooterLinks";

const renderLegalLink = (label: string, href: string) => {
  return <a href={href}
            target="_blank"
            className="text-neutral-content/50 font-light hover:text-neutral-content text-sm">
    {label}
  </a>
}

const renderLinkWithText = (id: string, href: string, image: string | React.ReactNode, inNewTab = true): React.ReactNode => {
  if (typeof image === 'string') {
    image = <Image src={image as string} alt={id} width={14} height={14} color="white"
                   className="hover:fill-white text-neutral-content" />;

      // <img src={image as string}
      //            className="w-[16px] h-[16px] hover:fill-white fill-neutral-content"
      //            alt={id} />
  }
  return (
    <Link key={`footer_${id}`} href={href} target={inNewTab ? "_blank" : undefined} className="text-xl flex flex-row items-center">
      <span data-tip={id.toUpperCase()}
            className="text-neutral-content hover:text-white hover:scale-110 flex tooltip">{ image }</span>
    </Link>
  );
}

export const Footer = () => {
  const isPerpsView = PERPS_VIEW;
  useEffect(() => {
    let intervalId = setInterval(() => {
      if (typeof document === 'undefined' || document === null) {
        console.debug("returning null document", document)
        return;
      }
      const badge = document.getElementById('badge-button');
      if (badge && isBadgeInViewpoint(badge.getBoundingClientRect())) {
        logBadgeView();
        clearInterval(intervalId);
      }
    }, 2000);
  }, []);

  const { data: gasData } = useFeeData();
  // const router = useRouter();
  // const isCollect = router.asPath.includes("/spot");

  const hasMounted = useHasMounted();
  const {ethPrice, showInUsd, setShowInUsd} = useEthPrice();
  const footerLinks = useFooterLinks();

  const bnpl = useLongView();
  const optionAction = useOptionAction();
  return (
    <div className="flex flex-col">
      { bnpl?.data && <LongFooter {...bnpl.data} /> }
      { optionAction?.data?.option && <OptionActionFooter {...optionAction.data} /> }
      <footer className="bg-main-bg border-t border-neutral-content/50 flex flex-col xl:flex-row xl:items-center xl:justify-between h-full w-full p-4 gap-4" id="footer">
        <div className="responsive-flex gap-2 md:gap-6">
          {renderLinkWithText("home", "/home", <FooterLogo/>, false)}
          {renderLegalLink("Privacy Policy", PRIVACY_POLICY_LINK)}
          {renderLegalLink("Terms and Conditions", TERMS_OF_USE_LINK)}
          {renderLegalLink("Responsible Disclosure Policy", RESPONSIBLE_DISCLOSURE_LINK)}
        </div>
        <div className="responsive-flex gap-6">
          <div className="flex flex-row gap-6">
            {/*<BlastinPepesWhitelist />*/}
            <NewsButton/>
            {
              ethPrice &&
              <div className="flex flex-row gap-2 items-center">
                <FaEthereum className={classNames({
                  'text-neutral-content': showInUsd,
                  'text-white': !showInUsd
                })}/>
                <Switch checked={showInUsd}
                        onColor={theme.extend.colors.neutral}
                        checkedIcon={false}
                        height={16}
                        width={32}
                        handleDiameter={16}
                        uncheckedIcon={false}
                        onChange={e => setShowInUsd(e.valueOf())}/>
                <span className={classNames({
                  'text-neutral-content': !showInUsd,
                  'text-white': showInUsd
                })}>$</span>
              </div>
            }
            {/*{*/}
            {/*  ethPrice && hasMounted &&*/}
            {/*  <span className="text-neutral-content/50 hover:text-white hover:scale-110 flex flex-row items-center gap-2 select-none">*/}
            {/*    <FaEthereum size={16} />| ${ethPrice.toLocaleString([], {maximumFractionDigits: 0})}*/}
            {/*  </span>*/}
            {/*}*/}
            {
              gasData && hasMounted &&
              <span
                className="text-neutral-content/50 hover:text-white hover:scale-110 flex flex-row items-center gap-2 select-none">
                <FaGasPump/>
                {"| " + Number.parseInt(gasData.formatted.gasPrice || '0') + " Gwei"}
              </span>
            }
          </div>
          <div className="flex flex-row gap-6">
            {footerLinks.map(({id, href, icon, inNewTab}) => renderLinkWithText(id, href, icon, inNewTab))}
          </div>
          <div className="h-full flex flex-col justify-end lg:items-end brightness-100">
            {
              isBlast ?
                <img id="badge-button"
                     className="w-[128px] h-[28px]"
                     src="/static/built_on_blast.svg"
                     alt="Built on Blast"/> :
                <a href="#"
                   onClick={(e) => {
                     e.preventDefault();
                     logBadgeClick();
                   }}>{/*<div className="pt-2 border-t border-1 border-neutral-content"></div>*/}
                  <img id="badge-button"
                       className="w-[128px] h-[28px]"
                       src="https://static.alchemyapi.io/images/marketing/badge.png"
                       alt="Alchemy Supercharged"/>
                </a>
            }
          </div>
        </div>
      </footer>
    </div>
  );
}
