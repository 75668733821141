import React, { createContext, useContext, useEffect, ReactNode } from 'react';
import {useQuery} from "@tanstack/react-query";
import {useHasMounted} from "@/hooks/useHasMounted";

interface BuildContextType {
}

const BuildContext = createContext<BuildContextType | undefined>(undefined);

interface BuildIdResponse {
  buildId: string;
}

export const BuildProvider = ({ children }: { children: ReactNode }) => {
  const hasMounted = useHasMounted();

  const { data, error, isSuccess} = useQuery({
    queryKey: ['buildId'],
    queryFn: async () => fetch('/api/build-id'),
    enabled: hasMounted,
    refetchInterval: 60 * 1000, // 60 seconds
  });


  useEffect(() => {
    if (error) {
      console.error('Failed to fetch build ID:', error);
      return;
    }

    const fetchData = async () => {
      if (isSuccess && data) {
        const result: BuildIdResponse = await data.json();
        const clientBuildId = process.env.NEXT_PUBLIC_BUILD_ID;
        if (clientBuildId && clientBuildId !== result.buildId) {
          window.location.reload();
        }

        console.log('Checking for new build ID....');
        console.log('Client build ID:', process.env.NEXT_PUBLIC_BUILD_ID);
        console.log('Server build ID:', result?.buildId);
      }
    };

    fetchData();
  }, [data, isSuccess]);

  return (
    <BuildContext.Provider value={{ }}>
      {children}
    </BuildContext.Provider>
  );
};

export const useBuild = () => {
  const context = useContext(BuildContext);
  if (context === undefined) {
    throw new Error('useBuild must be used within a BuildProvider');
  }
  return context;
};
