export const DuneIcon = () => {
  return (
    <svg width="18px" height="18px" viewBox="0 0 20 20" version="1.1" className="hover:fill-white fill-neutral-content hover:stroke-white stroke-neutral-content">
      <defs></defs>
      <g id="Page-1" strokeWidth="1" fillRule="evenodd">
        <g id="DuneLogoCircle" transform="translate(1.000000, 1.000000)" fillRule="nonzero">
          <path d="M8.99505,17.9859 C13.962375,17.9859 17.9892,13.9596 17.9892,8.99295 C17.9892,4.0262775 13.962375,0 8.99505,0 C4.02768,0 0.0008468625,4.0262775 0.0008468625,8.99295 C0.0008468625,13.9596 4.02768,17.9859 8.99505,17.9859 Z" id="Shape" fill="none" strokeWidth="2"></path>
          <path d="M1.565805,14.06565 C1.565805,14.06565 7.509225,12.118875 17.984325,8.703375 C17.984325,8.703375 18.558075,14.296725 12.759375,17.214225 C12.759375,17.214225 9.899925,18.5847 6.762825,17.705625 C6.762825,17.705625 3.6390375,17.115975 1.565805,14.06565 Z" id="Shape" stroke="none"></path>
        </g>
      </g>
    </svg>
  )
}