import React, {FC, createContext, useContext, useState} from "react";
import {useIsDesktop} from "@/hooks/useIsDesktop";

interface Props extends React.PropsWithChildren<{}>{
}

interface CustomHeaderContentContextInterface {
  customContent?: React.ReactNode | undefined;
  setCustomContent: (content?: React.ReactNode | undefined) => void;
}

export const CustomHeaderContentContext = createContext<CustomHeaderContentContextInterface>({
  customContent: undefined,
  setCustomContent: () => {},
});

export const CustomHeaderContentContextProvider: FC<Props> = ({ children }) => {
  const isDesktop = useIsDesktop();
  const [customContent, setCustomContent] = useState<React.ReactNode | undefined>(undefined);
  return (
    <CustomHeaderContentContext.Provider value={{
      customContent: isDesktop ? undefined : customContent,
      setCustomContent
    }}>
      {children}
    </CustomHeaderContentContext.Provider>
  );
};

export const useCustomHeaderContent = () => {
  return useContext(CustomHeaderContentContext);
}