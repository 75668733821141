import {
  PRIVACY_POLICY_LINK,
  RESPONSIBLE_DISCLOSURE_LINK,
  TERMS_OF_USE_LINK
} from "@/util/constants";

export const useLegalLinks = () => {

  return [
    {
      id: "privacy-policy",
      name: "Privacy Policy",
      href: PRIVACY_POLICY_LINK
    },
    {
      id: "terms-and-conditions",
      name: "Terms and Conditions",
      href: TERMS_OF_USE_LINK
    },
    {
      id: "responsible-disclosure",
      name: "Responsible Disclosure Policy",
      href: RESPONSIBLE_DISCLOSURE_LINK
    }
  ]
}