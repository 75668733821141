import { Modal, useModal } from "@/components/Modal";
import { Button, ButtonType } from "@/components/Button";
import { MakePoolBid } from "./MakePoolBid";
import { cancelPoolBid, fetchPoolBid } from "@/api/datafetcher";
import { WasabiOptionWithMetadataAndValue, OptionStatus } from "@/types/types";
import { AddressZero } from "@ethersproject/constants";
import { CHAIN_ID, DEMO_ETH, WETH } from "@/util/constants";
import { PoolBidOrder } from "@/types/exchange_types";
import { ChainItem, TransactionChain } from "@/components/Transactions/TransactionChain";
import { EthDisplay } from "@/components/EthDisplay";
import { TransactionButtonProps } from "@/components/Transactions/TransactionButton";
import { toBN } from "@/util/converters";
import {useQuery} from "@tanstack/react-query";
import { WasabiPoolAbi } from "@/contract/WasabiPoolAbi";
import classNames from "classnames";

export const PoolBidButton = ({ option, wide = false }: { option: WasabiOptionWithMetadataAndValue, wide: boolean }) => {
  const offerModal = useModal();

  const {data, isLoading, refetch} = useQuery({
    queryKey: ["poolBid", option.id],
    queryFn: async () => await fetchPoolBid(option.id),
  });

    const renderSkeletonButtonLoading = () => (
      <div className="min-w-[150px] min-h-[40px] rounded-md bg-slate-600 animate-pulse"/>
    );

    return (
      <>
        {isLoading ? renderSkeletonButtonLoading() : (
          <>
            {option.poolBid && data?.data ? (
              <CancelButton data={data.data} refetch={refetch} poolAddress={option.poolAddress} />
            ) : (
              <Button
                className="min-w-[150px]"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  offerModal.show();
                }}
              >
                Place Bid
              </Button>
            )}
            <Modal {...offerModal}>
              <MakePoolBid option={option} onOfferMade={() => window.location.reload()} />
            </Modal>
          </>
        )}
      </>
    );
};

export const CancelButton = ({ data, refetch, poolAddress }: { data: PoolBidOrder, refetch: () => Promise<any>, poolAddress: string }) => {

  const transactionChain: ChainItem[] = [];
  transactionChain.push({
    name: "Cancel Pool Bid",
    description: (
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2">
            <span>Option Id: {data.bid.optionId}</span>
          </div>
        </div>
        <EthDisplay
          value={toBN(data.bid.price)}
          size={4}
          removeTrailingZeroes={true}
          numDigits={4}
        />
      </div>
    ),
    buttonQuery: () => useQuery<TransactionButtonProps>({
      queryKey: ['pool_bid_cancel', data.bid.id],
      queryFn: async () => {
        const buttonProps: TransactionButtonProps = {
          id: "cancelPoolBid",
          loadingText: "Cancelling Offer",
          addressOrName: poolAddress,
          contractInterface: WasabiPoolAbi,
          functionName: "cancelOrder",
          args: [data.bid.id],
          onTransactionSuccess: (txnResult) => {
            cancelPoolBid(txnResult.transactionHash)
              .then(refetch)
              .catch(console.error)
          },
          alertOnError: true,
        }
        return buttonProps;
      }
    }),
  });

  return (
    <div className="relative group">
      <TransactionChain
        id="pool_bid_cancel"
        className="min-w-[150px]"
        transactionChain={transactionChain}
        enabled={true}
        title={<EthDisplay label="Cancel bid" value={toBN(data.bid.price)} size={4} />}
        buttonType={ButtonType.PRIMARY}
        onSuccess={() => window.location.reload()}
      />
    </div>
  );
}
