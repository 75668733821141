import React, {useState} from "react";
import {OptionType, WasabiOptionWithMetadata} from "@/types/types";
import {fetchOptionData, saveAsk} from "@/api/datafetcher";
import {BigNumber} from "ethers";
import {useAccount, useSignTypedData} from "wagmi";
import {EthInput} from "@/components/EthInput";
import {Button} from "@/components/Button";
import {CHAIN_ID, DEMO_ETH, WASABI_CONDUIT, WASABI_OPTION} from "@/util/constants";
import {TokenApprovalButton} from "@/components/ERC721/TokenApprovalButton";
import {Modal, useModal} from "@/components/Modal";
import {addDays, getDiffText} from "@/util/dateutils";
import {BsCalendarEvent} from "react-icons/bs";
import {Dropdown, DropdownOption, dropdownOption} from "@/components/Dropdown";
import {BiHelpCircle} from "react-icons/bi";
import {AiFillCheckCircle} from "react-icons/ai";
import {Ask, AskMigrated, WasabiAskMigrated} from "@/types/exchange_types";
import {useQuery} from "@tanstack/react-query";
import {LoadingSpinner} from "@/components/LoadingSpinner";
import {EthDisplay} from "@/components/EthDisplay";

import {getRandomInt} from "@/util/index";

import {Tooltip as ReactTooltip} from "react-tooltip";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import 'react-tooltip/dist/react-tooltip.css'
import {ErrorPanel} from "@/components/ErrorPanel";
import {parseError} from "@/util/errorHandling";
import {useReadErc721OwnerOf} from "@/util/generated";
import {zeroAddress} from "viem";
import {TypedDataDomain} from "abitype/src/abi";
import {SplitLabelValue} from "@/components/SplitLabelValue";
import {TimeRemaining} from "@/components/TimeRemaining";
import {
  OrderExpiryDatePicker,
  useOrderExpiryDatePicker
} from "@/components/Order/OrderExpiryDatePicker";

const options: DropdownOption<number>[] = [
  dropdownOption(1000 * 60 * 30,"30 minutes"),
  dropdownOption(1000 * 60 * 60, "1 hour"),
  dropdownOption(1000 * 60 * 60 * 6, "6 hours"),
  dropdownOption(1000 * 60 * 60 * 12, "12 hours"),
  dropdownOption(1000 * 60 * 60 * 24, "1 day"),
  dropdownOption(1000 * 60 * 60 * 24 * 2, "2 days"),
  dropdownOption(1000 * 60 * 60 * 24 * 3, "3 days"),
  dropdownOption(1000 * 60 * 60 * 24 * 7, "7 days"),
];

type Props = {
  option: WasabiOptionWithMetadata;
};

const domain: TypedDataDomain = {
  name: 'ConduitSignature',
  version: '1',
  chainId: CHAIN_ID,
  verifyingContract: WASABI_CONDUIT,
} as const;

const types = {
  Ask: [
    { name: "id", type: "uint256" },
    { name: "price", type: "uint256" },
    { name: "tokenAddress", type: "address" },
    { name: "orderExpiry", type: "uint256" },
    { name: "seller", type: "address" },
    { name: "optionId", type: "uint256" },
  ]
} as const;

export const MakeAsk = ({ option }: Props) => {
  const contractAddress: string = option.metadata.attributes.find(a => a.traitType === "nft_address")!.value;

  const {address} = useAccount();

  const [saveAskStatus, setSaveAskStatus] = useState<'idle' | 'saving' | 'error' | 'success'>('idle');
  const [erc721Approved, setErc721Approved] = useState(false);
  const [price, setPrice] = useState<BigNumber | null>(null)
  const [orderExpiry, setOrderExpiry] = useState<Date>(addDays(new Date(), 2));
  const orderExpiryDatePicker = useOrderExpiryDatePicker();
  const {error, isPending: waitingForSignature, signTypedDataAsync} = useSignTypedData();

  const ownerQuery = useReadErc721OwnerOf({
    address: WASABI_OPTION,
    args: [BigInt(option.id)]
  })
  let tokenAddress = option.metadata.attributes.find(a => a.traitType === "currency")!.value;
  if (tokenAddress === "ETH") {
    tokenAddress = undefined;
  }

  const currentPriceQuery = useQuery({
    queryKey: ["finalOptionData", contractAddress, option.strikePrice, option.expiration, option.optionType],
    queryFn: async () => await fetchOptionData(contractAddress, option.optionType, option.expiration, option.strikePrice),
    enabled: ownerQuery.isSuccess && ownerQuery.data === address,
    staleTime: 60 * 1000,
    gcTime: 2 * 60 * 1000,
  });

  async function sign() {
    if (!address || !price || orderExpiry.getTime() < new Date().getTime() || saveAskStatus === 'success' || saveAskStatus === 'saving') {
      return;
    }
    const ask: AskMigrated = {
      id: getRandomInt(1_000_000),
      price: BigInt(price.toString()),
      tokenAddress: CHAIN_ID === 1 ? zeroAddress : DEMO_ETH,
      orderExpiry: BigInt(Math.round(orderExpiry.getTime() / 1000)),
      seller: address!,
      optionId: BigInt(option.id!)
    };

    signTypedDataAsync({domain, types, primaryType: "Ask", message: ask})
      .then(signature => {
        const wasabiAsk: WasabiAskMigrated = { ...ask, signature: signature };
        setSaveAskStatus('saving');
        return saveAsk(wasabiAsk)
          .then(response => {
            setSaveAskStatus(response.success ? 'success' : 'error');
          }).catch(e => {
            console.error(e);
            setSaveAskStatus('error');
          })
      })
      .catch(() => {})
  }

  const renderCreateListingView = () => {
    return (
      <>
        <div className="standard-stack">
          <h2 className="text-xl flex flex-row gap-2 items-center">
            <span>Set a price</span>
            <BiHelpCircle id="price_tooltip" className="w-4 h-4" />
            <ReactTooltip anchorId="price_tooltip"
                          className="z-40 text-center max-w-[300px] bg-glass"
                          content="The listing price cannot be edited once an option is listed. In order to change the listing price, the old one must be cancelled and a new listing must be created. Cancelling a listing will require gas fees to be paid."
                          place='bottom' />
          </h2>
          <button className="standard-stack items-center rounded-md border-glass border max-w-[150px] bg-glass hover:bg-glass-focus p-2 my-2"
                  disabled={(currentPriceQuery.data?.availableOptions.selections.length || 0) <= 0}
                  onClick={() => {
                    setPrice(currentPriceQuery.data?.availableOptions.premium!);
                  }}>
            <span className="font-light text-neutral-content text-sm">Current Price</span>
            {
              currentPriceQuery.isLoading
                ? <LoadingSpinner size={4} />
                : <>
                  {
                    (currentPriceQuery.data?.availableOptions.selections.length || 0) > 0 &&
                    <EthDisplay value={currentPriceQuery.data?.availableOptions.premium!}
                                removeTrailingZeroes={true}
                                numDigits={4}
                                size={4} />
                  }
                  {
                    (currentPriceQuery.data?.availableOptions.selections.length || 0) <= 0 &&
                    <span>N/A</span>
                  }
                </>
            }
          </button>
          <EthInput value={price} onChange={setPrice} />
        </div>
        <Button onClick={async () => await sign()}
                loading={waitingForSignature || saveAskStatus === 'saving'}
                disabled={orderExpiry.getTime() < new Date().getTime() || price === null}>
          { waitingForSignature && "Waiting For Signature" }
          { saveAskStatus === 'saving' && "Saving Order" }
          { saveAskStatus === 'success' && <><AiFillCheckCircle/><span>Order Saved</span></> }
          {
            !waitingForSignature &&
            (saveAskStatus === 'idle' || saveAskStatus === 'error') &&
            "Complete Listing"
          }
        </Button>
        { error && <ErrorPanel message={parseError(error)} /> }
        { saveAskStatus === 'error' && <ErrorPanel message="Something went wrong please try again."/> }
        {/*<Button onClick={() => contractWrite.write?.()}>Send</Button>*/}
      </>
    )
  }

  return (
    <div className="standard-stack mx-auto">
      <div className="text-2xl">List Your Option</div>
      <div className="text-md text-neutral-content">
        List your option for sale.
      </div>
      <hr className="border-neutral-content/50" />
      <div className="flex flex-row gap-8">
        <div className="standard-stack">
          <SplitLabelValue label="Option ID">
            <span className="text-neutral-content">{option.id}</span>
          </SplitLabelValue>
          <SplitLabelValue label="Strike Price">
            <EthDisplay
              value={option.strikePrice}
              tokenAddress={tokenAddress}
              numDigits={4}
              size={4}
              removeTrailingZeroes={true}
            />
          </SplitLabelValue>
          <SplitLabelValue label="Type">
            <span>{OptionType[option.optionType]}</span>
          </SplitLabelValue>
          <SplitLabelValue label="Expires on">
            <TimeRemaining epochSeconds={option.expiration} />
          </SplitLabelValue>
          <hr className="border-neutral-content/50" />
          <SplitLabelValue label="Current Price">
            {option.premium ? (
              <EthDisplay
                value={option.premium}
                removeTrailingZeroes={true}
                tokenAddress={tokenAddress}
                numDigits={8}
                size={4}
              />
            ) : (
              "--"
            )}
          </SplitLabelValue>
          <hr className="border-neutral-content/50" />
          <SplitLabelValue label="Offer Expires In:">
            <OrderExpiryDatePicker
              {...orderExpiryDatePicker}
              className="max-w-[150px]"
            />
          </SplitLabelValue>
        </div>
        <div className="standard-stack">
          {
            ownerQuery.isLoading
              ? <LoadingSpinner />
              : <>
                {
                  !erc721Approved && ownerQuery.data === address &&
                  <TokenApprovalButton contractAddress={WASABI_OPTION}
                                       to={WASABI_CONDUIT}
                                       approvalChanged={setErc721Approved}
                                       singleToken={false} />
                }
                {
                  erc721Approved && ownerQuery.data === address &&
                  renderCreateListingView()
                }
                {
                  ownerQuery.data !== address &&
                  <p className="w-full text-center text-neutral-content">Not Listed</p>
                }
              </>
          }
        </div>
      </div>
    </div>
  )
}
