import {ContractFunctionExecutionError, ContractFunctionRevertedError} from "viem";

export interface ErrorCode {
  name: string;
  hash: string;
  message: string;
}

export const errorCodes: ErrorCode[] = [
  {
    name: "OrderFilledOrCancelled", hash: "0x7b38b76e", message: "The order was filled or cancelled"
  },
  {
    name: "InvalidSignature", hash: "0x8baa579f", message: "Invalid signature is supplied"
  },
  {
    name: "InsufficientAvailableLiquidity", hash: "0xe926a90f", message: "Not enough available liquidity"
  },
  {
    name: "RequestNftIsLocked", hash: "0x7aac9f5d", message: "Requested NFT already has an option on it"
  },
  {
    name: "NftIsInvalid", hash: "0xc7e077da", message: "NFT is invalid"
  },
  {
    name: "InvalidExpiry", hash: "0xd36c8500", message: "Expiry is invalid"
  },
  {
    name: "InvalidStrike", hash: "0x9a5818d8", message: "Strike is invalid"
  },
  {
    name: "InvalidOptionType", hash: "0x53b5f2ba", message: "Option type is invalid"
  },
  {
    name: "HasExpired", hash: "0x3d36c2e5", message: "Order has expired"
  },
  {
    name: "FailedToSend", hash: "0x467d86d1", message: "Failed to send"
  },
  {
    name: "Unauthorized", hash: "0x82b42900", message: "Unauthorized request"
  },
]

export const tryParseError = (error: Error): ErrorCode | undefined => {
  if (error.name === "ContractFunctionRevertedError") {
    const a = error as ContractFunctionRevertedError;
    return {
      name: a.reason || a.name,
      hash: '',
      message: a.message,
    }
  } else if (error.name === "ContractFunctionExecutionError") {
    const a = error as ContractFunctionExecutionError;
    return {
      name: a.shortMessage || a.name,
      hash: '',
      message: a.shortMessage
    }
  }
  // @ts-ignore
  const code = error.error?.data?.originalError?.data || error.cause?.data?.errorName;
  return errorCodes.find(a => a.hash === code || a.name === code);
}