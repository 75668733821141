import {EthersError, getParsedEthersError, ReturnValue} from "@enzoferey/ethers-error-parser";

export const getErrorMessage = (error: ReturnValue) => {
  if (error.errorCode === "EXECUTION_REVERTED") {
    return `Error: ${error.context}`;
  } else if (error.errorCode === "REJECTED_TRANSACTION") {
    return `Transaction was rejected.`;
  } else if (error.errorCode === "TRANSACTION_RAN_OUT_OF_GAS") {
    return `Transaction ran out of gas, try again.`;
  } else if (error.errorCode === "TRANSACTION_UNDERPRICED") {
    return "Transaction was underpriced.";
  } else if (error.errorCode === "CALL_REVERTED") {
    return `Reverted: ${error.context}`;
  } else if (error.errorCode === "NONCE_TOO_LOW") {
    return "Nonce too low. Please refresh the page and try again.";
  } else if (error.context === "ACTION_REJECTED") {
    return `Action was rejected.`;
  }
  return "Something went wrong. Please try again or contact our support at Discord";
}

export const parseError = (error: any): string => {
  const parsedError = getParsedEthersError(error as EthersError);
  const errorMessage = getErrorMessage(parsedError);
  return errorMessage;
}