import { OptionType, OptionData, WasabiOptionWithMetadataAndValue } from "@/types/types";
import { EthDisplay } from "@/components/EthDisplay";
import { BigNumber, ethers } from "ethers";
import { CHAIN_ID } from "@/util/constants";
import { Button, ButtonType } from "@/components/Button";
import React, { useState } from "react";
import { EthInput } from "@/components/EthInput";
import {
  OrderExpiryDatePicker,
  useOrderExpiryDatePicker,
} from "@/components/Order/OrderExpiryDatePicker";
import { useAccount, useSignTypedData } from "wagmi";
import { formatEther, weiToEth } from "@/util/converters";
import { PoolBid, PoolBidOrder } from "@/types/exchange_types";
import { savePoolPid, fetchPool } from "@/api/datafetcher";
import { getRandomInt } from "@/util/index";
import { ErrorPanel } from "@/components/ErrorPanel";
import { parseError } from "@/util/errorHandling";
import { MdOutlineLocalOffer } from "react-icons/md";
import { TypedDataDomain } from "abitype/src/abi";
import {Address, zeroAddress} from "viem";
import { SplitLabelValue } from "@/components/SplitLabelValue";
import {useQuery} from "@tanstack/react-query";
import { TimeRemaining } from "@/components/TimeRemaining";

const types = {
  PoolBid: [
    { name: "id", type: "uint256" },
    { name: "price", type: "uint256" },
    { name: "tokenAddress", type: "address" },
    { name: "orderExpiry", type: "uint256" },
    { name: "optionId", type: "uint256" },
  ],
} as const;

export interface Props {
  option: WasabiOptionWithMetadataAndValue;
  onOfferMade?: (bid: PoolBidOrder) => any;
}

export const MakePoolBid = ({option, onOfferMade}: Props) => {
  const { address } = useAccount();
  const [price, setPrice] = useState<BigNumber | null>(
    option.premium
      ? ethers.utils.parseEther(
        (formatEther(option.premium) * 0.9).toFixed(3).toString()
      )
      : null
  );

  const orderExpiryDatePicker = useOrderExpiryDatePicker(undefined, new Date(option.expiration * 1000));
  const [saveOfferStatus, setSaveOfferStatus] = useState<
    "idle" | "saving" | "error" | "success" | "loading"
  >("idle");

  const {data, isLoading} = useQuery({
    queryKey: ["pool", option.poolAddress],
    queryFn: async () => await fetchPool(option.poolAddress),
  });

  const domain: TypedDataDomain = {
    name: "PoolBidVerifier",
    version: "1",
    chainId: CHAIN_ID,
    verifyingContract: option.poolAddress as Address,
  } as const;

  const buttonIsDisabled = () => {
    if (price == null || saveOfferStatus === "success" || saveOfferStatus === "loading") {
      return true;
    }
    return data?.pool.availableBalance.lt(price) || false;
  };

  const getBid = (): PoolBid => {
    const orderExpiry = orderExpiryDatePicker.orderExpiry;
    return {
      id: BigInt(Math.round(new Date().getTime() / 1000)),
      price: BigInt(price?.toString() || 0),
      tokenAddress: (data?.pool.tokenAddress || zeroAddress) as Address,
      orderExpiry: BigInt(Math.round(orderExpiry.getTime() / 1000)),
      optionId: BigInt(option.id || 0),
    } as const;
  };

  const { error, isPending: waitingForSignature, signTypedDataAsync } = useSignTypedData();


  const sign = () => {
    const orderExpiry = orderExpiryDatePicker.orderExpiry;
    if (
      !address ||
      !price ||
      orderExpiry.getTime() < new Date().getTime() ||
      saveOfferStatus === "success" ||
      saveOfferStatus === "saving"
    ) {
      return;
    }
    const bid = getBid();
    // @ts-ignore
    window.dataLayer.push({
      event: "makePoolBidFinal",
      orderExpiry: bid.orderExpiry,
      price: weiToEth(bid.price),
    });

    signTypedDataAsync({ domain, types, primaryType: "PoolBid", message: bid })
      .then((signature) => {
        // @ts-ignore
        window.dataLayer.push({
          event: "poolBidSignatureSuccessful",
        });

        const wasabiBid: PoolBidOrder = { bid, signature };

        setSaveOfferStatus("saving");
        return savePoolPid(wasabiBid)
          .then((response) => {
            setSaveOfferStatus(response.success ? "success" : "error");
            if (response.success) {
              setTimeout(() => onOfferMade?.(wasabiBid), 1000);
            }
          })
          .catch((e) => {
            console.error(e);
            setSaveOfferStatus("error");
          });
      })
      .catch((e) => {
        // @ts-ignore
        window.dataLayer.push({
          event: "signatureRejected",
        });
      });
  };

  return (
    <div className="w-full standard-stack min-w-[400px]">
      <div className="text-2xl">Make Pool Bid</div>
      <div className="text-md text-neutral-content">
        Enter a bid to buy and close this option<br/>using the funds in your pool.
      </div>
      <hr className="border-neutral-content/50" />
      <SplitLabelValue label="Option ID">
        <span className="text-neutral-content">{option.id}</span>
      </SplitLabelValue>
      <SplitLabelValue label="Strike Price">
        <EthDisplay
          value={option.strikePrice}
          tokenAddress={data?.pool.tokenAddress}
          numDigits={4}
          size={4}
          removeTrailingZeroes={true}
        />
      </SplitLabelValue>
      <SplitLabelValue label="Type">
        <span>{OptionType[option.optionType]}</span>
      </SplitLabelValue>
      <SplitLabelValue label="Expires on">
        <TimeRemaining epochSeconds={option.expiration} />
      </SplitLabelValue>
      <hr className="border-neutral-content/50" />
      <SplitLabelValue label="Offer Expires In:">
        <OrderExpiryDatePicker
          {...orderExpiryDatePicker}
          className="max-w-[150px]"
        />
      </SplitLabelValue>
      <hr className="border-neutral-content" />
      {data && !isLoading &&
        <SplitLabelValue label="Available Pool Balance">
          <EthDisplay
            value={option.optionType === OptionType.CALL ? data.pool.availableBalance : data.pool.availableBalance.add(option.strikePrice)}
            tokenAddress={data?.pool.tokenAddress || zeroAddress}
            removeTrailingZeroes={true}
            numDigits={5}
            className={
              price !== null && price.gt(data.pool.availableBalance)
                ? "text-error"
                : "text-neutral-content"
            }
            size={4}
          />
        </SplitLabelValue>}

      <div className="flex flex-row justify-between text-xl items-center">
        <span className="text-neutral-content">Enter Bid</span>
        <EthInput
          className="max-w-[150px]"
          value={price}
          onChange={setPrice}
          selectedToken={data?.pool.tokenAddress || zeroAddress}
        />
      </div>
      <hr className="border-neutral-content pb-2" />
      <div className="w-full flex flex-col items-center justify-end gap-2">
          <div className="flex flex-row gap-2">
            <Button
              id="make_offer_final"
              buttonType={ButtonType.PRIMARY}
              disabled={buttonIsDisabled()}
              loading={waitingForSignature || saveOfferStatus === "saving"}
              onClick={() => sign()}
            >
              <MdOutlineLocalOffer />{" "}
              {waitingForSignature
                ? "Signing"
                : saveOfferStatus === "saving"
                  ? "Saving"
                  : saveOfferStatus === "success"
                    ? "Offer Created"
                    : "Make Offer"}
            </Button>
          </div>
        {error && <ErrorPanel message={parseError(error)} />}
        {saveOfferStatus === 'error' && <ErrorPanel message="Failed to save pool bid." />}
      </div>
      {data && !isLoading && price !== null && data.pool.availableBalance.lt(price) && (
        <span className="text-error w-full text-center pt-4">
          Insufficient Balance For This Offer
        </span>
      )}
    </div>
  );
};
