import {OptionType} from "@/types/types";
import {BigNumber, BigNumberish, ethers} from "ethers";
import {formatUnits} from "viem";

export const optionType = (type: OptionType) => {
  return type === OptionType.CALL ? 'Call' : 'Put';
}

export const formatValue = (value: bigint, fractionDigits = 5, decimals = 18) => {
  return parseFloat(Number(formatUnits(value, decimals)).toFixed(fractionDigits));
}

export const weiToEth = (value: any): number => {
  const weiValue =
    value instanceof BigNumber ? value : BigNumber.from(String(value));
  return Number(ethers.utils.formatEther(weiValue));
};

export const formatEther = (value?: BigNumberish): number => {
  if (!value) {
    return 0;
  }
  return Number(ethers.utils.formatEther(value));
}

export const prettifyNumber = (value: number): string => {
  if (value < 1000) {
    return value.toFixed(1).toString();
  } else if (value < 10_000) {
    return value.toFixed(0).toString();
  } else if (value < 1_000_000) {
    return `${(value / 1_000).toFixed(0)}k`;
  } else if (value < 1_000_000_000) {
    return `${(value / 1_000_000).toFixed(2)}M`;
  } else {
    return `${(value / 1_000_000_000).toFixed(2)}B`;
  }
}

export const toBN = (value: any): BigNumber => {
  if (value === null) {
    return BigNumber.from(0);
  }
  const stringValue = String(value).toLowerCase();
  if (stringValue.indexOf("e+") >= 0) {
    return BigNumber.from((value as number).toLocaleString('fullwide', {useGrouping:false}))
  }
  return BigNumber.from(stringValue);
}

export const prettifyEth = (
  value: BigNumber, numDigits = 4, removeTrailingZeroes = true, prettify = true
): string => {
  let formattedValue = Number(formatEther(value)).toFixed(numDigits);
  if (removeTrailingZeroes) {
    formattedValue = parseFloat(formattedValue).toString();
  }
  if (prettify) {
    formattedValue = prettifyNumber(parseFloat(formattedValue));
  }
  return formattedValue;
}

export function castBigNumberToBigInt(obj: any): any {
  if (obj instanceof BigNumber) {
    return BigInt(obj.toString());
  } else if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(castBigNumberToBigInt);
    } else {
      const newObj: any = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key] = castBigNumberToBigInt(obj[key]);
        }
      }
      return newObj;
    }
  }
  return obj;
}

export function multiplyByPrice(amount: bigint, price: number) {
  return BigInt(amount) * BigInt(Math.round(1_000_000_000_000 * price)) / 1_000_000_000_000n;
}