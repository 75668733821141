import {useAlert} from "@/contexts/AlertContext";
import {BiCheckCircle, BiErrorCircle} from "react-icons/bi";
import {IoClose} from "react-icons/io5";
import classNames from "classnames";

export const Alert = () => {
  const {show, message, type, hide} = useAlert();

  return (
    show ?
    <div className="absolute w-screen h-screen flex items-end justify-center z-50">
      <div onClick={hide}
           className={classNames("w-1/2 min-w-[300px] text-white p-4 mb-4 rounded-md flex flex-row gap-2 items-center justify-between cursor-pointer", {
             'bg-error': type === 'error',
             'bg-success': type === 'success',
           })}>
        <span className="flex flex-row gap-2 items-center">
          {type === 'error' ? <BiErrorCircle size={20} /> : <BiCheckCircle size={20} />} {message}
        </span>
        <IoClose onClick={hide} className="cursor-pointer hover:scale-150" />
      </div>
    </div> :
    <></>
  );
}