import {Modal, useModal} from "@/components/Modal";
import {News} from "@/components/news/News";
import React, {useEffect} from "react";
import {useUserSelection} from "@/contexts/UserSelectionContext";
import {getNewsItems} from "@/components/news/newsitems";
import {useHasMounted} from "@/hooks/useHasMounted";
import {twMerge} from "tailwind-merge";

export interface Props extends React.PropsWithChildren {
  className?: string | undefined;
  onClick?: Function | undefined;
}

export const NewsButton = ({className, children, onClick}: Props) => {
  const hasMounted = useHasMounted();
  const newsModal = useModal();
  const {userSelections, handleSelection} = useUserSelection();
  const { lastNewsFetch } = userSelections;
  const newsItems = getNewsItems();
  const lastNewsDate = newsItems.length > 0 ? newsItems[0].date : new Date();

  useEffect(() => {
    if (!hasMounted || newsItems.length === 0) {
      return;
    }

    if (!lastNewsFetch || lastNewsFetch < lastNewsDate.getTime()) {
      newsModal.show();
      handleSelection('lastNewsFetch', Math.max(new Date().getTime(), lastNewsDate.getTime() + 1));
    }
  }, [hasMounted, lastNewsFetch]);

  if (newsItems.length === 0) {
    return <div></div>
  }

  return (
    <>
      <div className={twMerge("cursor-pointer hover:text-white text-neutral-content", className || '')}
           onClick={() => {
             onClick && onClick();
             newsModal.show();
           }}>
        { children || "What's New?" }
      </div>
      <Modal {...newsModal} title="What's New">
        <News onClick={newsModal.hide} />
      </Modal>
    </>
  )
}