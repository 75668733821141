import {getNft} from "@/api/datafetcher";
import { BigNumber } from "ethers";
import {useQuery} from "@tanstack/react-query";
import {twMerge} from "tailwind-merge";
import {LoadingSpinner} from "@/components/LoadingSpinner";
import ModalImage from "react-modal-image";

export const SingleNft = ({
  collectionAddress,
  tokenId,
  hideFooter = false,
  size = 40,
  height = undefined,
  className = ''
}: {
  collectionAddress: string;
  tokenId: BigNumber;
  hideFooter?: boolean;
  size?: any;
  height?: any;
  className?: string,
}) => {
  const { data, isLoading } = useQuery({
    queryKey: ["contractAddress", collectionAddress, "tokenId", tokenId],
    queryFn: async () => await getNft(collectionAddress, tokenId),
    gcTime: 1000 * 60,
    staleTime: 1000 * 60 * 1,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (data === undefined) {
    return <div className="text-error">Failed</div>;
  }
  const nft = data;
  let sizeClass = 'hover:brightness-110';
  if (height) {
    sizeClass += ` h-[${height}px]`;
  } else {
    sizeClass += ` w-${size || 40} h-${size || 40}`;
  }
  return <div className={twMerge("flex flex-col items-center justify-center rounded-md gap-1 glassborder overflow-hidden p-1", className || '')}
              onClick={e => e.stopPropagation()}
              key={nft.id}>
    <ModalImage
      alt=""
      hideZoom={true}
      hideDownload={true}
      small={nft.imageThumbnailUrl || nft.imageUrl}
      large={nft.imageThumbnailUrl || nft.imageUrl}
      className={sizeClass}/>
    {!hideFooter && <div className="label">{nft.name || nft.tokenId}</div>}
  </div>;
};
