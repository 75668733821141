import {useQuery} from "@tanstack/react-query";
import {fetchWhitelistData, WhitelistResponse} from "@/api/datafetcher";
import {useAccount} from "wagmi";
import {PERPS_VIEW} from "@/util/constants";

export const saveWhitelistToStorage = (address: string, status: WhitelistResponse) => {
  if ((status === "WHITELISTED" || status === "BANNED") && typeof sessionStorage !== "undefined") {
    sessionStorage.setItem(`whitelist_${address}`, status);
    sessionStorage.setItem("whitelist_cache_expires", (new Date().getTime() + 5 * 60 * 1000).toString());
  }
  return undefined;
};

export const getWhitelistFromLocalStorage = (address: string) => {
  return undefined;
};

export const useWhitelist = () => {
  const {address} = useAccount();

  const query = useQuery<WhitelistResponse>({
    queryKey: ['whitelist', address],
    queryFn: async () => {
      const response = await fetchWhitelistData(address!);
      saveWhitelistToStorage(address!, response);
      return response;
    },
    enabled: address !== undefined,
    gcTime: 30 * 1000,
  });

  return {
    ...query,
    canBuy: query.data === 'WHITELISTED',
  }
}
