import React, {useState} from "react";
import {addDays, getDiffText} from "@/util/dateutils";
import {Modal, useModal} from "@/components/Modal";
import {BsCalendarEvent} from "react-icons/bs";
import {Dropdown, dropdownOption, DropdownOption} from "@/components/Dropdown";
import ReactDatePicker from "react-datepicker";
import {twMerge} from "tailwind-merge";
import "react-datepicker/dist/react-datepicker.css";
import {Button, ButtonType} from "@/components/Button";

const options: DropdownOption<number>[] = [
  dropdownOption(1000 * 60 * 30,"30 minutes"),
  dropdownOption(1000 * 60 * 60, "1 hour"),
  dropdownOption(1000 * 60 * 60 * 6, "6 hours"),
  dropdownOption(1000 * 60 * 60 * 12, "12 hours"),
  dropdownOption(1000 * 60 * 60 * 24, "1 day"),
  dropdownOption(1000 * 60 * 60 * 24 * 2, "2 days"),
  dropdownOption(1000 * 60 * 60 * 24 * 3, "3 days"),
  dropdownOption(1000 * 60 * 60 * 24 * 7, "7 days"),
];

export const useOrderExpiryDatePicker = (defaultDate?: Date, maxDate?: Date) => {
  const [orderExpiry, setOrderExpiry] =
    useState<Date>(defaultDate || maxDate || addDays(new Date(), 2));

  return { orderExpiry, setOrderExpiry, maxDate }
}

export const OrderExpiryDatePicker = (
  { orderExpiry, setOrderExpiry, className = '', maxDate }:
  { orderExpiry: Date, setOrderExpiry: (expiry: Date) => any, className?: string, maxDate?: Date }
) => {
  const modal = useModal();

  return (
    <div className={twMerge("input cursor-pointer hover:bg-glass-focus flex items-center gap-2", className || '')}
         onClick={modal.show}>
      <BsCalendarEvent /> {getDiffText(new Date(), orderExpiry)}
      <Modal {...modal}>
        <div className="flex flex-col gap-2">
          <h3>Quick Select</h3>
          <Dropdown options={options.filter(o => !maxDate || (o.value + new Date().getTime()) < maxDate.getTime())}
                    selected={undefined}
                    placeholder={getDiffText(new Date(), orderExpiry)}
                    onChange={v => {
                      const date = new Date();
                      date.setTime(date.getTime() + v);
                      setOrderExpiry(date);
                      modal.hide();
                    }} />
          <h3 className="mt-2">Pick a date</h3>
          <ReactDatePicker onChange={a => a !== null && setOrderExpiry(a)}
                           className="input"
                           popperClassName="!static !transform-none	"
                           showPopperArrow={false}
                           filterTime={d => d.getTime() > new Date().getTime()}
                           minDate={new Date()}
                           maxDate={maxDate}
                           showTimeSelect
                           open={true}
                           selected={orderExpiry} />
          <Button buttonType={ButtonType.SECONDARY} onClick={modal.hide}>
            Done
          </Button>
        </div>
      </Modal>
    </div>
  )
}