import {
  useAccount,
  useContractRead,
  useContractWrite,
} from "wagmi";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { BigNumber } from "ethers";
import { Button } from "@/components/Button";
import {LoadingSpinner} from "@/components/LoadingSpinner";
import {TransactionButtonProps, TransactionButton} from "@/components/Transactions/TransactionButton";
import {googlePushDataLayer} from "@/util/analytics";
import {formatEther} from "@/util/converters";
import {Address, erc721Abi} from "viem";
import {
  useReadErc721GetApproved,
  useReadErc721IsApprovedForAll,
} from "@/util/generated";

const convertToBool = (data: any): boolean => {
  if (typeof data === "boolean") {
    return data;
  }
  return false;
};

export const useTokenApproval = () => {
  const [approved, approvalChanged] = useState<boolean>(false);
  return {approved, approvalChanged};
}

/**
 * We have to get approval from the user by calling ERC721#getApproval for all, before we are
 * allowed to transfer their token. This is a component that gathers all the logic within.
 */
export const TokenApprovalButton = ({
  contractAddress,
  to,
  approvalChanged,
  singleToken = false,
  tokenId = undefined,
  className,
  autoStart
}: {
  contractAddress: string;
  to: string;
  approvalChanged: (isApproved: boolean) => void;
  singleToken: boolean;
  tokenId?: BigNumber;
  className?: string;
  autoStart?: boolean;
}) => {
  const { address } = useAccount();
  const [approved, setApproved] = useState(false);

  const getApproved = useReadErc721GetApproved({
    address: contractAddress as Address,
    args: [singleToken && tokenId !== undefined ? BigInt(tokenId.toString()) : 0n],
    query: { enabled: singleToken && tokenId !== undefined }
  });

  const isApprovedForAll = useReadErc721IsApprovedForAll({
    address: contractAddress as Address,
    args: [address as Address, to as Address],
    query: { enabled: !singleToken }
  });

  useEffect(() => {
    if (getApproved.data || isApprovedForAll.data) {
      let approved = singleToken
        ? getApproved.data?.toString().toLowerCase() === to.toLowerCase()
        : convertToBool(isApprovedForAll.data);
      setApproved(approved);
      approvalChanged(approved);
    }
  }, [getApproved.data, isApprovedForAll.data]);

  const isLoading = getApproved.isLoading || isApprovedForAll.isLoading || getApproved.isFetching || isApprovedForAll.isFetching;

  useEffect(() => {
    if (singleToken) {
      getApproved.refetch();
    } else {
      isApprovedForAll.refetch();
    }
  }, [tokenId]);

  const transactionButtonProps: TransactionButtonProps = {
    id: "allow_erc721_transfer",
    alertOnError: true,
    loadingText: "Getting Approval...",
    addressOrName: contractAddress,
    contractInterface: erc721Abi,
    functionName: singleToken ? "approve" : "setApprovalForAll",
    args: singleToken ? [to, tokenId] : [to, true],
    enabled: !singleToken || tokenId !== undefined,
    onTransactionSuccess: () => {
      singleToken ? getApproved.refetch() : isApprovedForAll.refetch();
      googlePushDataLayer('nftTransferApproval', {
        'contractAddress': contractAddress,
        'spender': to,
      });
    }
  }

  const isSuccess = getApproved.isSuccess || isApprovedForAll.isSuccess;
  const data = getApproved.data || isApprovedForAll.data;

  return (
    <TransactionButton {...transactionButtonProps}
                       autoStart={autoStart && isSuccess && data !== undefined && !(singleToken ? data.toString().toLowerCase() === to.toLowerCase() : convertToBool(data))}
                       disabled={approved}
                       loading={isLoading}
                       className={className}>
      {!isLoading && approved && "Token Transfer Approved"}
      {!isLoading && !approved && "Approve ERC721 Transfer"}
      {isLoading && "Looking Up Approval"}
    </TransactionButton>
  );
};
