export const EthIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 20 30"
      fill="none"
    >
      <path
        opacity="0.9"
        d="M9.69231 0V20.7692L0 15.2308L9.69231 0Z"
        fill="white"
      />
      <path d="M9.69246 0V20.7692L19.3848 15.2308L9.69246 0Z" fill="white" />
      <path
        d="M9.69231 30.0002V22.6156L0 17.0771L9.69231 30.0002Z"
        fill="white"
      />
      <path
        opacity="0.9"
        d="M9.69246 30.0002V22.6156L19.3848 17.0771L9.69246 30.0002Z"
        fill="white"
      />
    </svg>
  );
};
