import {BiErrorCircle} from "react-icons/bi";

export const ErrorPanel = (
  { message, id }: { message: string, id?: string | undefined }) => {
  return (
    <div id={id} className="w-full p-2 flex flex-row gap-2 items-center justify-center text-error text-sm">
      <div><BiErrorCircle size={20} /></div>
      <div>{message}</div>
    </div>
  );
};
