import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {MdNavigateNext, MdNavigateBefore} from "react-icons/md";
import classNames from "classnames";
import {getNewsItems} from "@/components/news/newsitems";
import Link from "next/link";

export const News = ({onClick}: {onClick: Function}) => {
  const [step, setStep] = React.useState(0);
  const newsItems = getNewsItems();

  const renderCta = () => {
    const stepItem = newsItems[step];
    const ctaName = Array.isArray(stepItem.ctaName) ? stepItem.ctaName : [stepItem.ctaName];
    const ctaHref = Array.isArray(stepItem.ctaHref) ? stepItem.ctaHref : [stepItem.ctaHref];

    return <div className="responsive-flex items-center gap-2">
      {
        ctaName.map((name, index) => (
          <Link href={ctaHref[index]}
                onClick={() => onClick()}
             className="bg-[#94FF0B] rounded-full w-300px text-black tracking-wide uppercase text-xl px-4 py-2 hover:brightness-125">
            {name}
          </Link>
        ))
      }
    </div>
  }

  return (
    <div className="flex flex-row justify-between gap-2 py-4 relative w-full md:w-[600px] md:h-[400px]">
      <div className={classNames("text-neutral-content hover:text-white cursor-pointer flex items-center", {
        "invisible": step === 0,
      })} onClick={() => setStep(s => Math.max(0, s - 1))}>
        <MdNavigateBefore size={30} />
      </div>
      <div className="standard-stack !gap-8 items-center">
        <img src={newsItems[step].image} alt={newsItems[step].title} className={newsItems[step].imageClass} />
        <div className="standard-stack items-center">
          <div className="text-xl md:text-3xl font-bold">{newsItems[step].title}</div>
          <div className="text-sm md:text-md font-light max-w-[400px]">{newsItems[step].description}</div>
        </div>
        {renderCta()}
      </div>
      <div  className={classNames("text-neutral-content hover:text-white cursor-pointer flex items-center", {
        "invisible": step === newsItems.length - 1,
      })} onClick={() => setStep(s => Math.min(newsItems.length - 1, s + 1))}>
        <MdNavigateNext size={30} />
      </div>
    </div>
  )
}