export const MirrorIcon = () => {
  return (
    <svg width="12px" height="16px" viewBox="0 0 12 16" version="1.1" className="hover:fill-white fill-neutral-content">
      <defs></defs>
      <g stroke="none">
        <g id="mirrorxyz">
          <path d="M0,5.913048 C0,2.64736 2.64736,0 5.91304,0 C9.17872,0 11.82608,2.64736 11.82608,5.913048 L11.82608,15.42448 C11.82608,15.74232 11.5684,16 11.25056,16 L0.5755392,16 C0.2576776,16 0,15.74232 0,15.42448 L0,5.913048 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
  )
}