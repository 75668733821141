import {BigNumber} from "ethers";
import {useQuery} from "@tanstack/react-query";
import {getNft} from "@/api/datafetcher";
import {LoadingSpinner} from "@/components/LoadingSpinner";
import ModalImage from "react-modal-image";

export const ERC721Token = (
  {collectionAddress, tokenId, className}: { collectionAddress: string; tokenId: BigNumber; className?: string}
) => {

  const {data, isLoading} = useQuery({
    queryKey: ["contractAddress", collectionAddress, "tokenId", tokenId],
    queryFn: async () => await getNft(collectionAddress, tokenId),
    gcTime: 1000 * 60,
    staleTime: 1000 * 60 * 1,
  });

  if (isLoading) {
    return <LoadingSpinner/>;
  }
  if (data === undefined) {
    return <div className="text-error">Failed</div>;
  }

  const nft = data;

 return (
    <img
      src={nft.imageUrl || nft.imageThumbnailUrl}
      className={className || ""}
      alt={`${collectionAddress}_${tokenId.toString()}`}
    />
  );
};
