import { Listbox } from "@headlessui/react";
import { BsCaretDownFill } from "react-icons/bs";
import React from "react";
import classNames from "classnames";
import {twMerge} from "tailwind-merge";

export function dropdownOption<T>(value: T, label: string, icon?: string | React.ReactNode): DropdownOption<T> {
  return {value, label, icon};
}

export interface DropdownOption<T> {
  icon?: string | React.ReactNode;
  label: string;
  shortLabel?: string,
  value: T;
}

export const Dropdown = ({
  options,
  selected,
  onChange,
  placeholder,
  className,
  itemClassName,
  optionsClassName,
  label,
  renderLabel = true,
  hideCaret = false,
}: {
  options: DropdownOption<any>[];
  selected?: any;
  onChange: (value: any) => void;
  placeholder?: string | React.ReactNode;
  className?: string;
  itemClassName?: string;
  optionsClassName?: string;
  label?: string | React.ReactNode;
  renderLabel?: boolean;
  hideCaret?: boolean;
}) => {
  const selectedOption = options.find(o => o.value === selected);

  const renderOption = (option: DropdownOption<any>, renderLabel = true) => {
    return <div className="flex flex-row gap-2 items-center justify-start my-2 min-w-[24px]">
      { option.icon && (
        typeof option.icon === 'string'
          ? <img src={option.icon as string}
                 alt="token"
                 className="w-[24px] h-[24px]"/>
          : option.icon
      )}
      {renderLabel && option.label}
    </div>
  }

  return (
    <Listbox
      value={options}
      onChange={(value) => {
        onChange(value);
      }}
    >
      <div className="relative">
        {label && <Listbox.Label>{label}</Listbox.Label>}
        <Listbox.Button className={twMerge('input flex items-center justify-between gap-1 hover:bg-glass-focus', className || '')}>
          {selectedOption ? renderOption(selectedOption, renderLabel) : placeholder || "Select an option"}
          {!hideCaret && <BsCaretDownFill className="text-neutral-focus group-hover:text-primary" />}
        </Listbox.Button>
        <Listbox.Options className={twMerge('mt-1 absolute bg-main-bg whitespace-nowrap text-xs z-20 md:max-h-60 overflow-y-auto w-screen min-w-[100px] right-0 divide-y divide-neutral-content/50 rounded-md border border-neutral-content/50', optionsClassName || '')}>
          {options.map((option, i) => (
            <Listbox.Option
              key={option.value}
              value={option.value}
              className={twMerge(classNames("hover:bg-glass hover:cursor-pointer p-2", {
                "bg:glass-focus": option.value === selected,
                // "border-t border-neutral-content/20": i >= 0,
              }), itemClassName || '')}
            >
              {renderOption(option)}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};
