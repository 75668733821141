import {useQuery} from "@tanstack/react-query";
import {fetchBNPLData2ForItem, fetchBNPLOrder, fetchOptionData, fetchCollectionWithFloorPrice } from "@/api/datafetcher";
import {LoadingSpinner} from "@/components/LoadingSpinner";
import {compareBigNumber} from "@/util/comparators";
import React, {useEffect, useState, useContext} from "react";
import {EthDisplay} from "@/components/EthDisplay";
import {DateSelect} from "@/components/DateSelect";
import {flashloanFeeRate, getFlashloanFee} from "@/util/fees";
import {ERC721Token} from "@/components/ERC721/ERC721Token";
import {toBN} from "@/util/converters";
import {Tooltip as ReactTooltip} from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {SplitLabelValue} from "@/components/SplitLabelValue";
import {toMultimap} from "@/util/record";
import {TimeRemaining} from "@/components/TimeRemaining";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {useCollectionSymbol} from "@/util/useCollectionSymbol";
import {FaEthereum, FaLock} from "react-icons/fa";
import ReactSlider from "react-slider";
import {useRouter} from "next/router";
import {useAccountBalance} from "@/hooks/useAccountBalance";
import {UserBalanceContext} from "@/contexts/UserBalanceContext";
import {WASABI_BNPL_2, WASABI_CONDUIT, WASABI_OPTION} from "@/util/constants";
import {MdKeyboardArrowUp} from "react-icons/md";
import {Modal, useModal} from "@/components/Modal";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {useWhitelist} from "@/components/whitelist/useWhitelist";
import {LongContextData} from "@/components/BNPL/LongContext";
import {WasabiOptionAbi} from "@/contract/WasabiOptionAbi";
import {decodeEventLog, TransactionReceipt} from "viem";
import {BNPLItem2} from "@/types/bnpl_types";
import {LongViewAddToCart} from "@/components/BNPL/LongViewAddToCart";
import {TransactionButtonProps} from "@/components/Transactions/TransactionButton";
import {OptionType} from "@/types/types";
import {WasabiConduitAbi} from "@/contract/WasabiConduitAbi";
import {LongInfo} from "@/components/BNPL/LongInfo";
import {WasabiBNPLAbi} from "@/contract/WasabiBNPLAbi";
import {BiHelpCircle} from "react-icons/bi";
import {BsFillLightningChargeFill} from "react-icons/bs";
import {MarketplaceView} from "@/components/MarketplaceView";
import {useFeeManager} from "@/util/useFeeManager";
import {WasabiBNPL2Abi} from "@/contract/WasabiBNPL2Abi";

const sortByTotalAmount = (a: BNPLItem2, b: BNPLItem2) => compareBigNumber(a.amountNow.add(a.amountLater), b.amountNow.add(b.amountLater));
const sortByAmountNow = (a: BNPLItem2, b: BNPLItem2) => compareBigNumber(a.amountNow, b.amountNow);
const getTimeAmount = (item: BNPLItem2) => item.protocol === "wasabi" ? item.expiration : item.duration;

export const LongFooter = (props: LongContextData) => {
  const {collection, tokenId, close, collectionId} = props;
  const contractAddress = collection.contractAddress;
  const router = useRouter()
  const [selectedOrder, setSelectedOrder] = useState<BNPLItem2>();
  const [sliderIndex, setSliderIndex] = useState(0);
  const {refreshBalance} = useContext(UserBalanceContext);
  const whitelist = useWhitelist();
  const { ensSet } = router.query;

  const {getFee, getValueWithFee, feeRate} = useFeeManager();
  const getSelectedExpiration = () => getTimeAmount(selectedOrder!);

  const bnplModal = useModal(localStorage?.getItem('has_shown_bnpl_info') !== 'true');

  useEffect(() => {
    setSelectedOrder(undefined);
    setSliderIndex(0);
  }, [props.tokenId]);

  const setSelectedDate = (date: number, data: Map<number, BNPLItem2[]>) => {
    let order: BNPLItem2 = data.get(date)!.reduce((a, b) => a.amountNow.lt(b.amountNow) ? a : b);
    const newSliderIndex = (data.get(date)?.length || 1) - 1;
    setSelectedOrder(order);
    setSliderIndex(newSliderIndex);
  }

  const { data, isLoading} = useQuery({
    queryKey: ['bnplOrders', contractAddress, tokenId],
    queryFn: async () => {
      const data = await fetchBNPLData2ForItem(contractAddress, tokenId, ensSet as (string | undefined));
      const items = data.items.sort(sortByTotalAmount);
      if (items.length === 0) {
        return new Map<number, BNPLItem2[]>();
      }
      const expiryToOrders = toMultimap(items, getTimeAmount);
      expiryToOrders.forEach((asks, expiry) => {
        const sortedAsks = asks.sort((a, b) => -sortByAmountNow(a, b));
        expiryToOrders.set(expiry, sortedAsks);
      });
      return expiryToOrders;
    },
    enabled: router.isReady,
    refetchInterval: 10 * 1000,
  });

  useEffect(() => {
    if (data) {
      if (selectedOrder && data.has(getTimeAmount(selectedOrder))) { // Refresh slider index and check if the selectedOrder still exists
        const orders = data.get(getTimeAmount(selectedOrder))!;
        let index = orders.findIndex(o => o.orderId === selectedOrder.orderId);
        if (index < 0) { // selectedOrder no longer exists, pick the one closest in amount now
          const newOrder = orders.reduce((minDiffObject, currentObject) => {
            const diff = currentObject.amountNow.sub(selectedOrder.amountNow).abs();
            const minDiff = minDiffObject.amountNow.sub(selectedOrder.amountNow).abs();
            if (diff.lt(minDiff)) {
              return currentObject;
            }
            return minDiffObject;
          });
          index = orders.findIndex(o => o.orderId === newOrder.orderId);
          setSelectedOrder(newOrder);
        }
        if (sliderIndex !== index) {
          setSliderIndex(index);
        }
      } else {
        const expiry = Math.min(...Array.from(data.keys()));
        const orders = data.get(expiry)!;
        const index = orders.length - 1;
        setSliderIndex(index);
        setSelectedOrder(orders[index]);
      }
    }
  }, [data]);


  const collectionWithFloor = useQuery({
    queryKey: ["collectionDetails", collectionId],
    queryFn: async () => await fetchCollectionWithFloorPrice(collectionId)
  });


  const accountBalance = useAccountBalance();

  const renderTooltipContent = () => {
    if (!selectedOrder) {
      return undefined;
    }

    const feeAmount =
      selectedOrder.protocol === 'wasabi'
        ? getFee(selectedOrder.amountNow)
        : getFlashloanFee(selectedOrder.amountLater);
    const amountNow =
      selectedOrder.protocol === 'wasabi'
        ? selectedOrder.amountNow
        : selectedOrder.amountNow.sub(feeAmount);
    const amountLater =
      selectedOrder.protocol === 'wasabi'
        ? getValueWithFee(selectedOrder.amountLater)
        : selectedOrder.amountLater;
    return (
      <>
        <span
          className="text-white hover:text-neutral-content text-sm cursor-default text-right flex items-center gap-1 whitespace-nowrap"
          data-for="tooltip"
          id="price_tooltip"
        >
          <AiOutlineInfoCircle size={18} />
        </span>
        {/*<span>*/}
        {/*  {selectedOrder.protocol}*/}
        {/*</span>*/}
        <ReactTooltip anchorId="price_tooltip" id="tooltip" place="top" noArrow className="z-50">
          <div className="w-full">
            <span className="text-sm">Price Breakdown</span>
            <SplitLabelValue label="Cost">
              <EthDisplay
                size={4}
                value={amountNow}
                removeTrailingZeroes
                numDigits={4}
              />
            </SplitLabelValue>
            <SplitLabelValue label="Fee Rate">
              {
                selectedOrder.protocol === 'wasabi'
                  ? <span>{`${(feeRate * 100).toFixed(2)}%`}</span>
                  : <span>{`${(flashloanFeeRate * 100).toFixed(2)}%`}</span>
              }
            </SplitLabelValue>
            <SplitLabelValue label="Fee Amount">
              <EthDisplay
                size={4}
                value={feeAmount}
                removeTrailingZeroes
                numDigits={6}
              />
            </SplitLabelValue>
            <hr className="border-neutral-content/50" />
            <SplitLabelValue label="Due Now">
              <EthDisplay
                size={4}
                className="text-primary-content"
                value={selectedOrder.amountNow.add(feeAmount)}
                removeTrailingZeroes
                numDigits={4}
              />
            </SplitLabelValue>
            <hr className="border-neutral-content/50" />
            <SplitLabelValue
              label={
                <div className="flex flex-row gap-1">
                  Due Within <TimeRemaining epochSeconds={getSelectedExpiration()}
                                            isDuration={selectedOrder.protocol !== 'wasabi'}
                                            short={true} />
                </div>
              }
            >
              <EthDisplay
                size={4}
                value={amountLater}
                removeTrailingZeroes
                numDigits={4}
              />
            </SplitLabelValue>
            <hr className="border-neutral-content/50" />
            <SplitLabelValue label="Total">
              <EthDisplay
                size={4}
                className="text-primary-content"
                value={selectedOrder.amountNow.add(feeAmount).add(amountLater)}
                removeTrailingZeroes
                numDigits={4}
              />
            </SplitLabelValue>
          </div>
        </ReactTooltip>
      </>
    );
  };

  const onTransactionSuccess = (receipt: TransactionReceipt) => {
    refreshBalance?.();
    const log = receipt.logs.find(l => l.address.toLowerCase() === WASABI_OPTION.toLowerCase());
    if (log) {
      const decodedLog = decodeEventLog({
        abi: WasabiOptionAbi,
        data: log.data,
        topics: log.topics
      });
      if ('tokenId' in decodedLog.args) {
        const tokenId = decodedLog.args['tokenId']
        router.push(`/option/${tokenId.toString()}?justCreated=true`);
      }
    }
  }

  const renderBarContent = () => {
    if (!selectedOrder) {
      return undefined;
    }

    if (isLoading || !selectedOrder) {
      return (
        <div className="h-full flex items-center justify-center gap-2">
          <span>Loading</span> <LoadingSpinner />
        </div>
      );
    }

    const mapData = data!;
    const dates = Array.from(mapData.keys()).sort((a, b) => a - b);
    const selectedDate = getSelectedExpiration();

    const totalAmount = selectedOrder.amountLater.add(selectedOrder.amountNow);
    const breakEven = selectedOrder.protocol === 'wasabi' ? getValueWithFee(totalAmount) : totalAmount;

    const transactionChain: ChainItem[] = [];
    transactionChain.push({
      name: "Long",
      description: <div className="flex gap-4">
        <ERC721Token className="h-full max-h-[160px] border glassborder rounded-md"
                     collectionAddress={contractAddress}
                     tokenId={toBN(tokenId)} />
        <div className="standard-stack grow">
          <SplitLabelValue label="Collection">
            <span className="text-white">{collection.name}</span>
          </SplitLabelValue>
          <SplitLabelValue label="Duration">
            <div className="text-white">
              <TimeRemaining epochSeconds={getSelectedExpiration()}
                             isDuration={selectedOrder.protocol !== 'wasabi'}
                             short={true} />
            </div>
          </SplitLabelValue>
          <SplitLabelValue label="Breakeven Price">
            <EthDisplay value={breakEven}
                        className="text-white"
                        numDigits={4}
                        size={4}
                        removeTrailingZeroes={true} />
          </SplitLabelValue>
          <hr className="border-neutral-content/50"/>
          <SplitLabelValue label="You Pay">
            <EthDisplay
              className="text-white text-xl"
              value={getValueWithFee(selectedOrder.amountNow)}
              size={6}
              removeTrailingZeroes={true}
              numDigits={4} />
          </SplitLabelValue>
        </div>
      </div>,
      buttonQuery: () => useQuery<TransactionButtonProps>({
        queryKey: ['bnpl2_order', selectedOrder.orderId, selectedOrder.tokenId],
        queryFn: async () => {
          if (selectedOrder.protocol === 'wasabi') {
            const data =
              await fetchOptionData(
                selectedOrder.collectionAddress,
                OptionType.CALL,
                selectedOrder.expiration,
                selectedOrder.amountLater,
                toBN(selectedOrder.tokenId),
                undefined,
                undefined,
                undefined,
                true
              );
            const buyButtonProps: TransactionButtonProps = {
              id: "buy_option",
              loadingText: "Issuing option...",
              addressOrName: WASABI_CONDUIT,
              contractInterface: WasabiConduitAbi,
              functionName: "buyOption",
              args: data.option ? [data.option, data.signature] : [],
              overrides: {
                value: getValueWithFee(data.option?.premium || toBN(0)),
              },
              enabled: !accountBalance.isLoading && accountBalance.balance.gte(data.option?.premium || 0),
              onTransactionSuccess,
            }
            return buyButtonProps;
          }

          const data =
            await fetchBNPLOrder(
              selectedOrder.collectionAddress,
              selectedOrder.tokenId,
              selectedOrder.protocol,
              selectedOrder.orderId);

          if (!data.loanOrder) {
            throw new Error("Not implemented");
          }
          const loanOrder = data.loanOrder!;

          const buyButtonProps: TransactionButtonProps = {
            id: "bnpl_loan",
            loadingText: "Issuing option...",
            addressOrName: WASABI_BNPL_2,
            contractInterface: WasabiBNPL2Abi,
            functionName: "bnpl",
            args: [
              loanOrder.nftLending,
              loanOrder.borrowData,
              loanOrder.flashLoanAmount,
              loanOrder.marketplaceCallData,
              loanOrder.signatures
            ],
            overrides: {
              value: data.item.amountNow,
            },
            enabled: !accountBalance.isLoading && accountBalance.balance.gte(data.item.amountNow),
            onTransactionSuccess,
          }
          return buyButtonProps;
        }
      }),
    });

    let leverage = 1;
    if (collectionWithFloor.data) {
      leverage = collectionWithFloor.data!.floorPrice.div(selectedOrder.amountNow).toNumber();
      if (leverage < 1) {
        leverage = 1;
      }
    }
    return (
      <div className="w-full flex flex-col lg:flex-row justify-around px-4 items-center gap-4 lg:gap-2 lg:divide-x divide-neutral-content/20 py-4 lg:py-0">
        <div className="flex flex-row gap-2 items-center justify-start lg:justify-around w-full lg:divide-x divide-neutral-content/20">
          <div className="flex items-center justify-center">
            <ERC721Token className="h-full max-h-[100px] border glassborder rounded-md"
                         collectionAddress={contractAddress}
                         tokenId={toBN(tokenId)} />
          </div>
          <div className="standard-stack px-4 min-h-[75px]">
          <span className="whitespace-nowrap text-white text-[16px] font-light flex items-center gap-1">
            Pick Duration
            <BiHelpCircle id="duration_tooltip" className="w-4 h-4" />
            <ReactTooltip anchorId="duration_tooltip"
                          className="z-40 text-center"
                          place='top'>
              <span>
                Pick a duration for your Long position.
                <br/>
                You can close the position at any time before the expiration.
              </span>
            </ReactTooltip>
          </span>
            <span className="text-primary-content">
              <DateSelect dates={dates.map(d => ({date: d, numOptions: mapData.get(d)?.length || 0}))}
                          onChange={d => setSelectedDate(d, mapData)}
                          alwaysShowDropdown={true}
                          showNumOptions={true}
                          isDuration={selectedOrder.protocol !== 'wasabi'}
                          selected={selectedDate} />
            </span>
          </div>
          <div className="standard-stack px-4 min-h-[75px] min-w-[200px] xl:min-w-[300px]">
          <span className="whitespace-nowrap text-white text-[16px] font-light flex items-center gap-1">
            Set Leverage
          <BiHelpCircle id="leverage_tooltip" className="w-4 h-4" />
          <ReactTooltip anchorId="leverage_tooltip"
                        className="z-40 text-center"
                        place='top'>
              <span>
                Leverage is the ratio of the floor price to the amount you pay now.
              </span>
          </ReactTooltip>
          </span>
            <div className="flex flex-row items-center gap-2">
              {
                (mapData.get(selectedDate)?.length || 0) > 1 &&
                <ReactSlider
                  className="flex items-center justify-center w-full max-w-[250px]"
                  thumbClassName="customSlider-thumb"
                  trackClassName="customSlider-track"
                  defaultValue={1}
                  min={0}
                  step={0.1}
                  max={mapData.get(selectedDate)!.length - 1}
                  value={sliderIndex}
                  onChange={(e) => {
                    setSliderIndex(e);
                    setSelectedOrder(mapData.get(selectedDate)![Math.round(e)])
                  }}
                  onAfterChange={e => setSliderIndex(Math.round(e))}
                />
              }
              <div className="text-primary-content text-2xl flex items-center gap-2">
                {leverage}x
                <MarketplaceView marketplace={selectedOrder.protocol.toUpperCase()} size={6} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center justify-start lg:justify-around w-full lg:divide-x divide-neutral-content/20">
          <div className="standard-stack px-4 min-h-[75px]">
          <span className="whitespace-nowrap text-white text-[16px] font-light flex flex-row gap-1 items-center">
            Breakeven
            <BiHelpCircle id="breakeven_tooltip" className="w-4 h-4" />
            <ReactTooltip anchorId="breakeven_tooltip"
                          className="z-40 text-center"
                          html="You will start profiting if the floor price goes above the breakeven price."
                          place='top' />
          </span>
            <EthDisplay value={breakEven}
                        numDigits={4}
                        removeTrailingZeroes={true}
                        className="text-primary-content min-w-[90px] text-2xl" />
          </div>
          <div className="standard-stack px-4 min-h-[75px]">
          <span className="whitespace-nowrap text-white text-[16px] font-light flex flex-row gap-1">
            Price {renderTooltipContent()}
          </span>
            <EthDisplay value={selectedOrder.protocol === 'wasabi' ? getValueWithFee(selectedOrder.amountNow) : selectedOrder.amountNow}
                        numDigits={4}
                        removeTrailingZeroes={true}
                        className="text-primary-content min-w-[90px] text-2xl text-right" />
          </div>
          <div className="flex flex-col px-4 items-center gap-2 justify-center">
            {
              whitelist.canBuy ?
                <div className="flex lg:flex-col xl:flex-row gap-2">
                  <TransactionChain
                    id={`bnpl_footer_${selectedOrder.orderId}_${selectedOrder.tokenId}`}
                    className="xl:!text-lg xl:!py-3 whitespace-nowrap xl:max-h-[64px] xl:w-[200px]"
                    title={<div className="flex gap-2 items-center"><BsFillLightningChargeFill /> Buy Now</div>}
                    onSuccess={r => r !== undefined && onTransactionSuccess(r)}
                    transactionChain={transactionChain}
                    enabled={true}
                  />
                  {
                    selectedOrder.protocol === 'wasabi' &&
                    <LongViewAddToCart item={selectedOrder} onSuccess={close} />
                  }
                </div> :
                <a className="wasabi-button wasabi-button-primary lg:!text-lg lg:!py-3 whitespace-nowrap max-h-[64px]"
                   href="/pass">
                  <FaLock /> Get Access
                </a>
            }
          </div>
        </div>
      </div>
    )
  }

  const renderSkeletonLoading = () => (
    <div className="w-full grid grid-cols-11 gap-4 p-4 min-h-[132px]">
      <div className="col-span-1 bg-slate-600 animate-pulse rounded-md max-h-[100px] max-w-[100px]"></div>
      <div className="col-span-10 grid grid-cols-3 gap-4">
        <div className="rounded-md bg-slate-600 animate-pulse"/>
        <div className="rounded-md bg-slate-600 animate-pulse"/>
        <div className="rounded-md bg-slate-600 animate-pulse"/>
      </div>
    </div>
  );


  return (
    <div className="relative w-full mx-auto justify-evenly text-neutral-content text-lg flex flex-col items-center pv">
      <div className="absolute top-[-24px] bg-base-100 min-w-[200px] flex items-center justify-center hover:bg-base-200 cursor-pointer rounded-t-full border-t border-neutral-content/50 "
           onClick={bnplModal.show}>
        <MdKeyboardArrowUp size={24} />
      </div>
      <div className="bg-base-100 border-t border-neutral-content/50 mx-auto flex flex-col gap-2 justify-center items-center text-neutral-content text-lg w-full min-h-[132px]">
        { selectedOrder === undefined && renderSkeletonLoading()}
        {
          !isLoading && selectedOrder &&
          <Modal {...bnplModal}>
            <LongInfo contractAddress={contractAddress}
                      tokenId={tokenId}
                      selectedOrder={selectedOrder}
                      onAgree={() => {
                        localStorage.setItem('has_shown_bnpl_info', 'true');
                        bnplModal.hide();
                      }} />
          </Modal>
        }
        { !isLoading && selectedOrder && renderBarContent() }
      </div>
    </div>
  );
}
