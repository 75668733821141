import {useAccountPassBalance} from "@/hooks/useAccountPassBalance";
import {CHAIN_ID} from "@/util/constants";
import {BigNumber} from "ethers";

const maxPasses = 10;
const baseFee = 200; // 2%
const denominator = 10_000;
const discountPerPass = 15; // 0.15%

export const useFeeManager = () => {
  const passBalance = useAccountPassBalance();
  const feeRate = (baseFee - Math.min(passBalance.balance, maxPasses) * discountPerPass) / denominator;

  const getFee = (amount: BigNumber): BigNumber => {
    if (CHAIN_ID !== 1) {
      return BigNumber.from(0);
    }
    return amount.mul(baseFee - Math.min(passBalance.balance, maxPasses) * discountPerPass).div(denominator);
  }
  const getValueWithFee = (value: BigNumber): BigNumber => {
    return value.add(getFee(value));
  }
  const getValueWithoutFee = (value: BigNumber): BigNumber => {
    return value.sub(getFee(value));
  }
  return {
    isLoading: passBalance.isLoading,
    passBalance: passBalance.balance,
    feeRate,
    getFee,
    getValueWithFee,
    getValueWithoutFee,
  }
}