import {BigNumber} from "ethers";
import {CHAIN_ID} from "@/util/constants";

export const flashloanFeeRate = 0.0005; // 0.05%

export function getFlashloanFee(value: BigNumber): BigNumber {
  if (CHAIN_ID !== 1) {
    return BigNumber.from(0);
  }
  return value.mul(50).div(10000);
}

export function getFlashloanFeeFromAmountNow(value: BigNumber): BigNumber {
  if (CHAIN_ID !== 1) {
    return BigNumber.from(0);
  }
  return value.mul(50).div(10000);
}