import React, { useEffect, useState } from 'react';
import { useMobileMenu } from '@/contexts/MobileMenuContext';

export const TouchHandler: React.FC = () => {
  const [startX, setStartX] = useState<number | null>(null);
  const [startY, setStartY] = useState<number | null>(null);
  const mobileMenu = useMobileMenu();

  useEffect(() => {
    const handleTouchStart = (event: TouchEvent) => {
      const touch = event.touches[0];
      setStartX(touch.clientX);
      setStartY(touch.clientY);
    };

    const handleTouchEnd = (event: TouchEvent) => {
      if (startX === null || startY === null) return;

      const touch = event.changedTouches[0];
      const endX = touch.clientX;
      const endY = touch.clientY;
      const screenWidth = window.innerWidth;

      if (Math.abs(startY - endY) < 50) {
        if (startX < screenWidth / 3) {
          if (endX > startX) {
            mobileMenu.show();
          }
        } else if (startX < (2 * screenWidth) / 3) {
          if (endX < startX) {
            mobileMenu.close();
          }
        } else {
          if (endX < startX) {
            mobileMenu.close();
          }
        }
      }

      setStartX(null);
      setStartY(null);
    };

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [startX, startY]);

  return null;
};
