import {
  CollectionDetailStatic,
  Item,
  ListCollectionFilter,
  TimePeriod,
  Traits,
} from "@/types/dkoda_types";
import {mergeSectionData} from "@/api/populate_static_data";
import {
  AMMOrderResponse,
  ArbitrageData, Collection,
  CollectionOptionsResponse,
  CollectionWithFloorPrice,
  CollectionWithPoolData,
  CompetitionEntry,
  DkodaPricingConfiguration, EnsSet,
  FinalizeOptionResponse,
  GeofenceData,
  LPStats,
  LPTradeHistoryEntry,
  NewPoolRequestBody,
  OptionActivityData,
  OptionalDataResponse,
  OptionBacktestingSnapshot,
  OptionData,
  OptionType,
  PaginatedResponse,
  PoolAsk,
  PoolAskOrder, PoolAskOrderMigrated,
  PoolHistorySnapshot,
  StatusResponse,
  TradeHistoryEntry,
  UserStats,
  WasabiOptionPage,
  WasabiOptionTable,
  WasabiOptionWithMetadata,
  WasabiOptionWithMetadataAndValue,
  WasabiPoolWithCollection,
} from "@/types/types";
import {
  convertArbitrageData, convertBNPL2Data,
  convertBNPLOrder, convertCollectionLoanOffer,
  convertCollectionOptionsResponse,
  convertCollectionWithFloorResponse,
  convertCollectionWithPoolData,
  convertCompetitionEntry,
  convertDkodaPricingConfiguration,
  convertFinalizeOptionResponse, convertHighestBid,
  convertLPStats,
  convertLPTradeHistoryEntry,
  convertOptionActivityData,
  convertOptionalDataResponse,
  convertOptionAMMResponse,
  convertOptionBacktestingSnapshot,
  convertOptionData,
  convertOptionPage, convertOptionRolloverOrder,
  convertOptionWithMetadata,
  convertOptionWithMetadataAndValue,
  convertPaginatedResponse,
  convertPoolAsk,
  convertPoolAskOrder,
  convertPoolHistorySnapshot,
  convertTradeHistoryEntry,
  convertUserStats,
  convertWasabiAsk,
  convertWasabiBid,
  convertWasabiBidWithCollection,
  convertWasabiOptionTable,
  convertWasabiPoolWithCollection,
} from "@/util/responseConverters";
import {BigNumber, utils} from "ethers";
import moment from "moment";
import postData from "@/api/postRequest";
import {
  HighestBid,
  WasabiAsk, WasabiAskMigrated,
  WasabiBid,
  WasabiBidMigrated,
  WasabiBidWithCollection,
  PoolBidOrder,
} from "@/types/exchange_types";
import {formatEther, toBN} from "@/util/converters";
import {
  HistoricalPriceDuration,
  HistoricalPriceGranularity,
  HistoricalPriceResponse
} from "@/types/historical_price_api_types";
import {CollectionDepthResponse} from "@/types/reservoir_types";
import contractAddress from "@/pages/collection/[contractAddress]";
import {BNPLData2, BNPLOrder, CollectionLoanOffer, OptionRolloverOrder} from "@/types/bnpl_types";

const BASE_URL = process.env.NEXT_PUBLIC_BACKEND_API;

const handleError = (error: any, onError?: Function) => {
  console.error("Something went wrong: ", error);
  if (onError) {
    onError(error);
  }
};

export const searchCollections = async (keyword: string): Promise<PaginatedResponse<Collection>> => {
  const params = new URLSearchParams();
  params.set("q", keyword);
  let data = await fetch(`${BASE_URL}/api/searchCollections?${params}`);
  return await data.json();
};

export const listCollections = async (filter: ListCollectionFilter) => {
  const params = new URLSearchParams();
  if (filter.cursor) {
    params.set("cursor", `${filter.cursor}`);
  }
  if (filter.sortColumn) {
    params.set("sortColumn", filter.sortColumn);
  }
  const data = await fetch(`${BASE_URL}/api/collection?${params}`);
  return await data.json();
};

export const fetchCollectionData = async (contractAddress: String): Promise<CollectionDetailStatic> => {
  const data = await fetch(`${BASE_URL}/api/collection/${contractAddress}`);
  return await data.json();
};

export const fetchSectionsData = async (collectionId: string) => {
  if (!collectionId) {
    return;
  }
  const data = await fetch(
    `${BASE_URL}/api/collection/sections/${collectionId}`
  );
  return await data.json().then(mergeSectionData);
};

export const fetchOpportunities = async (collectionId: string) => {
  const data = await fetch(
    `${BASE_URL}/api/opportunities/${collectionId}?refresh=${true}`
  );
  return await data.json();
};

export const fetchAnalytics = async (
  contractAddress: string,
  timePeriod: string,
  traits?: Traits
) => {
  const params = new URLSearchParams();
  params.set("timePeriod", timePeriod);
  if (traits) {
    for (let trait of traits) {
      params.append("traitType", trait.type);
      params.append("traitValue", `${trait.value}`);
    }
  }
  const data = await fetch(
    `${BASE_URL}/api/analytics/${contractAddress}?${params}`
  );
  return await data.json();
};

export const fetchSales = async (
  contractAddress: string,
  timePeriod: TimePeriod,
  traits?: Traits
) => {
  const params = new URLSearchParams();
  params.set("timePeriod", timePeriod);
  if (traits) {
    for (let trait of traits) {
      params.append("traitType", trait.type);
      params.append("traitValue", `${trait.value}`);
    }
  }
  const data = await fetch(
    `${BASE_URL}/api/analytics/sales/${contractAddress}?${params}`
  );
  return await data.json();
};

export const getCollection = async (contractAddress: String): Promise<CollectionDetailStatic> => {
  let data = await fetch(`${BASE_URL}/static/collection/${contractAddress}`).then(a => a.json());
  return data as CollectionDetailStatic;
};

export const fetchOwnerTokens = async (
  contractAddress: string,
  owner: string,
  tokenIds?: BigNumber[]
): Promise<Item[]> => {
  const params = new URLSearchParams();
  if (tokenIds) {
    for (let tokenId of tokenIds) {
      params.append("tokenId", tokenId.toString());
    }
  }
  const data = await fetch(
    `${BASE_URL}/api/itemsOfOwner/${contractAddress}/${owner}?${params}`
  );
  return await data.json();
};

export const fetchAvailableTokens =
  async (contractAddress: string, poolAddress?: string, strike?: BigNumber, expiration?: number): Promise<PaginatedResponse<Item>> =>
{
  const params = new URLSearchParams();
  if (poolAddress) {
    params.set("poolAddress", poolAddress);
  }
  if (strike) {
    params.set("strike", strike.toString());
  }
  if (expiration) {
    params.set("expiration", expiration.toString());
  }
  let data =
    await fetch(`${BASE_URL}/api/availableItems/${contractAddress}?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, a => a as Item)
};

export const getNft = async (contractAddress: string, tokenId: BigNumber): Promise<Item> => {
  const data = await fetch(`${BASE_URL}/api/nft/${contractAddress}/${tokenId.toString()}`);
  return await data.json();
};

export const fetchCollectionOptions = async (
  contractAddress: string,
  optionType: OptionType,
  date: number | null
): Promise<CollectionOptionsResponse> => {
  const params = new URLSearchParams();
  if (date) {
    params.set("expirationDate", date.toString());
  }
  params.set("optionType", optionType === OptionType.CALL ? "CALL" : "PUT");
  let data = await fetch(
    `${BASE_URL}/api/collection/${contractAddress}/options?${params}`
  );
  data = await data.json();
  return convertCollectionOptionsResponse(data);
};

export const fetchCollectionsWithPools = async (
  pageParam: string
): Promise<PaginatedResponse<CollectionWithPoolData>> => {
  const response = await fetch(`${BASE_URL}/api/poolCollections`).then((d) => d.json());
  return convertPaginatedResponse(response, convertCollectionWithPoolData);
};

export const fetchCollectionWithPoolData = async (
  contractAddress: string,
  ensSet?: string | undefined
): Promise<CollectionWithPoolData> => {
  const params = new URLSearchParams();
  if (ensSet) {
    params.set("ensSet", ensSet);
  }
  return await fetch(`${BASE_URL}/api/collectionWithPool/${contractAddress}?${params}`)
    .then((d) => d.json())
    .then(convertCollectionWithPoolData);
};

export const fetchCollectionWithFloorPrice = async (contractAddress: string): Promise<CollectionWithFloorPrice> => {
  let data = await fetch(`${BASE_URL}/api/collectionWithFloor/${contractAddress}`);
  data = await data.json();
  return convertCollectionWithFloorResponse(data);
};

export const fetchOptionData = async (
  contractAddress: string,
  optionType: OptionType,
  expiration: number,
  strikePrice: BigNumber,
  tokenId: BigNumber | undefined = undefined,
  poolAddress: string | undefined = undefined,
  seller: string | undefined = undefined,
  askId: BigNumber | undefined = undefined,
  withoutSelections = false
): Promise<FinalizeOptionResponse> => {
  const params = new URLSearchParams();
  params.set("withoutSelections", String(withoutSelections));
  params.set("contractAddress", contractAddress);
  params.set("optionType", OptionType[optionType]);
  params.set("expiration", String(expiration));
  params.set("strikePrice", strikePrice.toString());
  if (tokenId) {
    params.set("tokenId", tokenId.toString());
  }
  if (poolAddress) {
    params.set("poolAddress", poolAddress);
  }
  if (seller) {
    params.set("seller", seller);
  }
  if (askId) {
    params.set("askId", askId.toString());
  }

  let data = await fetch(`${BASE_URL}/api/option/?${params}`);
  data = await data.json();
  return convertFinalizeOptionResponse(data);
};

export const fetchOptionPrices = async (
  contractAddress: string,
  poolConfiguration: DkodaPricingConfiguration,
  volatilityMultiplier: string | number,
  expirationDate: number
): Promise<PaginatedResponse<OptionData>> => {
  const params = new URLSearchParams();
  params.set("contractAddress", contractAddress);
  params.set("expiration", String(expirationDate));
  params.set("strikeGranularity", "0.5");
  params.set(
    "minStrikePrice",
    utils.formatEther(poolConfiguration.minStrike)
  );
  params.set(
    "maxStrikePrice",
    utils.formatEther(poolConfiguration.maxStrike)
  );
  params.set("volatilityMultiplier", String(volatilityMultiplier));
  if (poolConfiguration.callEnabled) {
    params.append("optionType[]", "CALL");
  }
  if (poolConfiguration.putEnabled) {
    params.append("optionType[]", "PUT");
  }

  let data = await fetch(`${BASE_URL}/api/option/prices?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, convertOptionData);
};

export const fetchPoolBacktesting = async (
  newPoolRequest: NewPoolRequestBody,
  pricingConfiguration: DkodaPricingConfiguration
): Promise<OptionBacktestingSnapshot[]> => {
  let address = await getGoerliMapping(newPoolRequest.nftAddress);
  const params = new URLSearchParams();
  params.set("startDate", String(moment().subtract(4, "months").unix()));
  params.set("contractAddress", address);
  params.set(
    "minStrikePrice",
    utils.formatEther(pricingConfiguration.minStrike)
  );
  params.set(
    "maxStrikePrice",
    utils.formatEther(pricingConfiguration.maxStrike)
  );
  params.set(
    "minDuration",
    pricingConfiguration.minDuration.toString()
  );
  params.set(
    "maxDuration",
    pricingConfiguration.maxDuration.toString()
  );
  params.set(
    "volatilityMultiplier",
    String(pricingConfiguration.premiumMultiplierPercent)
  );
  if (pricingConfiguration.putEnabled) {
    params.append("optionType[]", "PUT");
  }
  if (pricingConfiguration.callEnabled) {
    params.append("optionType[]", "CALL");
  }
  params.set("initialEth", utils.formatEther(newPoolRequest.initialEthAmount));
  for (let initialTokenId of newPoolRequest.initialTokenIds) {
    params.append("initialTokenId[]", initialTokenId.toString());
  }
  let data = await fetch(
    `https://nytcgpzsmj.us-east-1.awsapprunner.com/pool/?${params}`
  );
  data = await data.json();
  return convertOptionBacktestingSnapshot(data);
};

export const fetchPools = async (
  owner?: string, commodityAddress?: string): Promise<PaginatedResponse<WasabiPoolWithCollection>> => {
  const params = new URLSearchParams();
  if (owner) {
    params.set("owner", owner);
  }
  if (commodityAddress) {
    params.set("commodityAddress", commodityAddress);
  }
  let data = await fetch(`${BASE_URL}/api/pools?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, convertWasabiPoolWithCollection);
};

export const fetchPool = async (contractAddress: string): Promise<WasabiPoolWithCollection> => {
  let data = await fetch(`${BASE_URL}/api/pool/${contractAddress}`);
  data = await data.json();
  return convertWasabiPoolWithCollection(data);
};

export const fetchOption = async (optionId: string, serialize: boolean): Promise<WasabiOptionWithMetadata> => {
  let data = await fetch(`${BASE_URL}/api/option/${optionId}`)
    .then(d => d.json());
  if (serialize) {
    return convertOptionWithMetadata(data);
  }
  return data as WasabiOptionWithMetadata;
};

export const fetchTradeStatusImage = async (optionId: BigNumber): Promise<OptionalDataResponse<string>> => {
  let data = await fetch(`${BASE_URL}/api/option/tradeStatusImage/${optionId.toNumber()}`);  
  data = await data.json();
  return convertOptionalDataResponse(data, a => a);
};

export const getTradeStatusDownloadImageLink = (positionId: string) => {
  return `${BASE_URL}/api/option/tradeStatusImage/download/${positionId}`;
}

export const fetchOptionPage = async (optionId: BigNumber | string, serialize: boolean): Promise<WasabiOptionPage> => {
  let data = await fetch(`${BASE_URL}/api/optionPage/${optionId.toString()}`)
    .then(d => d.json());
  if (serialize) {
    return convertOptionPage(data);
  }
  return data as WasabiOptionPage;
};

export const getOptions = async (
  ownerAddress?: string,
  contractAddress?: string,
  poolAddress?: string,
  fetchExpired?: boolean
): Promise<WasabiOptionWithMetadataAndValue[]> => {
  const params = new URLSearchParams();
  if (ownerAddress) {
    params.set("ownerAddress", ownerAddress);
  }
  if (contractAddress) {
    params.set("contractAddress", contractAddress);
  }
  if (poolAddress) {
    params.set("poolAddress", poolAddress);
  }
  params.set("fetchExpired", String(fetchExpired || false));
  let url = `${BASE_URL}/api/options/list?${params}`;
  let data = await fetch(url);
  data = await data.json();
  // @ts-ignore
  return data.map(convertOptionWithMetadataAndValue);
};

export const fetchActivity = async (
  nftAddress?: string,
  optionId?: string,
): Promise<PaginatedResponse<OptionActivityData>> => {
  const params = new URLSearchParams();
  if (nftAddress) {
    params.set("commodityAddress", nftAddress);
  }
  if (optionId) {
    params.set("optionId", optionId);
  }
  let data = await fetch(`${BASE_URL}/api/option/activity?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, convertOptionActivityData);
};

export const fetchBuyOrder = async (contractAddress: string): Promise<AMMOrderResponse> => {
  let data = await fetch(`${BASE_URL}/api/mock/order/${contractAddress}`);
  data = await data.json();
  return convertOptionAMMResponse(data);
}

export const fetchLeaderboard = async (count?: number): Promise<PaginatedResponse<CompetitionEntry>> => {
  const params = new URLSearchParams();
  if (count) {
    params.set('count', count.toString());
  }
  let data = await fetch(`${BASE_URL}/api/competition/leaderboard?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, convertCompetitionEntry);
}

export const fetchUserEntry = async (address: string, includeTransactions?: boolean): Promise<UserStats | null> => {
  const params = new URLSearchParams();
  if (includeTransactions) {
    params.set('includeTransactions', 'true');
  } else {
    params.set('includeTransactions', 'false');
  }
  let data = await fetch(`${BASE_URL}/api/competition/stats/${address}?${params}`);
  try {
    data = await data.json();
    return convertUserStats(data);
  } catch (e) {
    if (data.ok) {
      return null;
    }
    throw e;
  }
}

export type WhitelistResponse = "NOT_WHITELISTED" | "BANNED" | "WHITELISTED" | "NEEDS_TO_SIGN" | "NEEDS_PASS";

export const fetchWhitelistData = async (address: string): Promise<WhitelistResponse> => {
  let data = await fetch(`${BASE_URL}/api/whitelistStatus/${address}`);
  return await data.text() as WhitelistResponse;
}

export const tweetSent = async (address: string, hash: string): Promise<void> => {
  return await postData(`${BASE_URL}/api/tweetSent`, {address, hash});
}

export const getChecklistItems = async (address: string): Promise<String[]> => {
  let data = await fetch(`${BASE_URL}/api/competition/checklist/${address}`);
  return await data.json();
}

const testToActualNFTLookup: Record<string, string> = {};
export const getGoerliMapping = async (contractAddress: string): Promise<string> => {
  if (testToActualNFTLookup[contractAddress.toLowerCase()]) {
    console.debug("Found cache for ", contractAddress);
    return testToActualNFTLookup[contractAddress.toLowerCase()];
  }

  console.debug("Fetching collection mapping for", contractAddress);
  let data = await fetch(`${BASE_URL}/api/collection/goerliMapping/${contractAddress}`)
  const address = await data.text();
  testToActualNFTLookup[contractAddress.toLowerCase()] = address.toLowerCase();
  return address;
}

export const getUserTradeHistory = async (address: string, nextPageToken?: string): Promise<PaginatedResponse<TradeHistoryEntry>> => {
  const params = new URLSearchParams();
  if (nextPageToken) {
    params.set('offset', nextPageToken);
  }
  let data = await fetch(`${BASE_URL}/api/trades/${address}?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, convertTradeHistoryEntry);
}

export const saveAsk = async (ask: WasabiAskMigrated): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/ask`, ask);
}

export const savePoolAsk = async (order: PoolAskOrderMigrated): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/poolAsk`, {
    ...order,
    ask: {
      ...order.ask,
      optionType: OptionType[order.ask.optionType]
    }
  });
}

export const savePoolPid = async (bid: PoolBidOrder): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/poolBid`, bid)
}

export const fetchPoolBid = async (optionId: any): Promise<OptionalDataResponse<PoolBidOrder>> => {
  let data = await fetch(`${BASE_URL}/api/poolBid/${optionId}`);
  data = await data.json();
  return convertOptionalDataResponse(data, a => a);
}

export const cancelPoolBid = async(hash: string): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/poolBid/cancel/${hash}`).then(d => d.json());
}

export const fetchAsk = async (optionId: any): Promise<OptionalDataResponse<WasabiAsk>> => {
  let data = await fetch(`${BASE_URL}/api/ask/${optionId}`);
  data = await data.json();
  return convertOptionalDataResponse(data, convertWasabiAsk);
}

export const cancelAsk = async(hash: string): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/ask/cancel/${hash}`);
}

export const fetchExtraDeth = async (address: string): Promise<BigNumber> => {
  let data = await fetch(`${BASE_URL}/api/extraDeth/${address}`);
  return toBN(await data.text());
}

export const saveBid = async (bid: WasabiBidMigrated): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/bid`, {
    ...bid,
    optionType: OptionType[bid.optionType]
  });
}

export const cancelBid = async(hash: string): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/bid/cancel/${hash}`);
}

export const fetchBids = async (
  contractAddress: string,
  strikePrice?: BigNumber,
  optionType?: OptionType,
  expiration?: number
): Promise<PaginatedResponse<WasabiBid>> => {
  const params = new URLSearchParams();
  params.set("contractAddress", contractAddress);
  if (strikePrice) {
    params.set("strikePrice", formatEther(strikePrice).toString());
  }
  if (optionType) {
    params.set("optionType", OptionType[optionType]);
  }
  if (expiration) {
    params.set("expiration", expiration.toString());
  }
  let data = await fetch(`${BASE_URL}/api/bid?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, convertWasabiBid);
}

export const fetchHighestWasabiBid = async (
  contractAddress: string,
  strikePrice: BigNumber,
  optionType: OptionType,
  expiration: number
): Promise<OptionalDataResponse<WasabiBid>> => {
  const params = new URLSearchParams();
  params.set("contractAddress", contractAddress);
  params.set("strikePrice", formatEther(strikePrice).toString());
  params.set("optionType", OptionType[optionType]);
  params.set("expiration", expiration.toString());
  let data = await fetch(`${BASE_URL}/api/bid/highest?${params}`);
  data = await data.json();
  return convertOptionalDataResponse(data, convertWasabiBid);
}

export const fetchUserBids = async (address: string): Promise<PaginatedResponse<WasabiBidWithCollection>> => {
  let data = await fetch(`${BASE_URL}/api/${address}/bid`);
  data = await data.json();
  return convertPaginatedResponse(data, convertWasabiBidWithCollection);
}

export const fetchPoolHistory = async (poolAddress: string): Promise<PoolHistorySnapshot[]> => {
  let data = await fetch(`${BASE_URL}/api/pool/${poolAddress}/history`);
  data = await data.json();
  return (data as unknown as any[]).map(convertPoolHistorySnapshot);
}

export const updatePoolPricing = async (pricingConfiguration: DkodaPricingConfiguration, signature: string): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/pool/wasabiPricingConfiguration`, {
    pricingConfiguration: {
      ...pricingConfiguration,
      maxStrike: pricingConfiguration.maxStrike.toString(),
      minStrike: pricingConfiguration.minStrike.toString(),
      minDuration: pricingConfiguration.minDuration.toString(),
      maxDuration: pricingConfiguration.maxDuration.toString()
    },
    signature
  });
}

export const getPoolPricing = async (poolAddress: string): Promise<OptionalDataResponse<DkodaPricingConfiguration>> => {
  let data = await fetch(`${BASE_URL}/api/pool/${poolAddress}/wasabiPricingConfiguration`);
  data = await data.json();
  return convertOptionalDataResponse(data, convertDkodaPricingConfiguration);
}

export const fetchPoolAsks = async (poolAddress: string, pageKey: string): Promise<PaginatedResponse<PoolAsk>> => {
  let data = await fetch(`${BASE_URL}/api/pool/${poolAddress}/asks?pageKey=${pageKey}`);
  data = await data.json();
  return convertPaginatedResponse(data, convertPoolAsk);
}

export const fetchHistoricalPrices = async (
  contractAddress: string,
  duration: HistoricalPriceDuration,
  granularity: HistoricalPriceGranularity
): Promise<HistoricalPriceResponse> => {
  const params = new URLSearchParams();
  params.set("duration", duration);
  params.set("granularity", granularity);
  let data = await fetch(`${BASE_URL}/api/collection/${contractAddress}/historicalPrices?${params}`);
  return await data.json();
}

export const fetchDepthPrices = async (
  contractAddress: string,

): Promise<CollectionDepthResponse> => {
  const params = new URLSearchParams();
  params.set("collection", contractAddress);
  params.set("side", "sell");
  let data = await fetch(`${BASE_URL}/api/floorDepth?${params}`);
  return await data.json();
}

export const saveUserSignature = async (address: string, signature: string): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/userSignature`, { address, signature });
}

export const saveUserReferral = async (address?: string, code?: string | null): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/userReferral`, {address, code});
};

export const fetchArbitrageData = async (optionId: string): Promise<OptionalDataResponse<ArbitrageData>> => {
  let data = await fetch(`${BASE_URL}/api/option/${optionId}/arbitrageOption`);
  data = await data.json();
  return convertOptionalDataResponse(data, convertArbitrageData);
}

export const fetchBNPLData2 = async (contractAddress: string, range?: string, sort?: string, ensSetId?: string, nextPageToken?: string): Promise<BNPLData2> => {
  const params = new URLSearchParams();
  if (range) {
    params.set('range', range);
  }
  if (ensSetId) {
    params.set('ensSetId', ensSetId);
  }
  if (nextPageToken) {
    params.set('nextPageToken', nextPageToken);
  }
  params.set("sort", sort || "PREMIUM");
  let data = await fetch(`${BASE_URL}/api/${contractAddress}/bnpl2?${params}`);
  data = await data.json();
  return convertBNPL2Data(data);
}

export const fetchBNPLData2ForItem = async (contractAddress: string, tokenId: string, ensSetId?: string): Promise<BNPLData2> => {
  const params = new URLSearchParams();
  if (ensSetId) {
    params.set('ensSetId', ensSetId);
  }
  let data = await fetch(`${BASE_URL}/api/${contractAddress}/bnpl2/${tokenId}?${params}`);
  data = await data.json();
  return convertBNPL2Data(data);
}

export const fetchBNPLOrder = async (contractAddress: string, tokenId: string, protocol: string, orderId: string): Promise<BNPLOrder> => {
  let data = await fetch(`${BASE_URL}/api/${contractAddress}/bnpl2/${tokenId}/order?protocol=${protocol}&orderId=${orderId}`);
  data = await data.json();
  return convertBNPLOrder(data);
}

export const getLPTradeHistory = async (address: string, nextPageToken?: string ): Promise<PaginatedResponse<LPTradeHistoryEntry>> => {
  const params = new URLSearchParams();
  if (nextPageToken) {
    params.set('offset', nextPageToken);
  }

  let data = await fetch(`${BASE_URL}/api/lptrades/${address}?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, convertLPTradeHistoryEntry)
} 

export const getLPStats = async (address: string): Promise<LPStats> => {
  let data = await fetch(`${BASE_URL}/api/lpstats/${address}`);
  data = await data.json();
  return convertLPStats(data);
}

export const getGeofenceData = async (): Promise<GeofenceData> => {
  const data = await fetch("https://backend-mainnet.wasabi.xyz/api/geo");
  return await data.json();
}

export const fetchOptionTable = async (
  contractAddress: string,
  optionType: OptionType,
  date?: number | null
): Promise<WasabiOptionTable> => {
  const params = new URLSearchParams();
  if (date) {
    params.set("expirationDate", date.toString());
  }
  params.set("optionType", optionType === OptionType.CALL ? "CALL" : "PUT");
  let data = await fetch(
    `${BASE_URL}/api/collection/${contractAddress}/optionTable?${params}`
  );
  data = await data.json();
  return convertWasabiOptionTable(data);
}

export const fetchHighestBid = async (optionId: string): Promise<HighestBid> => {
  let data = await fetch(`${BASE_URL}/api/${optionId}/highestBid`);
  data = await data.json();
  return convertHighestBid(data);
}

export const fetchEnsSets = async (): Promise<EnsSet[]> => {
  const data = await fetch(`${BASE_URL}/api/ensSets`);
  return await data.json();
}

export const fetchOptionRolloverOffers = async (optionId: string): Promise<CollectionLoanOffer[]> => {
  const params = new URLSearchParams();
  params.set('optionId', optionId);

  const data = await fetch(`${BASE_URL}/api/rollover?${params}`);
  return (await data.json()).map(convertCollectionLoanOffer);
}

export const fetchOptionRolloverOrder = async (
  optionId: string, protocol: string, orderId: string, address: string
): Promise<OptionRolloverOrder> => {
  const params = new URLSearchParams();
  params.set('protocol', protocol);
  params.set('orderId', orderId);
  params.set('address', address);
  params.set('optionId', optionId);

  const data = await fetch(`${BASE_URL}/api/rollover/order?${params}`);
  return convertOptionRolloverOrder(await data.json());
}