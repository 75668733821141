import {PoolBidOrder} from "@/types/exchange_types";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {EthDisplay} from "@/components/EthDisplay";
import React from "react";
import {toBN} from "@/util/converters";
import {WasabiPoolAbi} from "@/contract/WasabiPoolAbi";
import {useFeeManager} from "@/util/useFeeManager";

export interface Props {
  poolAddress: string;
  poolBidOrder: PoolBidOrder;
  onSuccess: Function;
}

export const AcceptPoolBidButton = ({ poolAddress, poolBidOrder, onSuccess }: Props) => {
  const { signature, bid } = poolBidOrder;
  const price = toBN(bid.price);
  const {getFee} = useFeeManager();
  const fee = getFee(price);

  const transactionChain: ChainItem[] = [
    {
      name: "Accept Bid",
      description: <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <span>Selling 1 Option</span>
        </div>
        <EthDisplay
          value={price.sub(fee)}
          tokenAddress={bid.tokenAddress}
          size={4}
          removeTrailingZeroes={true}
          numDigits={4} />
      </div>,
      button: {
        id: "acceptPoolBid",
        addressOrName: poolAddress,
        contractInterface: WasabiPoolAbi,
        functionName: "acceptPoolBid",
        args: [bid, signature],
        loadingText: "Accepting Bid"
      }
    }
  ];

  return (
    <TransactionChain
      id="accept_pool_bid"
      enabled={true}
      title={<EthDisplay value={price.sub(fee)} tokenAddress={bid.tokenAddress} size={4} label={"Accept Bid"} />}
      transactionChain={transactionChain}
      onSuccess={() => onSuccess()} />
  );
}