export const LongInfoImage1 = ({payNow, payLater, numDays}: {payNow: number, payLater: number, numDays: number}) => {
  const diff = payNow / payLater;
  const leftHeight = Math.max(payNow > payLater ? 200 : Math.ceil(diff * 200), 10) / 10 * 10;
  const rightHeight = Math.max(payNow > payLater ? Math.ceil(200 / diff) : 200, 10);
  return (
    <div className="flex gap-8 items-end justify-evenly border-b border-b-neutral-content px-8 overflow-hidden !pb-0 box-content">
      <div className="standard-stack !gap-0">
        <span className="text-primary text-center text-xs">Today</span>
        <span className="text-primary text-center text-lg mb-2">{payNow}Ξ</span>
        <div style={{height: `${leftHeight}px`}}
          className={`w-[75px] bg-primary glowingShadow rounded-t-md`}></div>
      </div>
      <div className="standard-stack !gap-1">
        <span className="text-neutral-content text-center text-xs">Within {numDays} days</span>
        <span className="text-neutral-content text-center text-lg">{payLater}Ξ</span>
        <div className="border-t border-x border-dashed border-neutral-content/50 pt-4 px-20 rounded-t-md">
          <div style={{height: `${rightHeight}px`}}
               className={`w-[75px] bg-neutral-content/50 rounded-t-md`}></div>
        </div>
      </div>
    </div>
  );
}