import {defineChain} from "viem";

export const blastSepolia = defineChain({
  id: 168587773,
  name: "Blast Sepolia",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
  },
  network: "blast-sepolia",
  rpcUrls: {
    default: {
      http: ["https://sepolia.blast.io"]
    },
    public: {
      http: ["https://sepolia.blast.io"]
    }
  }
});

export const blast = defineChain({
  id: 81457,
  name: "Blast",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
  },
  network: "blast",
  rpcUrls: {
    default: {
      http: ["https://holy-practical-owl.blast-mainnet.quiknode.pro/590d29b28faafbe6e3f80846cdc50e3f4c5356b0"]
    },
    public: {
      http: ["https://holy-practical-owl.blast-mainnet.quiknode.pro/590d29b28faafbe6e3f80846cdc50e3f4c5356b0"]
    }
  }
});