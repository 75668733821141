import React from "react";
import {OptionActionContextData} from "@/components/Option/actions/OptionActionContext";
import {PoolBidButton} from "@/components/Pool/PoolBidButton";
import {AcceptAskAsPoolOwner} from "@/components/Option/listing/AcceptAskAsPoolOwner";
import {useQuery} from "@tanstack/react-query";
import {fetchHighestBid} from "@/api/datafetcher";
import {AcceptBidButton} from "@/components/Bid/AcceptBidButton";
import {AcceptPoolBidButton} from "@/components/Bid/AcceptPoolBidButton";
import {Button, ButtonType} from "@/components/Button";
import {ListOptionButton} from "@/components/Option/listing/ListOptionButton";
import {BiLinkExternal} from "react-icons/bi";
import {CHAIN_ID} from "@/util/constants";
import {OptionType} from "@/types/types";
import Link from "next/link";
import {useAccount} from "wagmi";

export const OptionActionFooter = (props: OptionActionContextData) => {
  const highestBidQuery = useQuery({
    queryKey: ["highestBid", props.option.id],
    queryFn: async () => await fetchHighestBid(props.option.id),
    refetchInterval: 60 * 1000,
    enabled: props.option.side === "BUY"
  });

  const {address} = useAccount();

  return (
    <div className="relative w-full mx-auto justify-evenly text-neutral-content text-lg flex flex-col items-center pv">
      <div className="bg-base-100 border-t border-neutral-content/50 mx-auto text-neutral-content text-lg w-full min-h-[80px] flex items-center justify-center">
        <div className="w-full xl:w-4/5 mx-4 flex flex-row gap-4 items-center justify-between">
          <div className="flex flex-row gap-4 items-center">
            {
              props.option.side === "SELL" &&
              <>
                <PoolBidButton option={props.option} wide={true} />
                <AcceptAskAsPoolOwner option={props.option} />
              </>
            }
            {
              props.option.side === "BUY" &&
              <>
                {
                  highestBidQuery.data?.bid &&
                  <AcceptBidButton
                    optionId={props.option.id}
                    poolAddress={props.option.poolAddress}
                    wasabiBid={highestBidQuery.data.bid}
                    onSuccess={() => window.location.reload()} />
                }
                {
                  highestBidQuery.data?.poolBid &&
                  <AcceptPoolBidButton
                    poolAddress={props.option.poolAddress}
                    poolBidOrder={highestBidQuery.data.poolBid}
                    onSuccess={() => window.location.reload()} />
                }
                {
                  highestBidQuery.isSuccess && !highestBidQuery.data.bid && !highestBidQuery.data.poolBid &&
                  <Button className="min-w-[150px]" disabled={true}>
                    Accept Bid
                  </Button>
                }
                {
                  <ListOptionButton option={props.option} />
                }
                {
                  CHAIN_ID === 1 && props.option.owner?.toLowerCase() === address?.toLowerCase() && props.option.optionType === OptionType.CALL &&
                  <Link href={`/option/${props.option.id}/rollover`}>
                    <Button buttonType={ButtonType.PRIMARY} className="w-full">
                      Rollover (Extend)
                    </Button>
                  </Link>
                }
              </>
            }
          </div>
          <a href={`/option/${props.option.id}`} className="wasabi-button wasabi-button-secondary">
            <BiLinkExternal/> View Option
          </a>
        </div>
      </div>
    </div>
  )
}
