import { formatEther } from "ethers/lib/utils";
import { BigNumber, ethers } from "ethers";
import { EthIcon } from "@/components/EthIcon";
import {DropdownOption} from "@/components/Dropdown";
import {Dropdown} from "@/components/Dropdown";
import {ALLOWED_TOKENS, CHAIN_ID} from "@/util/constants";
import {twMerge} from "tailwind-merge";

const eth = {
  icon: <EthIcon className="w-6 h-6" />,
  value: "",
  label: "ETH"
};
export const EthInput = ({
  value,
  onChange,
  canSelectToken = false,
  selectedToken = "",
  tokenChanged = () => {},
  className = '',
  allowTokens = false
}: {
  value: BigNumber | null;
  onChange: (value: BigNumber | null) => void;
  canSelectToken?: boolean;
  selectedToken?: string;
  tokenChanged?: (tokenAddress: string | undefined) => void;
  className?: string,
  allowTokens?: boolean
}) => {
  let options: DropdownOption<string>[] = allowTokens ? ALLOWED_TOKENS.map(t => {
    return {
      icon: t.icon || <EthIcon className="w-6 h-6" />,
      value: t.address,
      label: t.symbol
    };
  }) : [];
  options = [eth, ...options];

  const token = ALLOWED_TOKENS.find(a => a.address.toLowerCase() === selectedToken.toLowerCase());
  const icon =
    <div className="tooltip" data-tip={token ? token.symbol : "ETH"}>
      {
        token?.icon !== undefined
          ? <img src={token.icon} className={`w-6 h-6`} alt={token.symbol} />
          : <EthIcon className={`w-6 h-6`} />
      }
    </div>

  return (
    <div className={twMerge("flex items-center gap-2", className || '')}>
      {
        !canSelectToken
          ? icon
          : <Dropdown options={options}
                      selected={selectedToken}
                      placeholder="Select"
                      onChange={(value) => {
                        tokenChanged(value || undefined)
                      }} />
      }
      <input
        key="ethinput"
        min={0}
        step={0.1}
        type="number"
        placeholder="Amount"
        className="input"
        value={value !== null ? Number(formatEther(value)) : ""}
        onChange={(e) => {
          if (e.target.value) {
            const newNumber = ethers.utils.parseEther(e.target.value);
            onChange(newNumber);
          } else {
            onChange(null);
          }
        }}
      />
    </div>
  );
};
