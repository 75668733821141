import { formatEther } from "ethers/lib/utils";
import { BigNumber} from "ethers";
import { EthIcon } from "@/components/EthIcon";
import {ALLOWED_TOKENS} from "@/util/constants";
import {ERC20} from "@/types/types";
import React from "react";
import {prettifyNumber} from "@/util/converters";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useId from "@/hooks/useId"

export const EthDisplay = (
  {
    value,
    className = "",
    size = 6,
    numDigits = 2,
    label,
    tokenAddress = "",
    removeTrailingZeroes = false,
    prettify = false,
    labelOnRight,
    showPlusOnPositive = false,
  }: {
    value: BigNumber,
    className?: string,
    size?: number,
    numDigits?: number,
    label?: string | React.ReactNode,
    tokenAddress?: string | null | undefined,
    removeTrailingZeroes?: boolean,
    prettify?: boolean;
    labelOnRight?: boolean;
    showPlusOnPositive?: boolean;
  }) => {

  const token: ERC20 | undefined =
    ALLOWED_TOKENS.find(a => a.address.toLowerCase() === tokenAddress?.toLowerCase());

  let formattedValue = Number(formatEther(value)).toFixed(numDigits);
  if (removeTrailingZeroes) {
    formattedValue = parseFloat(formattedValue).toString();
  }
  if (prettify) {
    formattedValue = prettifyNumber(parseFloat(formattedValue));
  }
  if (showPlusOnPositive && value.gt(0)) {
    formattedValue = "+" + formattedValue;
  }
  const tooltipId = useId();
  
  return (
    <div className={"flex items-center gap-2 " + className} >
      {label && !labelOnRight && <span>{label}</span>}
      {formattedValue}
      <div id={tooltipId}>
        {
          token?.icon !== undefined
            ? <img src={token.icon} className={`w-${size} h-${size}`} alt={token.symbol} />
            : <EthIcon className={`w-${size} h-${size}`} />
        }
      </div>
      {label && labelOnRight && <span>{label}</span>}
      <ReactTooltip
        anchorId={tooltipId}
        place="top"
        content={token ? token.symbol : "ETH"}
        className="z-50"
        style={{ backgroundColor: "#3b485f", color: "#98a2b3" }}
      />
    </div>
  );
};
