import {useAccount} from "wagmi";
import {useHasMounted} from "@/hooks/useHasMounted";
import {useQuery} from "@tanstack/react-query";
import {fetchPassBalance} from "@/api/perpsDataFetcher";

export const useAccountPassBalance = () => {
  const {address} = useAccount();
  const hasMounted = useHasMounted();

  const query = useQuery({
    queryKey: ['pass_balance', address],
    queryFn: async () => await fetchPassBalance(address || ''),
    enabled: !!address !== undefined && hasMounted,
  });

  const balance = query.data || 0;
  return {
    isLoading: query.isLoading,
    balance,
    multiplier: parseFloat((1 + Math.min(10, balance) * 0.1).toFixed(1))
  }
}

export const useAddressPassBalance = (address: string) => {
  const hasMounted = useHasMounted();

  const query = useQuery({
    queryKey: ['pass_balance', address],
    queryFn: async () => await fetchPassBalance(address || ''),
    enabled: !!address !== undefined && hasMounted,
  });

  const balance = query.data || 0;
  return {
    isLoading: query.isLoading,
    balance,
    multiplier: parseFloat((1 + Math.min(10, balance) * 0.1).toFixed(1))
  }
}
