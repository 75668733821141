import {connectorsForWallets} from "@rainbow-me/rainbowkit";
import {ALCHEMY_API_KEY, CHAIN_ID, WALLET_CONNECT_PROJECT_ID} from "@/util/constants";
import {blast, blastSepolia, Chain, goerli, mainnet, sepolia} from "wagmi/chains";
import {createConfig, http} from "wagmi";
import {
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
  okxWallet,
  rabbyWallet,
  coinbaseWallet,
  ledgerWallet,
  phantomWallet
} from '@rainbow-me/rainbowkit/wallets';

let chain: Chain;
if (CHAIN_ID === 1) {
  chain = mainnet;
} else if (CHAIN_ID === sepolia.id) {
  chain = sepolia;
} else if (CHAIN_ID === blastSepolia.id) {
  chain = blastSepolia;
} else if (CHAIN_ID === blast.id) {
  chain = blast;
} else {
  chain = goerli;
}

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [ metaMaskWallet, phantomWallet, rainbowWallet, walletConnectWallet, okxWallet, rabbyWallet, coinbaseWallet, ledgerWallet ],
    },
  ],
  {
    appName: "Wasabi",
    projectId: WALLET_CONNECT_PROJECT_ID,
  }
);

const transports: any = {};
if (CHAIN_ID === blastSepolia.id || CHAIN_ID === blast.id) {
  transports[chain.id] = http(chain.rpcUrls.default.http[0]);
} else if (CHAIN_ID === sepolia.id) {
  transports[chain.id] = http(`https://eth-sepolia.g.alchemy.com/v2/${ALCHEMY_API_KEY}`);
} else {
  transports[chain.id] = http(`https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_API_KEY}`);
}

export const config = createConfig({
  chains: [chain],
  connectors: connectors,
  transports: transports,
  ssr: true, // If your dApp uses server side rendering (SSR)
});
