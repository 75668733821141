
import React, {createContext, useState, useContext, FC, ReactNode} from 'react';
import {TransactionType} from "@/types/types";


interface MobileMenuContext {
  isOpened: boolean;
  toggleOpened: () => void;
  show: () => void;
  close: () => void;
}

interface MobileMenuProviderType extends React.PropsWithChildren<{}> {  }

const MobileMenuContext = createContext<MobileMenuContext>({
  isOpened: false,
  toggleOpened: () => {},
  show: () => {},
  close: () => {}
});

export const MobileMenuProvider: FC<MobileMenuProviderType> = ({ children }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <MobileMenuContext.Provider value={{
      isOpened,
      toggleOpened: () => setIsOpened(!isOpened),
      show: () => setIsOpened(true),
      close: () => setIsOpened(false)
    }}>
      {children}
    </MobileMenuContext.Provider>
  );
};

export const useMobileMenu = () => {
  const context = useContext(MobileMenuContext);
  if (!context) {
    throw new Error('useTransaction must be used within a TransactionProvider');
  }
  return context;
}