import React, {FC, createContext, useState, useContext} from "react";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

interface AlertContextInterface {
  show: boolean;
  showError: (message: string) => any;
  showSuccess: (message: string) => any;
  message: string;
  type: 'error' | 'success';
  hide: () => any;
}

export const AlertContext = createContext<AlertContextInterface>({
  show: false,
  showError: () => {},
  showSuccess: () => {},
  message: '',
  type: 'error',
  hide: () => {}
});

export const AlertContextProvider: FC<Props> = ({ children }) => {
  const [show, setShow] = useState<boolean>(false);
  const [type, setType] = useState<'error' | 'success'>('error');
  const [message, setMessage] = useState<string>();

  const showError = (value: string) => {
    setType('error');
    setMessage(value);
    setShow(true);
    setTimeout(() => setShow(false), 2000);
  }

  const showSuccess = (value: string) => {
    setType('success');
    setMessage(value);
    setShow(true);
    setTimeout(() => setShow(false), 2000);
  }

  return (
    <AlertContext.Provider value={{show, showError, showSuccess, message: message || '', type, hide: () => setShow(false)}}>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  return useContext(AlertContext);
}
