import React, {useEffect, useState} from 'react';
import {usePendingTransaction} from '@/contexts/PendingTransactionContext';
import {LoadingSpinner} from "@/components/LoadingSpinner";
import {fetchCheckPositionByTxHash} from "@/api/perpsDataFetcher";

const PendingTransactionBox: React.FC = () => {
    const { pendingTransactions, removeTransaction } = usePendingTransaction();

    useEffect(() => {
        const fetchTransactionData = async () => {
            for (const transaction of pendingTransactions) {
                const data = await fetchCheckPositionByTxHash(transaction.transactionHash, transaction.transactionType);
                if (data) {
                    removeTransaction(transaction.transactionHash);
                }
            }
        };

        if (pendingTransactions.length > 0) {
            const interval = setInterval(fetchTransactionData, 5000);
            return () => clearInterval(interval);
        }
    }, [pendingTransactions]);

    return (
        <div>
            {pendingTransactions.length > 0 && (
                <div className="flex flex-row fixed bottom-[63px] right-0 typography-brand-body-l-caps min-h-[40px] px-6 py-2 [clip-path:polygon(30px_0,100%_0,100%_50%,calc(100%)_100%,0_100%,0_50%)] w-[400px] bg-[#32d581] group-focus-visible:bg-white hover:bg-white cursor-pointer text-black fadeRight">
                    <LoadingSpinner />
                    <span className="text-black p-1 ml-2">Indexing {pendingTransactions.length} transaction{pendingTransactions.length > 1 ? 's' : ''}...</span>
                </div>
            )}
        </div>
    );
};


export default PendingTransactionBox;
