export const LongInfoImage2 = ({payNow}: {payNow: string}) => {
  return (
    <div className="flex gap-12 items-end justify-evenly border-b border-b-neutral-content px-24 overflow-hidden !pb-0">
      <div className="">
        <div className="flex flex-col mb-4">
          <span className="text-primary text-center text-xs">You Paid</span>
          <span className="text-primary text-center text-lg">{payNow}Ξ</span>
        </div>
        <div className="h-[20px] w-[75px] bg-primary glowingShadow rounded-t-md"></div>
        <div className="h-[150px] w-[75px] bg-neutral-content/50"></div>
      </div>
      <div className="">
        <div className="flex flex-col mb-2">
          <span className="text-primary text-center text-lg">Profit</span>
          <span className="text-primary text-center text-lg">∞</span>
        </div>
        <div className="h-[50px] w-[75px] bg-primary glowingShadow rounded-t-md"></div>
        <div className="h-[150px] w-[75px] bg-neutral-content/50 "></div>
      </div>
      <div className="diagonalGrayLines h-[150px] w-[300px] absolute border-t border-x border-dashed border-neutral-content/50 rounded-t-md"></div>
    </div>
  );
}