const BADGE_ID = "TQwOTExNDIwMzg2N";
let ALCHEMY_URL = `https://alchemyapi.io/?r=badge:${BADGE_ID}`;
const ALCHEMY_ANALYTICS_URL = `https://analytics.alchemyapi.io/analytics`

export function logBadgeClick() {
  console.debug("logBadgeClick");
  if (typeof window === "undefined" || window === null) {
    return;
  }
  fetch(`${ALCHEMY_ANALYTICS_URL}/badge-click`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      badge_id: BADGE_ID,
    }),
  });
  // @ts-ignore
  window.open(ALCHEMY_URL, '_blank').focus();
}

export function logBadgeView() {
  console.debug("logBadgeView")
  fetch(`${ALCHEMY_ANALYTICS_URL}/badge-view`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      badge_id: BADGE_ID,
    }),
  });
}

export function isBadgeInViewpoint(bounding: DOMRect) {
  return (
    bounding.top >= 0
    && bounding.left >= 0
    && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}