import React, {useState} from "react";
import {AiFillTag, AiOutlineArrowDown} from "react-icons/ai";
import {Button, ButtonType} from "@/components/Button";
import classNames from "classnames";
import {Carousel} from 'react-responsive-carousel';
import {ERC721Token} from "@/components/ERC721/ERC721Token";
import {formatEther, toBN} from "@/util/converters";
import {SplitLabelValue} from "@/components/SplitLabelValue";
import {EthDisplay} from "@/components/EthDisplay";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {LongInfoImage1} from "@/components/BNPL/LongInfoImage1";
import {getNumDaysUntil} from "@/util/dateutils";
import {LongInfoImage2} from "@/components/BNPL/LongInfoImage2";
import {LongInfoImage3} from "@/components/BNPL/LongInfoImage3";
import {BNPLItem2} from "@/types/bnpl_types";
import {TimeRemaining} from "@/components/TimeRemaining";
import {useFeeManager} from "@/util/useFeeManager"; // requires a loader

interface Props {
  onAgree?: Function;
  contractAddress: string;
  tokenId: string,
  selectedOrder: BNPLItem2
}

export const LongInfo = (
  { onAgree, contractAddress, tokenId, selectedOrder }: Props
) => {
  const [currentStep, setCurrentStep] = useState(0);
  const {getValueWithFee} = useFeeManager();

  const payNow = getValueWithFee(selectedOrder.amountNow);
  const payLater = getValueWithFee(selectedOrder.amountLater);
  const payNowValue = parseFloat(formatEther(payNow).toFixed(3));
  const payLaterValue = parseFloat(formatEther(payLater).toFixed(3));

  const numDays = getNumDaysUntil(selectedOrder.expiration);
  const steps = [
    {
      title: "Long",
      description: <span>
        Buy NFTs for only a fraction of the cost. Pay the rest up until a date of your choosing.
        <br/><br/>
        No hidden fees, no interest, no loan.
      </span>,
      image: <LongInfoImage1
        numDays={numDays}
        payLater={payLaterValue}
        payNow={payNowValue} />,
      sideView: <div className="standard-stack p-2">
        <div className="standard-frame w-[200px] h-[200px]">
          <ERC721Token className="rounded-md h-full object-scale-down" collectionAddress={contractAddress} tokenId={toBN(tokenId)} />
        </div>
        <SplitLabelValue label="Current Price">
          <EthDisplay value={payNow.add(payLater)}
                      numDigits={3}
                      removeTrailingZeroes={true}
                      size={4} />
        </SplitLabelValue>
        <hr className="border-neutral-content/50 w-full"/>
        <SplitLabelValue label="You Pay" className="text-white">
          <EthDisplay value={payNow}
                      numDigits={3}
                      removeTrailingZeroes={true}
                      size={4}
                      className="text-white text-lg" />
        </SplitLabelValue>
      </div>
    },
    {
      title: "Profit Anytime by Selling the NFT or Position",
      description: <span>
        If the price appreciates, sell the NFT to any marketplace at anytime via Wasabi to take profits.
        Alternatively, you can sell the position anytime on Wasabi's marketplace.
      </span>,
      image: <LongInfoImage2 payNow={payNowValue.toString()} />,
      sideView: <div className="standard-stack p-2 items-center">
        <div className="standard-frame w-[100px] h-[100px]">
          <ERC721Token className="h-full rounded-md object-scale-down" collectionAddress={contractAddress} tokenId={toBN(tokenId)} />
        </div>
        <AiOutlineArrowDown />
        <div className="standard-frame grid grid-cols-2 gap-2 p-2 w-[100px] h-[100px] items-center justify-center">
          <img src="/static/opensea.svg" alt="OPENSEA" className="h-[30px] w-[30px]"/>
          <div className="flex items-center justify-center">
            <img src="/static/blur.png" alt="BLUR" className="!h-[30px] !w-[30px]"/>
          </div>
          <div className="flex items-center justify-center">
            <img src="/static/collectionxyz.svg"
                 alt="COLLECTIONXYZ"
                 className="!h-[30px] !w-[30px]"/>
          </div>
          <img src="/static/x2y2.svg" alt="X2Y2" className="h-[30px] w-[30px]"/>
        </div>
      </div>
    },
    {
      title: "Pay in Full, Take Ownership",
      description: <span>
        Pay the remaining amount until the expiry date to claim full ownership.
        If the total amount is not paid, the NFT is returned to the previous owner.
      </span>,
      image: <LongInfoImage3 expiry={selectedOrder.expiration} />,
      sideView: <div className="standard-stack p-2 items-center">
        <div className="standard-frame w-[200px] h-[200px]">
          <ERC721Token className="rounded-md h-full object-scale-down" collectionAddress={contractAddress} tokenId={toBN(tokenId)} />
        </div>
        <SplitLabelValue label="Already paid" className="w-full">
          <EthDisplay value={payNow}
                      size={4}
                      numDigits={3}
                      removeTrailingZeroes={true} />
        </SplitLabelValue>
        <hr className="border-neutral-content/50 w-full"/>
        <SplitLabelValue label={<div className="flex items-center gap-1">Within <TimeRemaining epochSeconds={selectedOrder.expiration} short={true}/></div>}
                         className="text-white w-full">
          <EthDisplay value={payLater}
                      numDigits={3}
                      removeTrailingZeroes={true}
                      size={4}
                      className="text-white text-lg" />
        </SplitLabelValue>
      </div>
    }
  ]

  return (
    <div className="standard-frame p-6 standard-stack !gap-4 items-center max-w-[900px]">
      <span className="w-full flex flex-row gap-2 items-center"><AiFillTag /> Long</span>
      <hr className="border-neutral-content/50 w-full"/>
      <Carousel selectedItem={currentStep}
                className="w-full"
                swipeable={false}
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}>
        {
          steps.map((step, i) => (
            <div id={`item${i}`}
                 key={`bnpl_info_${i}`}
                 className="carousel-item relative w-full standard-stack text-left">
              <div className="standard-frame w-full flex items-center justify-evenly gap-2 p-4 my-2">
                <div>
                  {step.image}
                </div>
                { step.sideView }
              </div>
              <span className="text-lg text-white w-full">{step.title}</span>
              <span className="text-md text-neutral-content w-full text-sm">{step.description}</span>
            </div>
          ))
        }
      </Carousel>
      <div>
        <Button buttonType={ButtonType.SECONDARY}
                onClick={() => {
                  if (currentStep < steps.length - 1) {
                    setCurrentStep(s => s + 1);
                  } else {
                    onAgree?.();
                  }
                }}>
          {
            currentStep < steps.length - 1
              ? "Continue"
              : "I Agree to the Terms"
          }
        </Button>
      </div>
      <div className="w-full flex items-center justify-center gap-3">
        {
          steps.map((s, index) => (
            <div className="cursor-pointer standard-stack items-center !gap-1 text-xs"
                 key={`step_item_${index}`}
                 onClick={() => setCurrentStep(index)}>
              <span className={classNames({
                "text-neutral-content": index > currentStep,
                "text-white": index <= currentStep
              })}>{index + 1}</span>
              <div className={classNames('overflow-hide !w-2 !h-2 bg-primary rounded-full', {
                     "glassborder": index > currentStep,
                     "bg-primary border border-primary": index <= currentStep
                   })}>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}