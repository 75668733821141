import {FC, createContext, useContext, useState, useEffect} from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchETHPrice} from "@/api/perpsDataFetcher";
import {useUserSelection} from "@/contexts/UserSelectionContext";
import {useIsDesktop} from "@/hooks/useIsDesktop";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

interface EthPriceContextInterface {
  ethPrice: number | undefined;
  showInUsd: boolean;
  setShowInUsd: (value: boolean) => any;
}

export const EthPriceContext = createContext<EthPriceContextInterface>({
  ethPrice: undefined,
  showInUsd: false,
  setShowInUsd: (value: boolean) => {},
});

export const useEthPrice = () => {
  return useContext(EthPriceContext);
}

export const EthPriceContextProvider: FC<Props> = ({ children }) => {
  const isDesktop = useIsDesktop();
  const {userSelections, handleSelection} = useUserSelection();

  const {data: ethPrice} = useQuery({
    queryKey: ['ethPrice'],
    queryFn: async () => fetchETHPrice(),
    refetchInterval: 10 * 1000, // 10 seconds
  })

  const setShowInUsd = (v: boolean) => handleSelection('showAsUsd', v);

  return (
    <EthPriceContext.Provider value={{ethPrice, showInUsd: userSelections.showAsUsd || false, setShowInUsd}}>
      {children}
    </EthPriceContext.Provider>
  );
};
