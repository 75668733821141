export type Extractor<K, V> = (value: V) => K;

export function toMap<K, V>(values: V[], keyExtractor: Extractor<K, V>): Map<K, V> {
  return values.reduce((map, value) => {
    const key = keyExtractor(value);
    return map.set(key, value);
  }, new Map<K, V>());
}

export function toMultimap<K, V>(values: V[], keyExtractor: Extractor<K, V>): Map<K, V[]> {
  return values.reduce((map, value) => {
    const key = keyExtractor(value);
    const valuesForKey = map.get(key) || [];
    return map.set(key, [...valuesForKey, value]);
  }, new Map<K, V[]>());
}