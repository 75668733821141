import {WasabiBidMigrated} from "@/types/exchange_types";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {TokenApprovalButton} from "@/components/ERC721/TokenApprovalButton";
import {WASABI_CONDUIT, WASABI_OPTION} from "@/util/constants";
import {toBN} from "@/util/converters";
import {EthDisplay} from "@/components/EthDisplay";
import React from "react";
import {WasabiConduitAbi} from "@/contract/WasabiConduitAbi";
import {AddressZero} from "@ethersproject/constants";
import {useFeeManager} from "@/util/useFeeManager";

export interface Props {
  optionId: string;
  poolAddress: string;
  wasabiBid: WasabiBidMigrated;
  onSuccess: Function;
}

export const AcceptBidButton = ({ optionId, poolAddress, wasabiBid, onSuccess }: Props) => {
  const {getFee} = useFeeManager();
  const { signature, ...bid } = wasabiBid;
  if (!bid.optionTokenAddress) {
    bid.optionTokenAddress = AddressZero;
  }
  const price = toBN(bid.price);
  const fee = getFee(price);

  const transactionChain: ChainItem[] = [
    {
      name: "Allow Option Transfer",
      description: "Allow the transfer of your options to sell.",
      stepRenderer: (onSuccess, onFailure) =>
        <TokenApprovalButton
          autoStart={true}
          to={WASABI_CONDUIT}
          singleToken={false}
          contractAddress={WASABI_OPTION}
          approvalChanged={approved => {
            if (approved) {
              onSuccess();
            }
          }}
          className="capitalize text-xs w-full"
        />
    },
    {
      name: "Accept Bid",
      description: <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <span>Selling 1 Option</span>
        </div>
        <EthDisplay
          value={price.sub(fee)}
          tokenAddress={wasabiBid.tokenAddress}
          size={4}
          removeTrailingZeroes={true}
          numDigits={4} />
      </div>,
      button: {
        id: "acceptBid",
        addressOrName: WASABI_CONDUIT,
        contractInterface: WasabiConduitAbi,
        functionName: "acceptBid",
        args: [optionId, poolAddress, bid, signature],
        loadingText: "Accepting Bid"
      }
    }
  ]
  return (
    <TransactionChain
      id="accept_bid"
      enabled={true}
      title={<EthDisplay value={price.sub(fee)} tokenAddress={bid.tokenAddress} size={4} label={"Accept Bid"} />}
      transactionChain={transactionChain}
      onSuccess={() => onSuccess()}/>
  );
}