import {slide as Menu} from "react-burger-menu";
import React from "react";
import {useMobileMenu} from "@/contexts/MobileMenuContext";
import Link from "next/link";
import {IoChevronDown, IoChevronForward} from "react-icons/io5";
import {useHeaderLinks} from "@/hooks/useHeaderLinks";
import {useFooterLinks} from "@/hooks/useFooterLinks";
import {PiCurrencyDollarBold, PiLinkSimple} from "react-icons/pi";
import {BiLinkExternal} from "react-icons/bi";
import {GrClose} from "react-icons/gr";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {FaEthereum, FaNewspaper} from "react-icons/fa";
import classNames from "classnames";
import {useLegalLinks} from "@/hooks/useLegalLinks";
import {AppDropdown} from "@/components/Header/AppDropdown";
import {NewsButton} from "@/components/news/NewsButton";
import {twMerge} from "tailwind-merge";

export const MobileNavigation = () => {
  const {isOpened, close} = useMobileMenu();
  const headerLinks = useHeaderLinks();
  const footerLinks = useFooterLinks();
  const legalLinks = useLegalLinks();
  const [showMisc, setShowMisc] = React.useState(false);
  const {showInUsd, setShowInUsd} = useEthPrice();

  return (
    <Menu pageWrapId="content-container"
          outerContainerId="app-container"
          isOpen={isOpened}
          customBurgerIcon={false}
          onClose={close}
    >
      <div className="!flex flex-1 flex-col justify-between bg-main-bg h-full pb-safe border-r border-r-neutral-content/50">
        <div className="flex flex-col divide-y divide-neutral-content/50 border-b border-b-neutral-content/50 overflow-y-auto no-scrollbar">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2">
              <img src="/static/wasabi_logo.svg"
                   className="w-[120px] p-4"
                   alt="wasabi"/>
              <AppDropdown/>
            </div>
            <div onClick={close} className="px-4">
              <GrClose/>
            </div>
          </div>
          <div className="flex flex-col divide-y divide-neutral-content/20">
            {
              headerLinks
                .filter(l => !l.disabled)
                .map(l => (
                  <Link href={l.to}
                        onClick={close}
                        className={twMerge("p-4 flex flex-row items-center justify-between hover:bg-glass-focus", l.color ? `text-[${l.color}]` : '')}
                        key={`link_${l.id}`}>
                    <span className="flex flex-row gap-4 items-center">{l.icon} {l.name}</span>
                    <IoChevronForward className="text-neutral-content"/>
                  </Link>
                ))
            }
            <div className="p-4 flex flex-row items-center justify-between hover:bg-glass-focus"
                 onClick={() => setShowInUsd(!showInUsd)}>
              <span className="flex flex-row gap-4 items-center">
                {
                  showInUsd
                    ? <PiCurrencyDollarBold />
                    : <FaEthereum className={classNames({
                      'text-neutral-content': showInUsd,
                      'text-white': !showInUsd
                    })} />
                }
                <span>Currency</span>
              </span>
              <div className="text-xs text-blue-300">
                Change to {showInUsd ? "ETH" : "USD"}
              </div>
            </div>
            <NewsButton className="text-white p-4 flex flex-row items-center gap-4 hover:bg-glass-focus" onClick={close}>
              <FaNewspaper /> What's New?
            </NewsButton>
            <div className="p-4 flex flex-row items-center justify-between hover:bg-glass-focus"
                 onClick={() => setShowMisc(s => !s)}>
              <span className="flex flex-row gap-4 items-center"><PiLinkSimple/> Other</span>
              {
                showMisc
                  ? <IoChevronDown className="text-neutral-content"/>
                  : <IoChevronForward className="text-neutral-content"/>
              }
            </div>
            {
              showMisc &&
              <div className="flex flex-col divide-y divide-neutral-content/20">
                {
                  legalLinks.map(link => (
                    <Link href={link.href}
                          key={`navlink_${link.id}`}
                          target="_blank"
                          onClick={close}
                          className="px-6 py-2 flex flex-row items-center justify-between hover:bg-glass-focus text-sm text-neutral-content">
                    <span className="flex flex-row gap-2 items-center">{link.name}</span>
                      <BiLinkExternal/>
                    </Link>
                  ))
                }
              </div>
            }
          </div>
        </div>
        <div className="w-full flex flex-row justify-evenly py-6">
          {
            footerLinks.map(link => (
              <Link href={link.href}
                    key={`navlink_${link.id}`}
                    target={link.inNewTab ? "_blank" : undefined}
                    onClick={close}
                    className="hover:bg-glass-focus text-neutral-content text-lg flex items-center">
                {link.icon}
              </Link>
            ))
          }
        </div>
      </div>
    </Menu>
  )
}