import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import React, {ReactElement, ReactNode, useEffect} from "react";
import { useRouter } from "next/router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactGA from "react-ga4";

require('util/patch.js');

import { WagmiProvider } from 'wagmi';
import { ThemeContextProvider } from "@/contexts/ThemeContext";
import NextNProgress from "nextjs-progressbar";
import "../styles/globals.css";
import Head from "next/head";
import { DefaultSeo } from "next-seo";
import { UserBalanceContextProvider } from "@/contexts/UserBalanceContext";
import { useScreen } from "@/util/useScreen";
import { ShoppingCartContextProvider } from "@/contexts/ShoppingCartContext";
import { ShoppingCart } from "@/components/ShoppingCart/ShoppingCart";
import { ProSidebarProvider } from "react-pro-sidebar";
import { AlertContextProvider } from "@/contexts/AlertContext";
import { Alert } from "@/components/Alert/Alert";
import { getBaseUrl, getOgImage, PERPS_VIEW } from "@/util/constants";
import { Footer } from "@/components/Footer/Footer";
import { LongContextProvider } from "@/components/BNPL/LongContext";
import { OptionActionContextProvider } from "@/components/Option/actions/OptionActionContext";
import * as process from "process";
import { EthPriceContextProvider } from "@/contexts/EthPriceContext";
import { UserSelectionProvider } from "@/contexts/UserSelectionContext";
import PendingTransactionBox from "@/components/PendingTransactionBox";
import { TransactionProvider } from "@/contexts/PendingTransactionContext";
import { config } from "../config";
import AddToHomeScreen from "@/components/AddToHomeScreen/AddToHomeScreen";
import {useHasMounted} from "@/hooks/useHasMounted";
import {MobileMenuProvider} from "@/contexts/MobileMenuContext";
import {MobileNavigation} from "@/components/MobileNavigation";
import {CustomHeaderContentContextProvider} from "@/contexts/CustomHeaderContentContext";
import {BuildProvider} from "@/contexts/BuildContext";
import {TouchHandler} from "@/components/TouchHandler";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps: { ...pageProps } }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page);
  const isDesktop = useScreen('sm');
  const router = useRouter();
  const isBase = router.pathname === '/' && PERPS_VIEW;
  const { ref } = router.query;
  const hasMounted = useHasMounted();

  // @ts-ignore
  ReactGA.initialize(process.env.NODE_ENV === "development"
    ? "G-1RM8ZF6QDQ"
    : process.env.NEXT_PUBLIC_GA_TRACKING_MEASUREMENT_ID);

  if (ref) {
    localStorage.setItem('ref', ref.toString());
  }

  return (
    <>
      <Head>
        <title>Wasabi</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
      </Head>
      <DefaultSeo
        title="Wasabi"
        description="The Decentralized Leverage Protocol"
        canonical={getBaseUrl()}
        openGraph={{
          url: getBaseUrl(),
          title: "Wasabi",
          description: "The Decentralized Leverage Protocol",
          images: [
            {
              url: getOgImage(),
              width: 1200,
              height: 630,
              alt: "Wasabi"
            }
          ]
        }}
        twitter={{
          handle: "wasabi_protocol",
          site: "wasabi_protocol",
          cardType: "summary_large_image",
        }}
        additionalLinkTags={[
          {
            rel: 'apple-touch-icon',
            href: 'https://app.wasabi.xyz/static/apple-touch-icon.png',
            sizes: '180x180'
          },
          {
            rel: 'manifest',
            href: '/static/manifest.json'
          },
        ]}
      />
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider id="rbwk" theme={darkTheme()}>
            <BuildProvider>
            <UserSelectionProvider>
              <ThemeContextProvider>
                <UserBalanceContextProvider>
                  <EthPriceContextProvider>
                    <MobileMenuProvider>
                      { !isDesktop && hasMounted && <TouchHandler /> }
                      <TransactionProvider>
                        <PendingTransactionBox />
                        <AlertContextProvider>
                          <LongContextProvider>
                            <OptionActionContextProvider>
                              <CustomHeaderContentContextProvider>
                                <ShoppingCartContextProvider>
                                  <ProSidebarProvider>
                                    <Alert />
                                    <NextNProgress showOnShallow={false} />
                                    <div id="app-container" className={`flex flex-col bg-main-bg`}>
                                      { !isDesktop && hasMounted && <MobileNavigation /> }
                                      <div id="content-container" className="flex-grow lg:overflow-auto lg:no-scrollbar flex flex-row h-full lg:h-screen pb-safe">
                                        <div className="w-full lg:overflow-auto no-scrollbar">
                                          {getLayout(<Component {...pageProps} />)}
                                        </div>
                                        {
                                          !PERPS_VIEW && hasMounted &&
                                          <div className="h-full">
                                            <ShoppingCart />
                                          </div>
                                        }
                                      </div>
                                      {isDesktop && hasMounted && <Footer />}
                                    </div>
                                  </ProSidebarProvider>
                                  { hasMounted && <AddToHomeScreen /> }
                                </ShoppingCartContextProvider>
                              </CustomHeaderContentContextProvider>
                            </OptionActionContextProvider>
                          </LongContextProvider>
                        </AlertContextProvider>
                      </TransactionProvider>
                    </MobileMenuProvider>
                  </EthPriceContextProvider>
                </UserBalanceContextProvider>
              </ThemeContextProvider>
            </UserSelectionProvider>
            </BuildProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </>
  );
}

export default MyApp;
