import React, {FC, createContext, useState} from "react";
import {CHAIN_ID, DEMO_ETH, isMainnet} from "@/util/constants";
import {BigNumber} from "ethers";
import {useAccountBalance} from "@/hooks/useAccountBalance";
import {WETH_ADDRESS} from "@/util/chainConstants";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

interface UserBalanceContextInterface {
  refreshBalance?: () => void;
  balance?: BigNumber;
}

export const UserBalanceContext = createContext<UserBalanceContextInterface>({});

export const UserBalanceContextProvider: FC<Props> = ({ children }) => {
  const accountBalance = useAccountBalance(undefined);

  return (
    <UserBalanceContext.Provider value={{refreshBalance: accountBalance.refreshBalance, balance: accountBalance.balance}}>
      {children}
    </UserBalanceContext.Provider>
  );
};
