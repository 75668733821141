import {useBalance} from "wagmi";
import {toBN} from "@/util/converters";
import {BigNumber} from "ethers";
import {useEffect, useState} from "react";
import {AddressZero} from "@ethersproject/constants";
import {useHasMounted} from "@/hooks/useHasMounted";
import {useReadErc20BalanceOf} from "@/util/generated";
import {Address, zeroAddress} from "viem";

export const useAddressBalance = (address?: string, tokenAddress?: string | null) => {
  const [balance, setBalance] = useState(BigNumber.from(0));
  const hasMounted = useHasMounted();

  const isETH = tokenAddress === undefined || tokenAddress === null || tokenAddress === AddressZero || tokenAddress === '';

  const ethRead = useBalance({
    address: address as Address || zeroAddress,
    unit: "wei",
    query: { enabled: address !== undefined && isETH && hasMounted, gcTime: 60 * 1000 }
  });

  const tokenRead = useReadErc20BalanceOf({
    address: tokenAddress as Address || zeroAddress,
    args: [address as Address || zeroAddress],
    query: { enabled: !isETH && address !== undefined && tokenAddress !== undefined && hasMounted, gcTime: 60 * 1000 }
  });

  useEffect(() => {
    if (!hasMounted) return;

    if (isETH && ethRead.data) {
      setBalance(toBN(ethRead.data.value));
    } else if (tokenRead.data) {
      setBalance(toBN(tokenRead.data));
    }
  }, [hasMounted, isETH, tokenRead.data, ethRead.data]);

  return {
    balance,
    isLoading: tokenRead.isLoading || ethRead.isLoading,
    refreshBalance: isETH ? ethRead.refetch : tokenRead.refetch
  }
}
