import {TimeRemaining} from "@/components/TimeRemaining";

export const LongInfoImage3 = ({expiry}: {expiry: number}) => {
  return (
    <div className="flex gap-4 items-end justify-evenly border-b border-b-neutral-content px-24 overflow-hidden !pb-0">
      <div className="">
        <div className="h-[20px] flex items-center justify-end text-neutral-content text-xs">Already paid ⎯</div>
        <div className="h-[150px] flex gap-2 items-center justify-end text-lg text-neutral-content">
          Pay Within <TimeRemaining epochSeconds={expiry} short={true} /> ⎯
        </div>
      </div>
      <div className="">
        <div className="diagonalGrayLines h-[20px] w-[75px] rounded-t-md border border-dashed border-neutral-content/50"></div>
        <div className="h-[150px] w-[75px] bg-neutral-content"></div>
      </div>
    </div>
  );
}