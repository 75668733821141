import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

const ModuleLoading = () => <></>;
const AddToIosSafari = dynamic(() => import('./AddToIosSafari'), { loading: () => <ModuleLoading /> });
const AddToMobileChrome = dynamic(() => import('./AddToMobileChrome'), { loading: () => <ModuleLoading /> });
// const AddToMobileFirefox = dynamic(() => import('./AddToMobileFirefox'), { loading: () => <ModuleLoading /> });
// const AddToMobileFirefoxIos = dynamic(() => import('./AddToMobileFirefoxIos'), { loading: () => <ModuleLoading /> });
// const AddToMobileChromeIos = dynamic(() => import('./AddToMobileChromeIos'), { loading: () => <ModuleLoading /> });
// const AddToSamsung = dynamic(() => import('./AddToSamsung'), { loading: () => <ModuleLoading /> });
// const AddToOtherBrowser = dynamic(() => import('./AddToOtherBrowser'), { loading: () => <ModuleLoading /> });

import useUserAgent from '@/hooks/useUserAgent';
import {useUserSelection} from "@/contexts/UserSelectionContext";

// type AddToHomeScreenPromptType = 'safari' | 'chrome' | 'firefox' | 'other' | 'firefoxIos' | 'chromeIos' | 'samsung' | '';
type AddToHomeScreenPromptType = 'safari' | 'chrome' | '';

export default function AddToHomeScreen() {
  const [displayPrompt, setDisplayPrompt] = useState<AddToHomeScreenPromptType>('');
  const { userAgent, isMobile, isStandalone, isIOS } = useUserAgent();

  const {userSelections, handleSelection} = useUserSelection();
  const {hideAddToHomeScreenUntil} = userSelections;
  const showAddToHomeScreen = () => (hideAddToHomeScreenUntil || 0) <= new Date().getTime()

  const closePrompt = () => {
    setDisplayPrompt('');
  };

  const doNotShowAgain = () => {
    const expirationDate = new Date().getTime() + 5 * 86400000; // 5 days
    handleSelection('hideAddToHomeScreenUntil', expirationDate);
    setDisplayPrompt('');
  };

  useEffect(() => {
    if (showAddToHomeScreen()) {
      if (isMobile && !isStandalone) {
        if (userAgent === 'Safari') {
          setDisplayPrompt('safari');
        // }
        // else if (userAgent === 'Chrome') {
        //   setDisplayPrompt('chrome');
        // } else if (userAgent === 'Firefox') {
        //   setDisplayPrompt('firefox');
        // } else if (userAgent === 'FirefoxiOS') {
        //   setDisplayPrompt('firefoxIos');
        // } else if (userAgent === 'ChromeiOS') {
        //   setDisplayPrompt('chromeIos');
        // } else if (userAgent === 'SamsungBrowser') {
        //   setDisplayPrompt('samsung');
        } else {
         // setDisplayPrompt('other');
          setDisplayPrompt('chrome');
        }
      }
    } else {
    }
  }, [userAgent, isMobile, isStandalone, isIOS]);

  const Prompt = () => (
    <>
      {
        {
          'safari': <AddToIosSafari closePrompt={closePrompt} doNotShowAgain={doNotShowAgain} />,
          'chrome': <AddToMobileChrome closePrompt={closePrompt} doNotShowAgain={doNotShowAgain} />,
          // 'firefox': <AddToMobileFirefox closePrompt={closePrompt} doNotShowAgain={doNotShowAgain} />,
          // 'firefoxIos': <AddToMobileFirefoxIos closePrompt={closePrompt} doNotShowAgain={doNotShowAgain} />,
          // 'chromeIos': <AddToMobileChromeIos closePrompt={closePrompt} doNotShowAgain={doNotShowAgain} />,
          // 'samsung': <AddToSamsung closePrompt={closePrompt} doNotShowAgain={doNotShowAgain} />,
          // 'other': <AddToOtherBrowser closePrompt={closePrompt} doNotShowAgain={doNotShowAgain} />,
          '': <></>
        }[displayPrompt]
      }
    </>
  )

  return (
    <>
      {
        displayPrompt !== ''
          ?
          <>
            <div
              className="fixed top-0 left-0 right-0 bottom-0 bg-black/70 z-50"
              onClick={closePrompt}
            >
              <Prompt />
            </div>
          </>
          :
          <></>
      }
    </>
  );
}
