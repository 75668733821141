import React, {createContext, FC, useEffect, useState} from "react";
import {BigNumber} from "ethers";
import {Collection, OptionType, PoolAsk, WasabiAskWithOption} from "@/types/types";
import {toBN, weiToEth} from "@/util/converters";
import moment from "moment";
import {fetchRetry} from "@/api/util";
import {fetchAsk, fetchOptionData} from "@/api/datafetcher";
import {useAlert} from "@/contexts/AlertContext";

export function getCartId(id: number, poolAddress: string, seller?: string): string {
  return `${id}_${poolAddress}_${seller || 'pool'}'}`
}

function encode(item: ShoppingCartItem): any {
  return {
    ...item,
    tokenId: item.tokenId?.toString(),
    optionData: item.optionRequest && {
      ...item.optionRequest,
      strikePrice: item.optionRequest.strikePrice.toString(),
      premium: item.optionRequest.premium.toString(),
      tokenId: item.optionRequest.tokenId.toString(),
    },
    ask: item.ask && {
      ask: {
        ...item.ask.ask,
        id: item.ask.ask.id.toString(),
        price: item.ask.ask.price.toString(),
        orderExpiry: item.ask.ask.orderExpiry.toString(),
        optionId: item.ask.ask.optionId.toString(),
      },
      option: {
        ...item.ask.option,
        tokenId: item.ask.option.tokenId?.toString(),
        premium: item.ask.option.premium.toString(),
        strikePrice: item.ask.option.strikePrice.toString(),
      }
    }
  }
}

function parse(item: any): ShoppingCartItem | undefined {
  try {
    const optionData = item.optionData;
    return {
      ...item,
      tokenId: item.tokenId ? toBN(item.tokenId) : undefined,
      optionRequest: item.optionData && {
        ...optionData,
        strikePrice: toBN(optionData.strikePrice),
        premium: toBN(optionData.premium),
        tokenId: toBN(optionData.tokenId),
      },
      ask: item.ask && {
        ask: {
          ...item.ask.ask,
          id: toBN(item.ask.ask.id),
          price: toBN(item.ask.ask.price),
          orderExpiry: toBN(item.ask.ask.orderExpiry),
          optionId: toBN(item.ask.ask.optionId),
        },
        option: {
          ...item.ask.option,
          tokenId: item.ask.option.tokenId && toBN(item.ask.option.tokenId),
          premium: toBN(item.ask.option.premium),
          strikePrice: toBN(item.ask.option.strikePrice),
        }
      }
    }
  } catch (e) {
    console.error("Failed to parse ShoppingCartItem", e);
    return undefined;
  }
}

function save(items: ShoppingCartItem[], lastUpdated: number) {
  console.debug("Saving Cart", new Date(lastUpdated * 1000));
  localStorage.setItem("items", JSON.stringify(items.map(encode)));
  localStorage.setItem("lastUpdated", lastUpdated.toString());
}


function readItems(): ShoppingCartItem[] {
  if (typeof localStorage == 'undefined' || !localStorage) {
    return [];
  }
  const items = localStorage.getItem("items");
  return items
    ? (JSON.parse(items) as any[]).map(parse).filter(i => i !== undefined).map(i => i!)
    : [];
}

function readLastUpdated(): number {
  if (typeof localStorage == 'undefined' || !localStorage) {
    return moment.now();
  }
  const lastUpdated = localStorage.getItem("lastUpdated");
  return lastUpdated ? Number.parseInt(lastUpdated) : moment.now();
}

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

interface ShoppingCartContextInterface {
  lastUpdated: number;
  items: ShoppingCartItem[];
  updateItems: (items: ShoppingCartItem[]) => any;
  addItem: (item: ShoppingCartItem) => any;
  total: number;
  refresh: () => any;
  time?: number | undefined;
}

export interface ShoppingCartItem {
  id: string;
  collection: Collection;
  optionRequest?: PoolAsk;
  ask?: WasabiAskWithOption;
  signature: string;
  tokenId?: BigNumber;
  quantity: number;
  unavailable?: boolean;
  tokenAddress?: string | null;
}

export const ShoppingCartContext = createContext<ShoppingCartContextInterface>({
  lastUpdated: 0,
  items: [],
  updateItems: () => {},
  addItem: () => {},
  total: 0,
  refresh: () => {},
});

export const MAX_ITEMS = 10;

export const ShoppingCartContextProvider: FC<Props> = ({ children }) => {
  // localStorage.
  const [items, setItems] = useState<ShoppingCartItem[]>(readItems());
  const [lastUpdated, setLastUpdated] = useState(readLastUpdated());
  const {showError} = useAlert();

  const [time, setTime] = useState<number>();

  const updateItems = (items: ShoppingCartItem[]) => {
    console.debug("Writing");
    const lastUpdated = moment.now();
    setItems(items);
    setLastUpdated(lastUpdated);
    save(items, lastUpdated);
  }

  useEffect(() => {
    console.debug("Reading");
    setItems(readItems());
    setLastUpdated(readLastUpdated());
  }, []);

  const refreshItems = (forceRefresh?: boolean) => {
    for (let item of items) {
      if (item.optionRequest) {
        console.debug("Refreshing", item.id);
        const poolAsk = item.optionRequest;
        fetchRetry(async () => await fetchOptionData(
          item.collection.contractAddress,
          poolAsk.optionType,
          poolAsk.expiry,
          poolAsk.strikePrice,
          poolAsk.optionType === OptionType.CALL ? poolAsk.tokenId : undefined,
          poolAsk.poolAddress,
          undefined,
          item.unavailable ? undefined : toBN(poolAsk.id),
          true))
        .then(updatedRequest => {
            console.debug("Refreshed for item", item.id, "block", updatedRequest.option?.expiry);
            setItems(items => {
              if (items.length === 0) {
                return [];
              }
              const newItems = items.map(oldItem => {
                if (oldItem.id !== item.id) {
                  return oldItem;
                }
                if (updatedRequest.option && updatedRequest.signature) {
                  return {
                    ...item,
                    unavailable: false,
                    id: getCartId(updatedRequest.option.id, updatedRequest.option.poolAddress, undefined),
                    optionRequest: updatedRequest.option,
                    signature: updatedRequest.signature};
                }
                return { ...item, unavailable: true };
              });
              const updateTimestamp = moment.now();
              if (updateTimestamp > lastUpdated) {
                setLastUpdated(updateTimestamp);
                save(newItems, updateTimestamp);
              }
              return newItems;
            });
        })
        .catch(console.error);
      } else if (!item.unavailable && item.ask && (forceRefresh || item.ask.ask.orderExpiry.toNumber() > new Date().getUTCSeconds())) {
        fetchRetry(async () => await fetchAsk(item.ask?.ask.optionId.toString()))
          .then(newAsk => {
            if (newAsk.data?.id.toString() === item.ask?.ask.id &&
              newAsk.data?.seller === item.ask?.ask.seller)
            {
              return;
            }
            setItems(items => {
              if (items.length === 0) {
                return [];
              }
              const newItems = items.map(oldItem => {
                if (!oldItem.ask || oldItem.ask.ask.optionId.toString() !== item.ask?.ask.optionId.toString()) {
                  return oldItem;
                }
                if (newAsk.data === null) {
                  return { ...oldItem, unavailable: true };
                }
                return { ...item, ask: { ...item.ask, ask: newAsk.data } };
              });
              const lastUpdated = moment.now();
              setLastUpdated(lastUpdated);
              save(newItems, lastUpdated);
              return newItems;
            })
          })
          .catch(console.error);
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 20_000);
    refreshItems(true);
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const total = items.reduce((a, b) => a + b.quantity, 0);
  return (
    <ShoppingCartContext.Provider
      value={{
        lastUpdated,
        items,
        updateItems,
        refresh: () => refreshItems(true),
        addItem: (item) => {
          if (total >= MAX_ITEMS) {
            showError(`Cannot add more than ${MAX_ITEMS} items to your cart.`);
            return;
          }
          // @ts-ignore
          window.dataLayer.push({
            'event': 'addToCart',
            'strikePrice' : weiToEth(item.ask?.option.strikePrice || item.optionRequest?.strikePrice || 0),
            'expiration': item.ask?.option.expiration || item.optionRequest?.expiry || 0,
            'optionType': OptionType[item.ask?.option.optionType || item.optionRequest?.optionType || OptionType.CALL],
            'contractAddress': item.collection.contractAddress,
            'tokenId': item.tokenId?.toString(),
          });
          updateItems([...items, item]);
        },
        total,
        time
    }}>
      {children}
    </ShoppingCartContext.Provider>
  );
};
