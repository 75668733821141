import {useQuery} from "@tanstack/react-query";
import {fetchAsk} from "@/api/datafetcher";
import {OptionType, WasabiOptionWithMetadataAndValue} from "@/types/types";
import {ERC721Token} from "@/components/ERC721/ERC721Token";
import {EthDisplay} from "@/components/EthDisplay";
import {WasabiPoolAbi} from "@/contract/WasabiPoolAbi";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {Button} from "@/components/Button";
import {toBN} from "@/util/converters";
import {Ask} from "@/types/exchange_types";

export const AcceptAskAsPoolOwner = ({option}: {option: WasabiOptionWithMetadataAndValue}) => {
  const exisingAskQuery = useQuery({
    queryKey: ["optionAsk", option.id],
    queryFn: async () => await fetchAsk(option.id),
  });

  if (exisingAskQuery.isLoading) {
    return (
      <div className="min-w-[150px] min-h-[40px] rounded-md bg-slate-600 animate-pulse"/>
    );
  }
  if (!exisingAskQuery.data?.data) {
    return <Button className="min-w-[150px]" disabled={true}>Buy to close</Button>
  }

  const { signature, ...askObject } = exisingAskQuery.data.data!;
  const ask: Ask = askObject;


  const transactionChain: ChainItem[] = [
    {
      name: "Close position",
      description: (
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2">
              {
                option.optionType === OptionType.CALL &&
                <ERC721Token className="h-full max-h-[50px] border glassborder rounded-md"
                             collectionAddress={option.collectionAddress}
                             tokenId={option.tokenId!} />
              }
              <span>1x {option.optionType === OptionType.CALL ? "CALL" : "PUT"} Option</span>
            </div>
          </div>
          <EthDisplay
            value={ask.price}
            size={3}
            removeTrailingZeroes={true}
            numDigits={4}
          />
        </div>
      ),
      button: {
        id: "pool_option_buyback",
        loadingText: "Closing option...",
        addressOrName: option.poolAddress,
        contractInterface: WasabiPoolAbi,
        functionName: "acceptAsk",
        args: [ask, signature],
      }
    }
  ];
  transactionChain.push();
  return (
    <TransactionChain
      id="pool_option_buyback"
      enabled={true}
      title={<EthDisplay label="Buy to close" value={ask?.price || toBN(0)} size={4} />}
      transactionChain={transactionChain}
      className="min-w-[150px]"
      onSuccess={() => window.location.reload()}
    />
  );
}
