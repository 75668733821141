import React, {useContext, useEffect, useState} from "react";
import {MAX_ITEMS, ShoppingCartContext, ShoppingCartItem} from "@/contexts/ShoppingCartContext";
import {Sidebar, sidebarClasses, useProSidebar} from "react-pro-sidebar";
import {IoClose} from "react-icons/io5";
import {SingleNft} from "@/components/SingleNft";
import {BigNumber, ethers} from "ethers";
import {EthDisplay} from "@/components/EthDisplay";
import {OptionData, OptionType} from "@/types/types";
import {formatEther, toBN, weiToEth} from "@/util/converters";
import {BiTrashAlt} from "react-icons/bi";
import moment from "moment/moment";
import {WASABI_CONDUIT} from "@/util/constants";
import {WasabiConduitAbi} from "@/contract/WasabiConduitAbi";;
import {useRouter} from "next/router";
import {useAlert} from "@/contexts/AlertContext";
import classNames from "classnames";
import {
  TransactionButton,
  TransactionButtonProps,
  useTransactionError
} from "@/components/Transactions/TransactionButton";
import {ErrorPanel} from "@/components/ErrorPanel";
import {useAccountBalance} from "@/hooks/useAccountBalance";
import {zeroAddress} from "viem";
import {useFeeManager} from "@/util/useFeeManager";

const MAX_QUANTITY = 8;

export const ShoppingCart = () => {
  const router = useRouter();
  const alert = useAlert();
  const { collapseSidebar } = useProSidebar();
  const {items, updateItems, lastUpdated, total, refresh, time } = useContext(ShoppingCartContext);
  const txnError = useTransactionError();
  const [txnLoading, setTxnLoading] = useState(false);
  const {getValueWithFee} = useFeeManager();

  const getPrice = (item: ShoppingCartItem): BigNumber => {
    if (item.optionRequest) {
      return getValueWithFee(item.optionRequest.premium);
    }
    return item.ask?.ask.price || toBN(0);
  }

  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (txnError.error && !txnError.error.includes("Cannot buy that many options")) {
      refresh();
    }
  }, [txnError.error]);
  useEffect(() => {
    txnError.setError(undefined);
  }, [items.length]);

  const removeItem = (id: string) => {
    // @ts-ignore
    window.dataLayer.push({
      'event': 'removeFromCart',
    });
    updateItems(items.filter(item => item.id !== id));
  }

  const renderItem = (item: ShoppingCartItem, i: number): any => {
    const updateQuantity = (val: number) => {
      const newQuantity = item.quantity + val;
      const newTotal = total + val;

      if (newQuantity > MAX_QUANTITY) {
        alert.showError("Too many items in the cart, you can only have at most 5 items at a time");
        return;
      } else if (newTotal > MAX_ITEMS) {
        alert.showError("Too many items in the cart, you can only buy 10 options at a time!");
        return;
      } else if (newQuantity === 0) {
        removeItem(item.id);
        return;
      }
      updateItems(items.map(i => {
        if (i.id !== item.id) {
          return i;
        }
        return { ...i, quantity: newQuantity };
      }))
    }

    let id = '';
    const tokenId = (item.optionRequest?.optionType === OptionType.CALL && item.tokenId) || item.ask?.option.tokenId;
    if (tokenId) {
      id = `#${tokenId.toString()}`;
    }
    const expiration = moment((
      item.optionRequest
        ? item.optionRequest.expiry * 1000
        : (item.ask ? item.ask.option.expiration * 1000 : 0)));

    const strike = item.optionRequest?.strikePrice || item.ask?.option.strikePrice!
    const optionType = item.optionRequest ? item.optionRequest.optionType : item.ask?.option.optionType!;
    const price = getPrice(item);
    return (
      <div key={`shopping_item_${i}`}
           className="rounded-md select-none flex flex-col">
        <div className="group flex flex-row gap-2 items-center justify-between hover:bg-glass-focus hover:bg-opacity-30 m-2 p-2 mb-1 rounded-sm">
          <div className="flex flex-row gap-2 items-center">
            <SingleNft size={16}
                       className="rounded-sm"
                       hideFooter={true}
                       collectionAddress={item.collection.contractAddress}
                       tokenId={tokenId || BigNumber.from(1)} />
            <div>
              <div>
                {`${formatEther(strike)} ${optionType === OptionType.CALL ? "Call" : "Put"} ${expiration.calendar({nextWeek: 'MM/DD/YYYY'})}`}
              </div>
              <span className={classNames("text-neutral-content text-xs", { 'text-error': item.unavailable })}>
                  {item.unavailable ? "Unavailable, please remove" : `${item.collection.name} ${id}`}
              </span>
            </div>
          </div>
          <div>
            {
              item.unavailable
                ? <span className="group-hover:hidden">N/A</span>
                : <EthDisplay className="group-hover:hidden"
                              value={price}
                              size={4}
                              numDigits={3}
                              removeTrailingZeroes={true} />
            }
            <button className="hidden group-hover:block hover:scale-110">
              <BiTrashAlt color="white" size={24} onClick={(e) => {
                e.preventDefault();
                removeItem(item.id);
              }} />
            </button>
          </div>
        </div>
        {/*<div className="text-sm text-neutral-content flex flex-row gap-2 mx-4 items-center pb-1">*/}
        {/*  Quantity:*/}
        {/*  <button onClick={() => updateQuantity(-1)} className="w-6 h-6 bg-glass hover:bg-glass-focus rounded-md">-</button>*/}
        {/*  <span>{item.quantity}</span>*/}
        {/*  {*/}
        {/*    optionType !== OptionType.CALL && !item.ask &&*/}
        {/*    <button onClick={() => updateQuantity(1)}*/}
        {/*            className="w-6 h-6 bg-glass hover:bg-glass-focus rounded-md"*/}
        {/*            disabled={item.quantity >= MAX_QUANTITY}>+</button>*/}
        {/*  }*/}
        {/*</div>*/}
      </div>
    )
  }

  const accountBalance = useAccountBalance(items.length > 0 ? items[0].tokenAddress : undefined);
  const totalPrice = items.reduce((a, b) => {
    let price = getPrice(b);
    return a.add(price.mul(b.quantity));
  }, BigNumber.from(0));

  const multiBuyTxnButtonProps: TransactionButtonProps = {
    id: "buy_options_from_cart",
    addressOrName: WASABI_CONDUIT,
    contractInterface: WasabiConduitAbi,
    functionName: "buyOptions",
    args: [
      items.filter(i => i.optionRequest).flatMap(i => Array(i.quantity).fill(i.optionRequest)),
      items.filter(i => i.ask).flatMap(i => Array(i.quantity).fill(i.ask!.ask)),
      [
        ...items.filter(i => i.optionRequest).flatMap(i => Array(i.quantity).fill(i.signature)),
        ...items.filter(i => i.ask).flatMap(i => Array(i.quantity).fill(i.signature))
      ]
    ],
    overrides: {
      value: items.filter(i => !(i.tokenAddress && i.tokenAddress !== zeroAddress)).map(getPrice).reduce((a, b) => a.add(b), BigNumber.from(0))
    },
    enabled: items.length > 0
      && !accountBalance.isLoading
      && accountBalance.balance.gte(totalPrice)
      && items.every(item =>
        (item.ask || (item.optionRequest && item.optionRequest.expiry > (moment.now() / 1000))) &&
        !item.unavailable),
    onTransactionSuccess: () => {
      updateItems([]);
      router.push('/portfolio?view=options');
    },
    ...txnError,
    setLoading: setTxnLoading
  }

  return (
    <Sidebar rtl={false}
             defaultCollapsed={true}
             width="400px"
             className="h-full"
             collapsedWidth={"0"}
             style={{
               borderRightWidth: 0,
               borderRightStyle: "none",
             }}
             rootStyles={{
               [`.${sidebarClasses.container}`]: {
                 backgroundColor: 'rgba(52,64,84,0.5)',
               },
             }}
    >
      {
        hasMounted &&
        <div className="h-full flex flex-col justify-between items-center flex-initial shadow-lg">
          <div className="w-full flex flex-row justify-between items-center px-8 py-8 text-white border-b border-neutral-content flex-grow-0">
            <span className="text-2xl text-bold">Your Cart</span>
            <IoClose className="hover:cursor-pointer hover:scale-125"
                     color="white"
                     size={'36px'}
                     onClick={() => collapseSidebar(true)} />
          </div>
          {
            items.length > 0 &&
            <>
              <div className="w-full flex flex-row justify-between items-center p-4">
                <span>{total} Item{items.length > 1 && 's'}</span>
                <button className="hover:scale-110" onClick={() => updateItems([])}>Clear all</button>
              </div>
            </>
          }
          <div className="w-full flex flex-col overflow-y-auto no-scrollbar flex-1 flex-auto">
            {
              items.length === 0 ?
                <div className="text-neutral-content text-lg text-center px-4 py-16">
                  Add items to get started
                </div> : items.map(renderItem)
            }
          </div>
          <div className="w-full flex flex-col gap-4 py-4 px-4 bg-glass flex-grow-0 box-shadow-[0_-35px_-35px_rgba(0,0,0,0.25)]">
            {
              items.length > 0 &&
              <div className="flex flex-row items-center gap-2 w-full">
              <span
                className="flex flex-row items-center gap-2 select-none text-sm text-neutral-content">
                <div className="pulse-green-animate bg-call rounded-full overflow-hide w-2 h-2"></div>Last updated:
                {/*<BiHelpCircle id="live_prices_tooltip" className="w-4 h-4" data-tooltip-content={"The option will automatically be updated."} />*/}
              </span>
                <span className="select-none text-sm">
              {moment(lastUpdated).fromNow()}
            </span>
              </div>
            }
            <div className="flex flex-row justify-between">
              <span className="font-bold">Total Price</span>
              <EthDisplay value={totalPrice} size={4} numDigits={3} removeTrailingZeroes={true} />
            </div>

            <TransactionButton {...multiBuyTxnButtonProps}
                               className="py-4"
                               disabled={items.length === 0 || items.some(item => item.unavailable)}>
              Complete Purchase
            </TransactionButton>
            {
              !accountBalance.isLoading
              && accountBalance.balance.lt(totalPrice)
              && items.every(i => !i.unavailable)
              && <ErrorPanel message="Insufficient Balance" />
            }
            { txnError.error && <ErrorPanel message={txnError.error} /> }
          </div>
        </div>
      }
    </Sidebar>
  )
}
