
import {
  useAccount,
  useSimulateContract, useEstimateGas, useWaitForTransactionReceipt,
  useSendTransaction, useWriteContract,
} from "wagmi";
import {Button, ButtonProps} from "@/components/Button";
import {useAlert} from "@/contexts/AlertContext";
import React, {useEffect, useState} from "react";
import {EthersError, getParsedEthersError} from "@enzoferey/ethers-error-parser";
import {getErrorMessage} from "@/util/errorHandling";
import {tryParseError} from "@/util/errorCodes";
import {ConnectWallet} from "@/components/ConnectWallet";
import {BigNumberish} from "ethers";
import {Address, encodeFunctionData, Hex, TransactionReceipt} from "viem";
import {castBigNumberToBigInt} from "@/util/converters";
import {CHAIN_ID, isBlast} from "@/util/constants";

export type Overrides = {
  value?: BigNumberish;
}

export type ContractWriteProps = {
  addressOrName: string;
  contractInterface: readonly any[];
  functionName: string;
  args?: any | any[];
  overrides?: Overrides;
}

function isContractWriteProps(props: TransactionProps): props is ContractWriteProps {
  return (props as ContractWriteProps).contractInterface !== undefined;
}

export type SendTransactionProps = {
  to: Address,
  data: Hex,
  value?: bigint,
}

export type TransactionProps = ContractWriteProps | SendTransactionProps;

export type TransactionButtonProps = ButtonProps & TransactionProps & {
  enabled?: boolean;
  loadingText?: string;
  successText?: string;
  onTransactionSuccess?: (receipt: TransactionReceipt) => any;
  alertOnError?: boolean;
  error?: string;
  setError?: (e?: string) => any;
  refreshToken?: any | undefined;
  setLoading?: (loading: boolean) => any;
  autoStart?: boolean;
};
// && ButtonProps && ContractWriteProps && SendTransactionProps;

export interface TransactionError {
  error: string | undefined;
  setError: (error: string | undefined) => any;
}

export const useTransactionError = (): TransactionError => {
  const [error, setError] = useState<string>();
  return {error, setError};
}

export const useTransactionLoading = () => {
  const [loading, setLoading] = useState(false);
  return {loading, setLoading};
}

export const TransactionButton: React.FC<TransactionButtonProps> = ({children, ...props}) => {
  const buffer = CHAIN_ID === 1 ? 0n : 5n; // 5%
  const alert = useAlert();
  const {address, chain} = useAccount();
  const [shouldAutostart, setShouldAutostart] = useState(props.autoStart);

  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);


  const handleError = (error: Error) => {
    console.error(error);
    let errorMessage: string;
    const txnError = tryParseError(error);
    if (txnError) {
      errorMessage = txnError.message;
      // @ts-ignore
      window.dataLayer.push({
        'event': 'transactionError',
        'id': props.id,
        'errorCode': txnError.name
      });
    } else if ('shortMessage' in error || 'details' in error) {
      // @ts-ignore
      errorMessage = error.shortMessage || error.details;
      // @ts-ignore
      window.dataLayer.push({
        'event': 'transactionError',
        'id': props.id,
        // @ts-ignore
        'errorCode': errorMessage.name,
      });
    } else {
      const parsedError = getParsedEthersError(error as EthersError);
      errorMessage = getErrorMessage(parsedError);
      // @ts-ignore
      window.dataLayer.push({
        'event': 'transactionError',
        'id': props.id,
        'errorCode': parsedError.errorCode,
      });
    }
    props.alertOnError && alert.showError(errorMessage);
    props.setError?.(errorMessage);
  }

  let hash: Hex | undefined,
    isIdle: boolean,
    writeIsLoading: boolean,
    reset: Function,
    refetch: Function,
    prepareIsSuccess: boolean,
    write: Function | undefined,
    error: any | undefined,
    simulateError: any | undefined,
    writeIsError: boolean;

  const isWrite = isContractWriteProps(props);
  const gasEstimate = useEstimateGas({
    to: (isWrite ? props.addressOrName : props.to) as Address,
    data: isWrite ? encodeFunctionData({
      abi: props.contractInterface,
      functionName: props.functionName,
      args: props.args ? props.args.map(castBigNumberToBigInt) : [],
    }) : props.data,
    value: isWrite
      ? (props.overrides?.value ? BigInt(props.overrides.value.toString()) : undefined)
      : (props.value || 0n),
  });

  if (isWrite) {
    const prepareContractWrite = useSimulateContract({
      address: props.addressOrName as Address,
      abi: props.contractInterface,
      functionName: props.functionName,
      args: props.args ? props.args.map(castBigNumberToBigInt) : undefined,
      value: props.overrides?.value ? BigInt(props.overrides.value.toString()) : undefined,
      // maxPriorityFeePerGas: isBlast ? (data?.maxPriorityFeePerGas || undefined) : undefined,
      // maxFeePerGas: isBlast ? (data?.maxFeePerGas || undefined) : undefined,
      query: {
        enabled: props.enabled,
      }
    });

    const writeContract = useWriteContract();

    const handleContractWrite = async () => {
      let gas = gasEstimate.data;
      if (!gas) {
        console.error('No gas estimate')
        return;
      }
      console.log('Estimated gas', gas);
      gas = gas * (100n + buffer) / 100n;
      console.log(`Estimated gas with ${buffer}% buffer`, gas);
      writeContract.writeContractAsync({
        ...prepareContractWrite.data!.request, gas
      })
        .then((result) => {
          // @ts-ignore
          window.dataLayer.push({
            'event': 'transactionSubmitted',
            'id': props.id,
            'txn_hash': result
          });
        })
        .catch((error) => {
          handleError(error);
        });
    };

    hash = writeContract.data;
    isIdle = writeContract.isIdle;
    writeIsLoading = writeContract.isPending;
    prepareIsSuccess = prepareContractWrite.isSuccess;
    reset = writeContract.reset;
    refetch = prepareContractWrite.refetch;
    writeIsError = writeContract.isError;
    write = handleContractWrite;
    error = writeContract.error;
    simulateError = prepareContractWrite.error;
  } else {
    const prepareSendTransaction = useEstimateGas({
      to: props.to,
      data: props.data,
      value: props.value,
    });

    const sendTransaction = useSendTransaction();

    const handleSendTransaction = () => {
      let gas = gasEstimate.data;
      if (!gas) {
        console.error('No gas estimate')
        return;
      }
      console.log('Estimated gas', gas);
      if (buffer > 0) {
        gas = gas * (100n + buffer) / 100n;
      }
      console.log(`Estimated gas with ${buffer.toString()}% buffer`, gas);
      sendTransaction.sendTransactionAsync({
        data: props.data,
        to: props.to,
        value: props.value,
        gas
      })
        .then((result) => {
          // @ts-ignore
          window.dataLayer.push({
            'event': 'transactionSubmitted',
            'id': props.id,
            'txn_hash': result
          });
        })
        .catch(handleError);
    };

    hash = sendTransaction.data;
    isIdle = sendTransaction.isIdle;
    writeIsLoading = sendTransaction.isPending;
    prepareIsSuccess = prepareSendTransaction.isSuccess;
    reset = sendTransaction.reset;
    refetch = prepareSendTransaction.refetch;
    writeIsError = sendTransaction.isError;
    write = handleSendTransaction;
    error = sendTransaction.error;
    simulateError = prepareSendTransaction.error;
  }

  useEffect(() => {
    if (simulateError) {
      handleError(simulateError);
    }
  }, [simulateError]);

  useEffect(() => {
    if (error) {
      reset();
      handleError(error);
    }
  }, [error]);

  const transaction = useWaitForTransactionReceipt({
    hash: hash,
    query: { enabled: !!hash },
  });

  useEffect(() => {
    if (transaction.isError) {
      reset();
      handleError(transaction.error);
    } else if (transaction.isSuccess && transaction.data) {
      if (transaction.data.status === "success") {
        // @ts-ignore
        if (window.dataLayer) {
          // @ts-ignore
          window.dataLayer.push({
            'event': 'transactionSuccessful',
            'id': props.id,
            'txn_hash': transaction.data.transactionHash
          });
        }
        reset();
        props.onTransactionSuccess?.(transaction.data);
      } else {
        handleError(new Error("Transaction failed"));
      }
    }
  }, [transaction]);

  useEffect(() => {
    if (props.refreshToken) {
      refetch();
    }
  }, [props.refreshToken]);

  useEffect(() => {
    if (shouldAutostart && prepareIsSuccess && gasEstimate.isSuccess) {
      setShouldAutostart(false);
      onClick();
    }
  }, [shouldAutostart, prepareIsSuccess, gasEstimate.isSuccess]);

  const isLoading = writeIsLoading || transaction.isLoading;

  useEffect(() => {
    if (props.setLoading) {
      props.setLoading(isLoading);
    }
  }, [isLoading]);

  const onClick = () => {
    if (isLoading) {
      return;
    }
    props.setError?.(undefined);
    if (writeIsError || transaction.isError) {
      reset();
      return;
    }
    write?.();
  }

  const buttonProps = {
    ...props,
    children: undefined,
  }

  if (!hasMounted || !address || chain?.id !== CHAIN_ID) {
    return <ConnectWallet />
  }

  return (
    <Button {...buttonProps}
            disabled={chain?.id !== CHAIN_ID || !address || transaction.isSuccess || props.disabled || writeIsLoading || transaction.isLoading || (props.enabled === false)}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onClick();
            }}
            loading={isLoading}>
      { writeIsLoading && "Waiting for Approval" }
      { transaction.isLoading && (props.loadingText || "Transaction Pending") }
      { isIdle && !hash && children }
      { transaction.isSuccess && (props.successText || "Success") }
    </Button>
  )
}
