export const FooterLogo = () => {
  return (
    <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg" className="hover:stroke-white stroke-neutral-content">
      <g opacity="0.5">
        <path d="M3.81558 16.3712C2.98555 15.8882 2.26864 15.2015 1.75552 14.3111C0.201087 11.6248 1.12168 8.18392 3.81553 6.62196C6.50183 5.06754 9.94275 5.98816 11.5047 8.68201C12.0179 9.57242 12.2593 10.5383 12.2593 11.489C10.5766 10.5156 8.44117 10.4477 6.63013 11.4891C4.8267 12.5379 3.82313 14.4319 3.81558 16.3712Z"/>
        <path d="M12.2598 1.7546C13.0898 1.27167 14.0481 1.00755 15.0667 1C18.1756 1 20.6959 3.5203 20.6884 6.62161C20.6884 9.73049 18.1681 12.2508 15.0667 12.2432C14.0405 12.2432 13.0822 11.9641 12.2598 11.4887C13.9425 10.5152 15.0668 8.69677 15.0744 6.62167C15.0668 4.54658 13.9425 2.72801 12.2598 1.7546Z" />
        <path d="M3.81445 6.62192C3.81445 5.67111 4.0635 4.69773 4.56907 3.81487C6.12351 1.12857 9.55684 0.200414 12.2582 1.75485C14.9445 3.30928 15.8726 6.74262 14.3182 9.444C13.8051 10.3344 13.0883 11.0211 12.2582 11.504C12.2582 9.55715 11.2471 7.67074 9.44364 6.63697C7.64021 5.58813 5.49717 5.66357 3.81445 6.62192Z" />
        <path d="M20.6938 6.62939C21.5238 7.11231 22.2406 7.79898 22.7538 8.68939C24.3082 11.3757 23.3876 14.8165 20.6937 16.3785C18.0074 17.933 14.5666 17.0124 13.0046 14.3186C12.4915 13.4282 12.25 12.4623 12.25 11.5115C13.9327 12.4849 16.0682 12.5529 17.8792 11.5115C19.6827 10.4627 20.6862 8.56869 20.6938 6.62939Z" />
        <path d="M12.2529 21.2455C11.4228 21.7284 10.4645 21.9925 9.44587 22.0001C6.33698 22.0001 3.81669 19.4797 3.82424 16.3784C3.82424 13.2695 6.34453 10.7493 9.44587 10.7569C10.4721 10.7569 11.4304 11.036 12.2529 11.5114C10.5701 12.4848 9.44581 14.3033 9.44581 16.386C9.44581 18.4535 10.5701 20.2721 12.2529 21.2455Z" />
        <path d="M20.6873 16.3709C20.6873 17.3216 20.4383 18.295 19.9327 19.1779C18.3783 21.8642 14.945 22.7924 12.2435 21.2379C9.55725 19.6835 8.62912 16.2502 10.1836 13.5488C10.6967 12.6584 11.4136 11.9717 12.2436 11.4888C12.2436 13.4356 13.2547 15.322 15.0581 16.3558C16.8691 17.4122 19.0121 17.3368 20.6873 16.3709Z" />
      </g>
    </svg>
  )
}