import ReactGA from "react-ga4";

export const googlePushDataLayer = (eventName: string, data: object) => {
  // @ts-ignore
  if (window.dataLayer) {
    // @ts-ignore
    window.dataLayer.push({
      'event': eventName,
      ...data,
    });
  }
}

export const ga4AnalyticEvent = (category: string, action: string, label?: string, args?: any) => {
    let eventParams = {
        category,
        action,
        label,
        ...args,
    };
    ReactGA.event(action, eventParams);
}

export const ga4AnalyticPages = (page: string, title: string, location?: string ) =>{
    ReactGA.send({ hitType: "pageview", page:page, title: title, location: location, });
};

