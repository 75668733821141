import {Button, ButtonType} from "@/components/Button";
import {useAccount, useSignMessage} from "wagmi";
import {saveUserSignature} from "@/api/datafetcher";
import {useAlert} from "@/contexts/AlertContext";
import {useState} from "react";
import {
  PRIVACY_POLICY_LINK,
  RESPONSIBLE_DISCLOSURE_LINK,
  TERMS_OF_USE_LINK
} from "@/util/constants";

const renderLink = (label: string, href: string) => {
  return <a href={href}
            target="_blank"
            className="text-blue-500 hover:text-blue-300 hover:underline font-normal">
    {label}
  </a>
}

export const MESSAGE =
  "By signing this statement, I, the owner of the provided wallet, certify under penalty of perjury and false swearing that I am not a citizen or resident in any of the Forbidden Jurisdictions provided in the General Terms and Conditions (https://wasabi.xyz/legal/terms-and-conditions), that upon request I will provide any required information about my identity, that the information I will provide when requested is correct and complete. I understand that I am only able to do this because I am not a citizen or resident in any of the Forbidden Jurisdictions, and I understand that Wasabi may contact other entities to confirm the accuracy of my statement. I take full and exclusive responsibility for my procedures on the platform, and I hereby indemnify Forsage of any of my non-compliant or illegal actions."

export const SignAgreement = ({onCancel, onSuccess}: {onCancel: Function, onSuccess: Function}) => {
  const {address} = useAccount();
  const alert = useAlert();
  const [isSaving, setIsSaving] = useState(false);

  const {signMessageAsync, isPending} = useSignMessage();
  const onSign = async () => {
    if (isPending || !address) {
      return;
    }

    try {
      const signature = await signMessageAsync({ message: MESSAGE });
      setIsSaving(true);

      const response = await saveUserSignature(address, signature);

      if (response.success) {
        onSuccess();
      } else {
        alert.showError(response.message || "Something went wrong");
      }
    } catch (error) {
      alert.showError("An error occurred during the signing process");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="standard-stack !gap-6 items-center p-4">
      <img src="/static/logo_nobackground.png" className="w-[150px] h-[150px]" alt="wasabi" />
      <h1 className="text-3xl text-white">Welcome to Wasabi</h1>
      <div className="font-light mb-4 text-center text-neutral-content">
        By connecting your wallet and using Wasabi, you agree to<br/> our&nbsp;{renderLink("Terms of Service", TERMS_OF_USE_LINK)}&nbsp;and&nbsp;{renderLink("Privacy Policy", PRIVACY_POLICY_LINK)}.
        <br/>
        <br/>
        You also acknowledge the inherent risks involved in trading NFT derivates <br/>or other assets indicated in our {renderLink("Responsible Disclosure Policy", RESPONSIBLE_DISCLOSURE_LINK)}.
      </div>
      <div className="w-full flex flex-row gap-2 justify-between">
        <Button buttonType={ButtonType.SECONDARY} className="min-w-[200px]" onClick={() => onCancel()}>
          CANCEL
        </Button>
        <Button buttonType={ButtonType.PRIMARY}
                loading={isPending || isSaving}
                disabled={isPending || isSaving}
                className="min-w-[200px]"
                onClick={onSign}>
          Accept and Sign
        </Button>
      </div>
    </div>
  )
}
