import {AiFillShop} from "react-icons/ai";
import {Tooltip as ReactTooltip} from "react-tooltip";
import React from "react";
import useId from "@/hooks/useId";

const other = { id: "OTHER", image: "" };
const marketplaces: { id: string, image: string }[] = [
  { id: "OPENSEA", image: "/static/opensea.svg" },
  { id: "SEAPORT", image: "/static/opensea.svg" },
  { id: "LOOKSRARE", image: "/static/looksrare.svg" },
  { id: "X2Y2", image: "/static/x2y2.svg" },
  { id: "BLUR", image: "/static/blur.png" },
  { id: "NFTX", image: "/static/nftx.svg" },
  { id: "CAVIAR", image: "/static/caviarsh.svg" },
  { id: "SUDOSWAP", image: "/static/sudoswap.svg" },
  { id: "CRYPTOPUNKS", image: "/static/cryptopunksapp.png" },
  { id: "WASABI", image: "/static/wasabi-icon-180px.png" },
  { id: "ARCADE", image: "/static/arcade.png" },
  { id: "NFTFI", image: "/static/nftfi.png" },
  { id: "ZHARTA", image: "/static/zharta.png" },
  { id: "TELLER", image: "/static/teller.png" },
  { id: "FLOORING", image: "/static/flooring.png" },
  { id: "RESERVOIR", image: "/static/reservoir.png" },
  other,
]

export const MarketplaceView = ({marketplace, size = 8}: {marketplace?: string, size?: number}) => {
  const selectedMarketplace = marketplaces.find(m => m.id.toLowerCase() === (marketplace || '').toLowerCase()) || other;
  const id = useId();
  return (
    <div className="flex flex-row gap-4 items-center justify-center">
      <div key={selectedMarketplace.id}
           id={id}
           className={`bg-white tooltip flex items-center justify-center rounded-full w-${size || 8} h-${size || 8} border border-glass-focus overflow-hidden`}>
        {
          selectedMarketplace.id === "OTHER"
            ? <AiFillShop className="mx-auto" />
            : <img src={selectedMarketplace.image} alt={selectedMarketplace.id}/>
        }
      </div>
      <ReactTooltip
        anchorId={id}
        id={`tooltip_${id}`}
        place="top"
        noArrow
        className="z-50"
        content={(marketplace || "OTHER").toUpperCase()}
      />
    </div>
  );
}