import moment from "moment";
import { TimePeriod } from "@/types/dkoda_types";
import {BigNumber} from "ethers";
import {toBN} from "@/util/converters";

export const formatGranularity = (
  timestamp: string,
  timePeriod: TimePeriod
) => {
  const granularity = {
    ONE_DAY: "HH:mm",
    ONE_WEEK: "MMM Do",
    ONE_MONTH: "MMM Do",
    THREE_MONTHS: "MMM Do",
    ONE_YEAR: "MMM",
    ALL: "MMM",
  };
  return moment(timestamp).format(
    granularity[timePeriod as keyof typeof granularity]
  );
};

export const shortenEthAddress = (address: string) => {
  return `${address.slice(0, 4)}...${address.slice(-4)}`;
};

export function getRandomInt(max: number): bigint {
  return BigInt(Math.floor(Math.random() * max));
}