const staticSectionData = {
  gains: {
    title: "Gains",
    description:
      "Access real-time on-chain transactions, average profitability and risk",
    color: "#6CE9A6",
    secondaryColor: "#ECFDF3",
  },
  hype: {
    title: "Hype",
    description:
      "Assess the hype around this collection by the volume, sentiment and density of all related social posts",
    color: "#7A5AF8",
    secondaryColor: "#F4F3FF",
  },
  community: {
    title: "Community",
    description:
      "See the quality of the creators, influencers and holders of this collection",
    color: "#7CD4FD",
    secondaryColor: "#F0F9FF",
  },
};

const staticScoreData = {
  social_volume: {
    label: "Social Volume",
    tooltip: "Number of tweets about this collection this week.",
  },
  sentiment: {
    label: "Sentiment",
    tooltip:
      "The average sentiment of each tweet this week. This number if between [-1] and [1]. [-1] would mean that everyone talked negatively about this collection and [1] would mean that everyone talked positively.",
  },
  discussion_density: {
    label: "Discussion Density",
    tooltip:
      "The density of this discussion. This can indicate authenticity of the conversion going in in Twitter. The lower this value is, the more likely that there are bots posting tweets about this collection.",
  },
  average_profit: {
    label: "Average Profit",
    tooltip: "The average profit of all sales.",
  },
  percent_profitable_transactions: {
    label: "Percentage of Profitable Transactions",
    tooltip: "This is the percentage of transactions that made a net profit.",
  },
  risk_rate: {
    label: "Risk Rate",
    tooltip:
      "The variance of assets per user. This value will be 0 if everyone holds the same amount of assets in this collection. It will increase when there are holders with a lot of assets.",
  },
};

/**
 * Merges the given dynamic section data with the static section data above.
 */
export const mergeSectionData = (data: Object) => {
  Object.keys(staticSectionData).forEach((key) => {
    // @ts-ignore
    data[key] = Object.assign({}, data[key], staticSectionData[key]);
    // @ts-ignore
    data[key].stats = data[key]?.stats?.map((stat) =>
      Object.assign(
        {},
        stat,
        staticScoreData[stat.id as keyof typeof staticScoreData]
      )
    );
  });
  return data;
};
