import React from "react";
import classNames from "classnames";
import {twMerge} from "tailwind-merge";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {BiHelpCircle} from "react-icons/bi";
import useId from "@/hooks/useId";

export const SplitLabelValue = ({label, children, labelClassName = '', isRow, tooltip, className = ''}: {className?: string, tooltip?: string | React.ReactNode | undefined, isRow?: boolean, label: any, children?: React.ReactNode | React.ReactNode[], labelClassName?: string}) => {
  const id = useId();
  return (
    <div className={twMerge(classNames("gap-8 text-neutral-content", {
      "flex flex-col justify-start !gap-2": isRow,
      "flex justify-between items-center": !isRow
    }), className || '')}>
      <div id={id}
           className={classNames(twMerge("cursor-default flex flex-row items-center gap-2 ", labelClassName || ''), {
             "cursor-help": tooltip,
           })}>
        {label}
        { tooltip && <>
          <BiHelpCircle size={16} />
          <ReactTooltip
            anchorSelect={`#${id}`}
            id={`tooltip_${id}`}
            className="z-50 max-w-[250px]"
            style={{ backgroundColor: "#3b485f", color: "#98a2b3" }}
          >{tooltip}</ReactTooltip>
        </> }
      </div>
      <div>{children}</div>
    </div>
  )
}