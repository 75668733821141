import {
  TransactionButton,
  TransactionButtonProps, TransactionError, useTransactionError
} from "@/components/Transactions/TransactionButton";
import {Modal, useModal} from "@/components/Modal";
import React, {ReactNode, useEffect, useState} from "react";
import {Button, ButtonType} from "@/components/Button";
import {ErrorPanel} from "@/components/ErrorPanel";
import {ConnectWallet} from "@/components/ConnectWallet";
import {useAccount} from "wagmi";
import {TransactionReceipt} from "viem";
import {LoadingSpinner} from "@/components/LoadingSpinner";
import useId from "@/hooks/useId";
import {useHasMounted} from "@/hooks/useHasMounted";
import {twMerge} from "tailwind-merge";
import {usePendingTransaction} from "@/contexts/PendingTransactionContext";
import {TransactionType} from "@/types/types";
import {UseQueryResult} from "@tanstack/react-query"
import {CHAIN_ID} from "@/util/constants";

type StepRenderer = (onSuccess: Function, onFailure: Function) => ReactNode;

export interface ChainItem {
  stepRenderer?: StepRenderer
  button?: TransactionButtonProps;
  buttonQuery?: () => UseQueryResult<TransactionButtonProps>;
  name: string | ReactNode;
  description: ReactNode,
  hideGoToWallet?: boolean;
}

const renderTransactionSuccess = () => {
    return (
        <>
            <div className="flex justify-center items-center p-6">
                <div className="flex flex-col items-center justify-center w-[6.25rem] h-[7.5rem]">
                    <div className="check-background">
                        <svg className="success-svg" viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className="check-shadow"></div>
                </div>
            </div>
            <div className="text-center">
                <div className="text-2xl">Success</div>
                <div className="text-md text-neutral-content">Your transaction has been successfully submitted</div>
            </div>
        </>
    )
}

const ChainItem = (
    {item, index, txnError, numChainItems, onTransactionSuccess}: {
        item: ChainItem,
        index: number,
        txnError: TransactionError,
        numChainItems: number,
        onTransactionSuccess: (receipt?: TransactionReceipt) => any
    }
) => {
    const query = item.buttonQuery ? item.buttonQuery() : undefined;
    const [transactionSuccessful, setTransactionSuccessful] = useState(false); // State to track transaction success

    useEffect(() => {
        return () => {
            setTransactionSuccessful(false);
        };
    }, []);

    const handleTransactionSuccess = (receipt?: TransactionReceipt) => {
        setTransactionSuccessful(true);
        onTransactionSuccess(receipt);
    };
    return (
        <div className="standard-stack !gap-4 pb-2 px-4 md:px-6">
            {
                transactionSuccessful ? (
                    renderTransactionSuccess()
                ) : (
                    <>
                      <div className="text-2xl">
                        {numChainItems > 1 && `Step ${index + 1}/${numChainItems}: `}
                      </div>
                      <div className="text-md text-neutral-content">
                        {item.description}
                      </div>
                      <hr className="border-neutral-content/50"/>
                      {
                        !item.hideGoToWallet &&
                        <div>
                          <div className="text-lg">Go to your wallet</div>
                          <div className="text-md text-neutral-content">You'll be asked to approve
                            this transaction
                            from your wallet.
                          </div>
                        </div>
                      }
                      {
                        item.button &&
                        <TransactionButton {...item.button!}
                                           {...txnError}
                                           autoStart={true}
                                           alertOnError={true}
                                           onTransactionSuccess={handleTransactionSuccess}>
                          {item.name}
                        </TransactionButton>
                        }
                        {
                            query &&
                            <>
                                {
                                  query.isLoading &&
                                  <Button disabled={true} loading={true}>
                                    Fetching Order
                                  </Button>
                                }
                                {
                                    query.isSuccess &&
                                    <TransactionButton {...query.data!}
                                                       {...txnError}
                                                       autoStart={true}
                                                       alertOnError={true}
                                                       onTransactionSuccess={handleTransactionSuccess}>
                                        {item.name}
                                    </TransactionButton>
                                }
                                {query.isError &&
                                    // @ts-ignore
                                    <ErrorPanel message={query.error.toString()}/>}
                            </>
                        }
                        {txnError.error && <ErrorPanel message={txnError.error}/>}
                        {
                            item.stepRenderer?.(() =>
                                onTransactionSuccess(undefined), () => {})
                        }</>
                )
            }

        </div>
    )
}

export interface Props {
    id: string;
    enabled: boolean;
    title: ReactNode;
    transactionChain: ChainItem[];
    onSuccess: (lastReceipt?: TransactionReceipt) => any;
    className?: string | undefined;
    buttonType?: ButtonType | undefined;
    dontUseModal?: boolean;
    type?: TransactionType;
}

export const TransactionChain = (
    {title, transactionChain, onSuccess, enabled, className, id, buttonType, dontUseModal, type}: Props
) => {
  const randomId = useId();
  const modal = useModal();
  const txnError = useTransactionError();
  const [index, setIndex] = useState(0);

  const {address, chain} = useAccount();

  const hasMounted = useHasMounted();
  useEffect(() => {
    txnError.setError(undefined);
    setIndex(0);
  }, [id]);

  const {addTransaction} = usePendingTransaction();

  const onTransactionSuccess = (receipt?: TransactionReceipt) => {
    if (index < transactionChain.length - 1) {
      setIndex(i => i + 1);
    } else {
      if (type) {
        addTransaction(receipt?.transactionHash!, type);
        setTimeout(() => modal.setModalVisible(false), 2000);
      }
      onSuccess(receipt);
    }
  }

  const getIndex = () => {
    if (index > transactionChain.length - 1) {
      return 0;
    }
    return index;
  }

  return <React.Fragment key={id + "_" + randomId}>
    {
      (!hasMounted || !address || chain?.id !== CHAIN_ID)
        ? <ConnectWallet className={twMerge("w-full", className || '')} />
        : <Button buttonType={buttonType || ButtonType.PRIMARY}
                  disabled={!enabled}
                  className={className || ''}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    modal.show();
                  }}>
          {title}
        </Button>
    }
    {
      dontUseModal ?
        <ChainItem item={transactionChain[getIndex()]}
                   numChainItems={transactionChain.length}
                   index={index}
                   key={`txnchainitem_${id}_${index}`}
                   onTransactionSuccess={onTransactionSuccess}
                   txnError={txnError} /> :
        <Modal {...modal} title={transactionChain.length > 0 ? transactionChain[getIndex()].name : ''}>
          <ChainItem item={transactionChain[getIndex()]}
                     numChainItems={transactionChain.length}
                     index={getIndex()}
                     key={`txnchainitem_${id}_${getIndex()}`}
                     onTransactionSuccess={onTransactionSuccess}
                     txnError={txnError} />
        </Modal>
    }
  </React.Fragment>
}
