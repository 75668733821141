import {DAY_SECONDS} from "@/util/constants";

const DAY_MILLIS = 1000 * 60 * 60 * 24;
const HOUR_MILLIS = 1000 * 60 * 60;
const MIN_MILLIS = 1000 * 60;

export function getDiffText(first: Date, second: Date): string {
  const diff = second.getTime() - first.getTime();
  if (diff >= DAY_MILLIS) {
    return `${Math.round(diff / DAY_MILLIS)} days`;
  } else if (diff >= HOUR_MILLIS) {
    return `${Math.round(diff / HOUR_MILLIS)} hours`;
  } else {
    return `${Math.round(diff / MIN_MILLIS)} minutes`;
  }
}

export function addDays(date: Date, numDays: number): Date {
  date.setDate(date.getDate() + numDays);
  return date;
}

export function getNumDaysUntil(until: number): number {
  const diff = Math.floor((until - new Date().getTime() / 1000) / DAY_SECONDS);
  return diff;
}

export function getTimeAgo(time: number): string {
  const diff = new Date().getTime() - time;
  if (diff >= DAY_MILLIS) {
    const days = Math.round(diff / DAY_MILLIS);
    return `${days} day${days > 1 ? 's' : ''}`;
  } else if (diff >= HOUR_MILLIS) {
    const hours = Math.round(diff / HOUR_MILLIS);
    return `${hours} hour${hours > 1 ? 's' : ''}`;
  } else if (diff >= MIN_MILLIS) {
    const minutes = Math.round(diff / MIN_MILLIS);
    return `${minutes} min${minutes > 1 ? 's' : ''}`;
  } else {
    const seconds = Math.round(diff / 1000);
    return `${seconds} sec${seconds > 1 ? 's' : ''}`;
  }
}