import classNames from "classnames";
import ScrollContainer from "react-indiana-drag-scroll";
import React, {Ref, useEffect, useRef, useState} from "react";
import {useScreen} from "@/util/useScreen";
import Select, {components, OptionProps, ValueContainerProps} from 'react-select'
import {theme as wtheme} from "tailwind.config"
import {Button, ButtonType} from "@/components/Button";
import {ExpirationDate} from "@/types/types";
import {BsCalendarEventFill} from "react-icons/bs";
import Countdown from "react-countdown";
import {TimeRemaining} from "@/components/TimeRemaining";

export const DateSelect = ({
  selected,
  onChange,
  dates,
  alwaysShowDropdown,
  showNumOptions = false,
  renderAsScroll = false,
  isDuration = false,
}: {
  selected?: number;
  onChange: (date: number) => any;
  dates: ExpirationDate[];
  alwaysShowDropdown?: boolean;
  showNumOptions?: boolean,
  renderAsScroll?: boolean,
  isDuration?: boolean
}) => {
  const scrollContainer: React.RefObject<HTMLDivElement> = useRef(null);
  const isDesktop = useScreen('sm');

  useEffect(() => {
    if (selected && scrollContainer.current) {
      const itemWidth = scrollContainer.current.offsetWidth / dates.length;
      const index = dates.findIndex(e => e.date === selected) || 0;
      scrollContainer.current.scrollTo(index * itemWidth, 0);      
    }
  }, [selected, dates]);

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const ValueContainer = ({ children, ...props }: ValueContainerProps<ExpirationDate>) => {
  const selectedDate = dates.find(a => a.date === selected)?.date;

   return (
    <components.ValueContainer {...props}>
      <div className="flex flex-row gap-2 items-center">
        <BsCalendarEventFill />
          {
            selectedDate
              ? renderDateRemaining(selectedDate)
              : 'Pick a Date'
          }
        </div>
      </components.ValueContainer>
    );
  };


  const renderDateRemaining = (epochSeconds: number) => {
    return (
      <TimeRemaining epochSeconds={epochSeconds} short={true} hideTooltip={true} isDuration={isDuration} />
    )
  }

  const renderButton = (expiration: ExpirationDate) => {
    const epochSeconds = expiration.date;
    const isSelected = selected === epochSeconds;
    return (
      <Button
        id={"date_" + epochSeconds}
        key={"date_" + epochSeconds}
        onClick={() => onChange(epochSeconds)}
        buttonType={isSelected ? ButtonType.PRIMARY : ButtonType.SECONDARY}
        className="!py-2"
      >
        <div className="flex flex-row gap-2 items-center">
          {
            epochSeconds
              ? renderDateRemaining(epochSeconds)
              : 'Pick a Date'
          }
        </div>
        {/*{date.format("MMM DD")}*/}
        {/*{thisYear < date.year() && `, ${date.year()}`}*/}
      </Button>
    );
  };
  const Option = (props: OptionProps<ExpirationDate>) => {
    // props.data
    return (
      <components.Option {...props} className={classNames("hover:cursor-pointer min-h-[50px] !flex !flex-row justify-between items-center", {
        "bg-glass-focus": props.data.date === selected,
        "hover:bg-glass": props.data.date !== selected
      })}>
        <span>{renderDateRemaining(props.data.date)}</span>
        {
          showNumOptions &&
          <span className={classNames("text-xs", {
            "text-call": props.data.numOptions > 0,
            "text-neutral-content": props.data.numOptions == 0,
          })}>({props.data.numOptions > 0 ? "+" : ""}{props.data.numOptions})</span>
        }
      </components.Option>
    );
  };

  if (!renderAsScroll && (alwaysShowDropdown || isDesktop)) {
    return (
      <div className="z-20" onClick={() => setMenuIsOpen(!menuIsOpen)}>
        <Select
          menuIsOpen={menuIsOpen}
          menuPlacement="auto"
          isClearable={false}
          isSearchable={false}
          options={dates}
          value={dates.find(a => a.date === selected)}
          components={{ ValueContainer, Option }}
          // @ts-ignore
          onChange={e => e && onChange(e.date)}
          styles={{
            container: baseStyles => ({
              ...baseStyles,
              // backgroundColor: 'unset',
              // borderColor: wtheme.extend.colors.neutral,
              // borderWidth: 1,
              // borderRadius: 50,
              // padding: '4px',
            }),
            indicatorSeparator: baseStyles => ({
              ...baseStyles,
              backgroundColor: wtheme.extend.colors.neutral,
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: wtheme.extend.colors.neutral,
              borderWidth: 0,
              borderRadius: 50,
              padding: '2px',
              minHeight: '45px',
              minWidth: '170px',
              cursor: "pointer",
              backgroundColor: wtheme.extend.colors.glass,
              ":hover": {
                backgroundColor: wtheme.extend.colors["glass-focus"],
                color: "white",
              },
              boxShadow: 'none'
            }),
            option: base => ({
              ...base,
              backgroundColor: 'none',
              color: 'white',
            })
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "white",
              primary75: wtheme.extend.colors["base-900"],
              primary50: wtheme.extend.colors["base-900"],
              primary25: wtheme.extend.colors["base-400"],
              neutral0: wtheme.extend.colors["base-100"],
              neutral5: wtheme.extend.colors["base-content"],
              neutral10: wtheme.extend.colors["base-content"],
              neutral20: wtheme.extend.colors["base-content"],
              neutral30: wtheme.extend.colors["base-content"],
              neutral40: wtheme.extend.colors["base-content"],
              neutral50: wtheme.extend.colors["base-content"],
              neutral60: wtheme.extend.colors["base-content"],
              neutral70: wtheme.extend.colors["base-content"],
              neutral80: wtheme.extend.colors["base-content"],
              neutral90: wtheme.extend.colors["base-content"],
            },
          })}
        />
        {/*<Dropdown options={options}*/}
        {/*          selected={selected}*/}
        {/*          onChange={onChange}*/}
        {/*/>*/}
      </div>
    )
  }

  return (
    <ScrollContainer className="scroll-container max-w-[400px] w-full px-2" innerRef={scrollContainer}>
      <div className="flex row gap-2">{dates.map(renderButton)}</div>
    </ScrollContainer>
  );
};
