import {BNPLItem2} from "@/types/bnpl_types";
import React, {useContext, useState} from "react";
import {getCartId, ShoppingCartContext, ShoppingCartItem} from "@/contexts/ShoppingCartContext";
import {Button, ButtonType} from "@/components/Button";
import {FiShoppingCart} from "react-icons/fi";
import {useProSidebar} from "react-pro-sidebar";
import {fetchOptionData} from "@/api/datafetcher";
import {toBN} from "@/util/converters";
import * as Sentry from "@sentry/nextjs";
import {useAlert} from "@/contexts/AlertContext";
import {OptionType} from "@/types/types";
import {zeroAddress} from "viem";

export const LongViewAddToCart = (
  {item, onSuccess}: {item: BNPLItem2, onSuccess: Function}
) => {
  const [loading, setLoading] = useState(false);
  const { addItem, items } = useContext(ShoppingCartContext);
  const {showError} = useAlert();
  const { collapseSidebar} = useProSidebar();

  const isAdded = () => {
    return items.some(i =>
      item.collectionAddress.toLowerCase() === i.collection.contractAddress.toLowerCase() &&
      item.tokenId === i.tokenId?.toString());
  }

  const addToCart = () => {
    if (loading || isAdded()) {
      return;
    }
    setLoading(true);
    collapseSidebar(false);

    fetchOptionData(
      item.collectionAddress,
      OptionType.CALL,
      item.expiration,
      item.amountLater,
      toBN(item.tokenId),
      undefined,
      undefined,
      undefined,
      true
    ).then(r => {
      if (!r.signature) {
        return;
      }
      const id = getCartId(
        r.ask?.ask.id.toNumber() || r.option?.id || 0,
        r.option?.poolAddress || r.option?.poolAddress || zeroAddress,
        r.ask?.ask.seller);
      const cartItem: ShoppingCartItem = {
        id,
        collection: r.collection,
        optionRequest: r.option,
        ask: r.ask,
        signature: r.signature!,
        quantity: 1,
        tokenId: r.option?.tokenId,
        tokenAddress: r.tokenAddress,
      }
      addItem(cartItem);
      onSuccess();
    }).catch(e => {
      console.error(e);
      Sentry.captureException(e);
      showError("Something went wrong while adding to cart, please refresh page and try again.");
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <Button buttonType={ButtonType.SECONDARY}
            className="xl:!text-lg xl:!py-3 whitespace-nowrap xl:max-h-[64px] xl:w-[200px]"
            loading={loading}
            onClick={addToCart}
            disabled={isAdded()}>
      <FiShoppingCart />{isAdded() ? "Added to Cart" : "Add to Cart"}
    </Button>
  )
}