import {
  AMMOrderResponse,
  AvailableOptions,
  AvailableSelection,
  CollectionOptionsResponse,
  CollectionPoolData,
  CollectionWithFloorPrice,
  CollectionWithPoolData,
  CompetitionEntry,
  FinalizeOptionResponse,
  OptionActivityData,
  OptionActivityType,
  OptionalDataResponse,
  OptionBacktestingSnapshot,
  OptionData,
  OptionPricingResponse,
  PoolAsk,
  OptionStatus,
  OptionType,
  PaginatedResponse,
  PayoutTransaction,
  PoolHistorySnapshot,
  TradeHistoryEntry,
  UserStats,
  WasabiAskWithOption,
  WasabiOption,
  WasabiOptionPage,
  WasabiOptionWithMetadata,
  WasabiOptionWithMetadataAndValue,
  WasabiPool,
  WasabiPoolWithCollection,
  DkodaPricingConfiguration, FunctionCall, ArbitrageData, PoolAskOrder,
  LPTradeHistoryEntry, LPStats, OptionActivity, WasabiOptionTable, OptionOverview
} from "@/types/types";
import {BigNumber} from "ethers";
import {toBN} from "@/util/converters";
import {
  Ask,
  Bid, HighestBid,
  WasabiAsk,
  WasabiBid,
  WasabiBidMigrated, WasabiBidWithCollection } from "@/types/exchange_types";
import {AddressZero} from "@ethersproject/constants";
import {
  BNPLData2,
  BNPLItem2,
  BNPLLoanOrder,
  BNPLOrder, CollectionLoanOffer, OptionRolloverOrder
} from "@/types/bnpl_types";
import {zeroAddress} from "viem";

export const convertCollectionOptionsResponse = (
  response: any
): CollectionOptionsResponse => {
  return {
    ...response,
    dates: response.dates,
    selectedDate: response.selectedDate,
    options: response.options.map(convertAvailableOptions),
    floorPrice: toBN(response.floorPrice),
  };
};

export const convertFinalizeOptionResponse = (response: any): FinalizeOptionResponse => {
  return {
    ...response,
    availableOptions: convertAvailableOptions(response.availableOptions),
    floorPrice: BigNumber.from(String(response.floorPrice)),
    option: response.option ? convertPoolAsk(response.option) : undefined,
    ask: response.ask ? convertWasabiAskAndOption(response.ask) : undefined,
  };
};

export const convertOption = (option: any): WasabiOption => {
  return {
    ...option,
    ...convertOptionData(option),
    id: String(option.id),
    poolAddress: option.poolAddress,
    status: option.status ? option.status as OptionStatus : undefined,
    tokenId: option.tokenId ? toBN(option.tokenId) : undefined,
    originalPremium: toBN(option.originalPremium),
  };
};

export const convertOptionWithMetadata = (option: any): WasabiOptionWithMetadata => {
  return {
    ...option,
    ...convertOption(option),
    metadata: option.metadata,
  }
}

export const convertOptionWithMetadataAndValue = (option: any): WasabiOptionWithMetadataAndValue => {
  return {
    ...option,
    ...convertOptionWithMetadata(option),
    arbitragePayout: toBN(option.arbitragePayout),
    ask: option.ask ? toBN(option.ask) : undefined,
    highestBid: option.highestBid ? toBN(option.highestBid) : undefined,
  }
}

export const convertOptionPage = (data: any): WasabiOptionPage => {
  return {
    ...data,
    option: convertOptionWithMetadata(data.option),
    payoutTransaction: data.payoutTransaction ? convertPayoutTransaction(data.payoutTransaction) : undefined,
    floorPrice: toBN(data.floorPrice),
    tradeToShare: data.tradeToShare ? convertOptionActivity(data.tradeToShare) : undefined
  };
};

export const convertAvailableSelection = (selection: any): AvailableSelection => {
  return {
    ...selection,
    premium: toBN(selection.premium),
    tokenIds: selection.tokenIds.map(toBN),
    ask: selection.ask && convertWasabiAsk(selection.ask),
  }
}

export const convertAvailableOptions = (option: any): AvailableOptions => {
  return {
    ...convertOptionData(option),
    selections: option.selections.map(convertAvailableSelection)
  };
};

export const convertOptionData = (option: any): OptionData => {
  return {
    optionType: option.optionType === "PUT" ? OptionType.PUT : OptionType.CALL,
    strikePrice: toBN(option.strikePrice),
    premium: toBN(option.premium),
    expiration: option.expiration,
  };
};

export const convertOptionActivity = (activity: any): OptionActivity => {
  return {
    ...activity,
    type: OptionActivityType[activity.type],
  }
}

export const convertOptionActivityData = (activityData: any): OptionActivityData => {
  return {
    ...activityData,
    activity: convertOptionActivity(activityData.activity),
    option: convertOption(activityData.option)
  }
};

export const convertPoolAsk = (option: any): PoolAsk => {
  return {
    id: option.id,
    poolAddress: option.poolAddress,
    optionType: option.optionType === "PUT" ? OptionType.PUT : OptionType.CALL,
    strikePrice: toBN(option.strikePrice),
    premium: toBN(option.premium),
    expiry: option.expiry,
    tokenId: toBN(option.tokenId || 0),
    orderExpiry: option.orderExpiry,
  };
};

export const convertPoolAskOrder = (option: any): PoolAskOrder => {
  return { ...option, ask: convertPoolAsk(option.ask) };
}

export const convertPricingResponse = (
  response: any
): OptionPricingResponse => {
  return {
    options: response.options.map(convertOption),
  };
};

export const convertCollectionWithFloorResponse = (
  response: any
): CollectionWithFloorPrice => {
  return {
    ...response,
    floorPrice: toBN(response.floorPrice),
  };
};

export const convertOptionBacktestingSnapshot = (
  response: any
): OptionBacktestingSnapshot[] => {
  // @ts-ignore
  return response.map((snapshot) => {
    return {
      ...snapshot,
      lockedWei: toBN(snapshot.lockedWei),
      totalPremiumsCollected: BigNumber.from(
        String(snapshot.totalPremiumsCollected)
      ),
      totalStrikeCollected: BigNumber.from(
        String(snapshot.totalStrikeCollected)
      ),
      totalWeiBalance: toBN(snapshot.totalWeiBalance),
    };
  });
};

export const convertWasabiPool = (pool: any): WasabiPool => {
  return {
    ...pool,
    availableBalance: toBN(pool.availableBalance),
    totalBalance: toBN(pool.totalBalance),
    admin: pool.admin || zeroAddress,
  };
};

export const convertWasabiPoolWithCollection = (data: any): WasabiPoolWithCollection => {
  return {
    ...data,
    pool: convertWasabiPool(data.pool),
  }
}

export function convertPaginatedResponse<T>(
  response: any,
  converter: (v: any) => T
): PaginatedResponse<T> {
  return {
    ...response,
    items: response.items.map((a: any) => converter(a)),
  };
}
export function convertOptionalDataResponse<T>(
  response: any,
  converter: (v: any) => T
): OptionalDataResponse<T> {
  return { data: response.data ? converter(response.data) : null};
}

const convertCollectionPoolData = (poolData: any): CollectionPoolData => {
  return {
    ...poolData,
    floorPrice: toBN(poolData.floorPrice),
    totalValueOfPools: toBN(poolData.totalValueOfPools),
    totalValueAvailable: toBN(poolData.totalValueAvailable),
    totalAvailableLoanSize: toBN(poolData.totalAvailableLoanSize),
  };
};

export const convertCollectionWithPoolData = (
  data: any
): CollectionWithPoolData => {
  return {
    ...data,
    poolData: convertCollectionPoolData(data.poolData),
  };
};

export const convertOptionAMMResponse = (response: any): AMMOrderResponse => {
  return {
    ...response,
    order: {
      ...response.order,
      price: toBN(response.order.price),
    },
  }
}

export const convertCompetitionEntry = (data: any): CompetitionEntry => {
  return {
    ...data,
    pnl: toBN(data.pnl),
    volume: toBN(data.volume),
    poolValueLocked: toBN(data.poolValueLocked),
  }
}

const convertPayoutTransaction = (data: any): PayoutTransaction => {
  return {
    ...data,
    payout: toBN(data.payout),
    profit: toBN(data.profit),
    optionId: toBN(data.optionId),
  }
}

export const convertUserStats = (data: any): UserStats => {
  return {
    ...data,
    totalPayout: toBN(data.totalPayout),
    entry: convertCompetitionEntry(data.entry),
    transactions: data.transactions.map(convertPayoutTransaction),
  };
}

export const convertTradeHistoryEntry = (data: any): TradeHistoryEntry => {
  return {
    ...data,
    optionType: data.optionType === "PUT" ? OptionType.PUT : OptionType.CALL,
    premium: toBN(data.premium),
    payout: data.payout ? toBN(data.payout) : undefined,
    profit: data.profit ? toBN(data.profit) : undefined,
    activityType: data.activityType ? OptionActivityType[data.activityType] : undefined
  }
}

export const convertLPTradeHistoryEntry = (data: any): LPTradeHistoryEntry => {
  return {
    ...data,
    option: convertOptionWithMetadata(data.option),
    type: OptionActivityType[data.type]
  }
}

export const convertLPStats = (data: any): LPStats => {
  return {
    ...data,
    tpvl: toBN(data.tpvl),
    pnl: toBN(data.pnl),
    totalPremiumCollected: toBN(data.totalPremiumCollected),
  }
}

export const convertAsk = (data: any): Ask => {
  return {
    tokenAddress: data.tokenAddress || AddressZero,
    seller: data.seller,
    price: toBN(data.price),
    orderExpiry: toBN(data.orderExpiry),
    optionId: toBN(data.optionId),
    id: toBN(data.id),
  }
}

export const convertWasabiAsk = (data: any): WasabiAsk => {
  return {
    ...convertAsk(data),
    signature: data.signature,
  }
}

export const convertBid = (data: any): Bid => {
  return {
    ...data,
    id: toBN(data.id),
    price: toBN(data.price),
    orderExpiry: toBN(data.orderExpiry),
    strikePrice: toBN(data.strikePrice),
    expiry: toBN(data.expiry),
    expiryAllowance: toBN(data.expiryAllowance),
    optionType: data.optionType === "PUT" ? OptionType.PUT : OptionType.CALL,
    optionTokenAddress: data.optionTokenAddress || AddressZero
  }
}

export const convertWasabiBid = (data: any): WasabiBid => {
  return {
    ...convertBid(data),
    signature: data.signature,
  }
}
export const convertWasabiBidWithCollection = (data: any): WasabiBidWithCollection => {
  return {
    ...data,
    ...convertWasabiBid(data)
  }
}

export const convertWasabiAskAndOption = (data: any): WasabiAskWithOption => {
  return {
    ask: convertAsk(data.ask),
    option: convertOption(data.option),
  }
}

export const convertPoolHistorySnapshot = (data: any): PoolHistorySnapshot => {
  return {
    ...data,
    poolState: {
      ...data.poolState,
      totalBalance: toBN(data.poolState.totalBalance),
      availableBalance: toBN(data.poolState.availableBalance),
    },
    totalPremiumCollected: toBN(data.totalPremiumCollected),
    totalStrikeCollected: toBN(data.totalStrikeCollected),
  }
}

export const convertDkodaPricingConfiguration = (data: any): DkodaPricingConfiguration => {
  return {
    ...data,
    minDuration: toBN(data.minDuration),
    maxDuration: toBN(data.maxDuration),
    minStrike: toBN(data.minStrike),
    maxStrike: toBN(data.maxStrike),
  }
}

export const convertFunctionCall = (data: any): FunctionCall => {
  return {
    ...data,
    value: toBN(data.value),
  }
}

export const convertArbitrageData = (data: any): ArbitrageData => {
  return {
    ...data,
    functionCalls: data.functionCalls.map(convertFunctionCall),
    flashLoanAmount: toBN(data.flashLoanAmount),
    tokenId: toBN(data.tokenId),
    marketValue: toBN(data.marketValue),
    payout: toBN(data.payout),
    profit: toBN(data.profit),
  }
}

export const convertBNPL2Item = (data: any): BNPLItem2 => {
  return {
    ...data,
    amountNow: toBN(data.amountNow),
    amountLater: toBN(data.amountLater),
  }
}

export const convertBNPL2Data = (data: any): BNPLData2 => {
  return {
    ...data,
    items: data.items.map(convertBNPL2Item),
  }
}

export const convertBNPLLoanOrder = (data: any): BNPLLoanOrder => {
  return {
    ...data,
    flashLoanAmount: toBN(data.flashLoanAmount),
    marketplaceCallData: data.marketplaceCallData.map(convertFunctionCall),
  }
}

export const convertBNPLOrder = (data: any): BNPLOrder => {
  return {
    ...data,
    item: convertBNPL2Item(data.item),
    poolAskOrder: data.poolAskOrder ? convertPoolAskOrder(data.poolAskOrder) : undefined,
    loanOrder: data.loanOrder ? convertBNPLLoanOrder(data.loanOrder) : undefined,
  }
}

export const convertOptionOverview = (data: any): OptionOverview => {
  return {
    ...data,
    type: data.type === "PUT" ? OptionType.PUT : OptionType.CALL,
    strike: toBN(data.strike),
    premium: toBN(data.premium),
  }
}

export const convertWasabiOptionTable = (data: any): WasabiOptionTable => {
  return {
    ...data,
    floorPrice: toBN(data.floorPrice),
    options: data.options.map(convertOptionOverview),
  }
}

export const convertWasabiBidMigrated = (data: any): WasabiBidMigrated => {
  return {
    ...data,
    optionType: data.optionType === "PUT" ? OptionType.PUT : OptionType.CALL,
  }
}

export const convertHighestBid = (data: any): HighestBid => {
  return {
    ...data,
    bid: data.bid ? convertWasabiBidMigrated(data.bid) : undefined,
  }
}

export const convertCollectionLoanOffer = (data: any): CollectionLoanOffer => {
  return {
    ...data,
    loanAmount: toBN(data.loanAmount),
    paybackAmount: toBN(data.paybackAmount),
    upfrontFee: toBN(data.upfrontFee),
    netLoanAmount: toBN(data.loanAmount).sub(toBN(data.upfrontFee)),
  }
}

export const convertOptionRolloverOrder = (data: any): OptionRolloverOrder => {
  return {
    ...data,
    loanOffer: convertCollectionLoanOffer(data.loanOffer),
    migrateRolloverData: data.migrateRolloverData ? convertBNPLLoanOrder(data.migrateRolloverData) : undefined,
    repayAmount: toBN(data.repayAmount),
    flashloanFee: toBN(data.flashloanFee),
    wasabiFee: toBN(data.wasabiFee),
    topoff: toBN(data.topoff),
    refund: toBN(data.refund)
  }
}