import React, {createContext, FC, useContext, useEffect, useState} from "react";
import {WasabiOptionWithMetadataAndValue} from "@/types/types";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

export interface OptionActionContextData {
  option: WasabiOptionWithMetadataAndValue;
  close: Function;
}

interface OptionActionContextInterface {
  data?: OptionActionContextData;
  setData: (data?: OptionActionContextData) => any;
  lastUpdated?: number;
}

export const OptionActionContext = createContext<OptionActionContextInterface | undefined>(undefined);

export const OptionActionContextProvider: FC<Props> = ({ children }) => {
  const [data, setData] = useState<OptionActionContextData>();

  useEffect(() => {
    if (window && !window.location.href.includes('portfolio?view=options')) {
      // Call your function here
      setData(undefined);
    }
  }, [typeof window !== "undefined" && window.location.href]);

  return (
    <OptionActionContext.Provider value={{data, setData}}>
      {children}
    </OptionActionContext.Provider>
  );
};

export const useOptionAction = () => {
  return useContext(OptionActionContext);
}