import {WasabiOptionWithMetadataAndValue} from "@/types/types";
import React from "react";
import {MakeAsk} from "@/components/Option/listing/MakeAsk";
import {Modal, useModal} from "@/components/Modal";
import {Button} from "@/components/Button";
import {useQuery} from "@tanstack/react-query";
import {cancelAsk, fetchAsk} from "@/api/datafetcher";
import {ChainItem, TransactionChain} from "@/components/Transactions/TransactionChain";
import {WASABI_CONDUIT} from "@/util/constants";
import {WasabiConduitAbi} from "@/contract/WasabiConduitAbi";
import {EthDisplay} from "@/components/EthDisplay";
import {Ask} from "@/types/exchange_types";
import {AddressZero} from "@ethersproject/constants";

export const ListOptionButton = ({option}: {option: WasabiOptionWithMetadataAndValue}) => {
  const makeAskModal = useModal();

  const existingAskQuery = useQuery({
    queryKey: ["optionAsk", option.id],
    queryFn: async () => await fetchAsk(option.id),
  });

  if (existingAskQuery.isLoading) {
    return (
      <div className="min-w-[150px] min-h-[40px] rounded-md bg-slate-600 animate-pulse"/>
    );
  }

  const ask: Ask | undefined =
    existingAskQuery.data?.data
      ? {
        id: existingAskQuery.data.data.id,
        price: existingAskQuery.data.data.price,
        tokenAddress: existingAskQuery.data.data.tokenAddress || AddressZero,
        orderExpiry: existingAskQuery.data.data.orderExpiry,
        seller: existingAskQuery.data.data.seller,
        optionId: existingAskQuery.data.data.optionId
      }
      : undefined;
  const transactionChain: ChainItem[] = [
    {
      name: "Cancel Listing",
      description: "Approve the transaction to cancel your listing.",
      button: {
        id: "cancel_ask",
        loadingText: "Cancelling",
        addressOrName: WASABI_CONDUIT,
        contractInterface: WasabiConduitAbi,
        functionName: "cancelAsk",
        args: ask ? [ask, existingAskQuery.data?.data?.signature] : [],
        enabled: ask !== undefined,
        onTransactionSuccess: (txnResult) => cancelAsk(txnResult.transactionHash),
      }
    }
  ];

  return (
    <>
      {
        existingAskQuery.data?.data
          ? <TransactionChain id="cancel_listing"
                              className="min-w-[150px]"
                              transactionChain={transactionChain}
                              enabled={true}
                              title={<EthDisplay value={existingAskQuery.data.data.price}
                                                 size={4}
                                                 tokenAddress={existingAskQuery.data.data.tokenAddress}
                                                 label="Cancel Listing" />}
                              onSuccess={() => window.location.reload()} />
          : <Button className="min-w-[150px]" onClick={makeAskModal.show}>List</Button>
      }
      <Modal {...makeAskModal}>
        <MakeAsk option={option}/>
      </Modal>
    </>
  );
}
