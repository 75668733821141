import { useState } from 'react';

function randomString(length: number, chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') {
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

const useId = (defaultId: string | undefined = undefined) => {
  const [id] = useState(() => defaultId || randomString(8));

  return id;
};

export default useId;
