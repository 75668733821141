import {
    BLAST_MAINNET_LINK,
    CHAIN_ID, isMainnet,
    OPTIONS_MAINNET_LINK, OPTIONS_TESTNET_LINK,
    PERP_MAINNET_LINK,
    PERP_TESTNET_LINK, PERPS_VIEW
} from "@/util/constants";
import React, {useEffect, useRef, useState} from "react";
import Link from "next/link";
import {IoChevronDown, IoChevronUp} from "react-icons/io5";
import {blast, mainnet, sepolia} from "wagmi/chains";
import {getChainImage, getChainName} from "@/util/chainConstants";
import classNames from "classnames";

export const AppDropdown = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const componentRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const renderLink = (href: string, chainId: number, isOptions = false) => {
        return (
          <Link
            className={classNames("flex items-center text-neutral-content px-4 py-2 hover:bg-gray-600 hover:text-white hover:bg-glass-focus cursor-pointer", {
                "text-white bg-glass": CHAIN_ID === chainId && PERPS_VIEW !== isOptions
            })}
            href={href}>
              <img className="w-6 h-6 me-2 rounded-full"
                   src={getChainImage(chainId)}
                   alt={getChainName(chainId)}/>
              <span>{isOptions ? "Options" : getChainName(chainId)}</span>
          </Link>
        )
    }


    return (
        <div className="relative inline-block text-neutral-content"
             ref={componentRef}
             onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <div className="relative">
                <div className="flex flex-row rounded-md px-1 hover:bg-glass-focus cursor-pointer">
                    <div className="flex items-center px-1 py-2">
                        <img className="w-6 h-6 rounded-full"
                             src={getChainImage(CHAIN_ID)}
                             alt={getChainName(CHAIN_ID)}/>
                    </div>
                    <div className="flex items-center cursor-pointer text-neutral-content">
                        {isDropdownOpen ? <IoChevronUp /> : <IoChevronDown />}
                    </div>
                </div>
                {isDropdownOpen &&
                  <div className="absolute overflow-hidden z-10 top-12 left-0 rounded-lg shadow w-60 bg-[#071526]">
                      <div className="flex flex-col">
                          {renderLink(BLAST_MAINNET_LINK, blast.id)}
                          {renderLink(PERP_MAINNET_LINK, mainnet.id)}
                          {CHAIN_ID === sepolia.id && renderLink(PERP_TESTNET_LINK, sepolia.id)}
                          {renderLink(OPTIONS_MAINNET_LINK, mainnet.id, true)}
                      </div>
                  </div>}
            </div>
        </div>
    )
}
