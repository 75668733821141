import React, {createContext, useState, useContext, FC, ReactNode} from 'react';
import {TransactionType} from "@/types/types";

interface TransactionReceipt {
    transactionHash: string;
    transactionType: TransactionType;
}

interface TransactionContextType {
    pendingTransactions: TransactionReceipt[];
    addTransaction: (transactionHash: string, transactionType: TransactionType) => void;
    removeTransaction: (transactionId: string) => void;
}

interface TransactionProviderProps extends React.PropsWithChildren<{}> {  }

const TransactionContext = createContext<TransactionContextType | undefined>(undefined);

export const TransactionProvider: FC<TransactionProviderProps> = ({ children }) => {
    const [pendingTransactions, setPendingTransactions] = useState<TransactionReceipt[]>([]);

    const addTransaction = (transactionHash: string, transactionType: TransactionType) => {
        setPendingTransactions([...pendingTransactions, { transactionHash, transactionType }]);
    };

    const removeTransaction = (transactionHash: string) => {
        setPendingTransactions(pendingTransactions.filter((t) => t.transactionHash !== transactionHash));
    };

    return (
        <TransactionContext.Provider value={{ pendingTransactions, addTransaction, removeTransaction }}>
            {children}
        </TransactionContext.Provider>
    );
};

export const usePendingTransaction = (): TransactionContextType => {
    const context = useContext(TransactionContext);
    if (!context) {
        throw new Error('useTransaction must be used within a TransactionProvider');
    }
    return context;
};
