import React, {createContext, FC, useContext, useEffect, useState} from "react";
import {CollectionDetailStatic} from "@/types/dkoda_types";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

export interface LongContextData {
  collection: CollectionDetailStatic;
  collectionId: string;
  tokenId: string;
  close: Function;
  version2: boolean;
}

interface LongContextInterface {
  data?: LongContextData;
  setData: (data?: LongContextData) => any;
}

export const LongContext = createContext<LongContextInterface | undefined>(undefined);

export const LongContextProvider: FC<Props> = ({ children }) => {
  const [data, setData] = useState<LongContextData>();

  useEffect(() => {
    if (window && !window.location.href.includes('long')) {
      // Call your function here
      setData(undefined);
    }
  }, [typeof window !== "undefined" && window.location.href]);

  return (
    <LongContext.Provider value={{data, setData}}>
      {children}
    </LongContext.Provider>
  );
};

export const useLongView = () => {
  return useContext(LongContext);
}