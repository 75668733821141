import Countdown from "react-countdown";
import React from "react";
import {Tooltip as ReactTooltip} from "react-tooltip";
import useId from "@/hooks/useId";
import classNames from "classnames";

export interface Props {
  epochSeconds: number;
  finishedText?: string;
  short?: boolean;
  hideTooltip?: boolean;
  isDuration?: boolean;
}

const durationPadding = 5 * 60 * 1000; // 5 minutes

export const TimeRemaining = ({ epochSeconds, finishedText, short, hideTooltip, isDuration }: Props) => {
  const getDate = () => {
    return new Date(epochSeconds * 1000 + (isDuration ? new Date().getTime() + durationPadding : 0));
  }
  const getFullDate = (): string => {
    return getDate().toLocaleString([], { timeZoneName: "short" });
  };

  const id = useId();

  return (
    <div className={classNames({
      "underline decoration-dashed underline-offset-4": !hideTooltip
    })}>
      <span className="cursor-default flex items-center"
        data-for="tooltip"
        id={id}
      >
        <Countdown
          date={getDate().getTime()}
          renderer={({ days, hours, minutes, seconds }) => {
            if (short) {
              if (days > 1) {
                return `${days} Days`;
              }
              if (days == 1) {
                return `${days} Day`;
              }
              if (hours > 1) {
                return `${hours} Hours`;
              } else if (hours === 1) {
                return `${hours} Hour`;
              } else if (minutes > 1) {
                return `${minutes} Minutes`;
              } else if (minutes === 1) {
                return `${minutes} Minute`;
              }
              return finishedText || `${seconds} Seconds`;
            }
            if (days >= 7) {
              return `${days} Days`;
            }
            if (days > 1) {
              return `${days} Days, ${hours} Hours`;
            }
            if (days == 1) {
              return `${days} Day, ${hours} Hours`;
            }
            if (hours > 10) {
              return `${hours} Hours`;
            }
            if (hours > 1) {
              return `${hours} Hours, ${minutes} Minutes`;
            }
            if (hours === 1) {
              return `${hours} Hour, ${minutes} Minutes`;
            }
            if (minutes >= 30) {
              return `${minutes} Minutes`;
            }
            if (minutes > 0) {
              return `${minutes} Minutes, ${seconds} Seconds`;
            }
            return finishedText || `${seconds} Seconds`;
          }}
        />
      </span>
      {
        !hideTooltip &&
        <ReactTooltip
          anchorSelect={`#${id}`}
          id={"tooltip_" + (id || "expiresIn_tooltip")}
          place="top"
          noArrow
          className="z-50"
          content={getFullDate()}
          style={{ backgroundColor: "#3b485f", color: "#98a2b3" }}
        />
      }
    </div>
  );
};
