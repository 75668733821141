import React from "react";
import {
  DISCORD_LINK,
  DUNE_LINK,
  GETTING_STARTED_LINK,
  MIRROR_LINK,
  PERP_ANALYTICS_LINK,
  PERPS_VIEW,
  TWITTER_LINK
} from "@/util/constants";
import {IoHelpCircle} from "react-icons/io5";
import {BsBugFill, BsFillBarChartFill, BsTwitter} from "react-icons/bs";
import {DuneIcon} from "@/components/icons/DuneIcon";
import {FaDiscord} from "react-icons/fa";
import {MirrorIcon} from "@/components/icons/MirrorIcon";

export interface FooterLinks {
  id: string;
  name: string;
  href: string;
  icon: React.ReactNode;
  inNewTab: boolean;
  hide?: boolean;
}

export const useFooterLinks = () => {

  const isPerpsView = PERPS_VIEW;

  const links: FooterLinks[] = [
    {
      id: "docs",
      name: "Docs",
      href: GETTING_STARTED_LINK,
      icon: <IoHelpCircle />,
      inNewTab: true
    },
    {
      id: "feedback",
      name: "Bugs & Feedback",
      href: "/feedback",
      icon: <BsBugFill />,
      inNewTab: false
    },
    {
      id: "leaderboard",
      name: "Leaderboard",
      href: "/leaderboard",
      icon: <BsFillBarChartFill />,
      inNewTab: false,
      hide: isPerpsView
    },
    {
      id: "dune",
      name: "Dune Dashboard",
      href: isPerpsView ? PERP_ANALYTICS_LINK : DUNE_LINK,
      icon: <DuneIcon />,
      inNewTab: true
    },
    {
      id: "twitter",
      name: "Twitter",
      href: TWITTER_LINK,
      icon: <BsTwitter />,
      inNewTab: true
    },
    {
      id: "discord",
      name: "Discord",
      href: DISCORD_LINK,
      icon: <FaDiscord />,
      inNewTab: true
    },
    {
      id: "mirror",
      name: "Mirror",
      href: MIRROR_LINK,
      icon: <MirrorIcon />,
      inNewTab: true
    }
  ];
  return links.filter(l => !l.hide);
}